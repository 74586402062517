export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";

export const GET_SINGLE_REQUEST = "GET_SINGLE_REQUEST";
export const GET_SINGLE_SUCCESS = "GET_SINGLE_SUCCESS";
export const GET_SINGLE_FAILURE = "GET_SINGLE_FAILURE";

//cart Section;
export const GET_CART_REQUEST = "GET_CART_REQUEST";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_FAILURE = "GET_CART_FAILURE";

export const POST_CART_REQUEST = "POST_CART_REQUEST";
export const POST_CART_SUCCESS = "POST_CART_SUCCESS";
export const POST_CART_FAILURE = "POST_CART_FAILURE";

export const DELETE_CART_REQUEST = "DELETE_CART_REQUEST";
export const DELETE_CART_SUCCESS = "DELETE_CART_SUCCESS";
export const DELETE_CART_FAILURE = "DELETE_CART_FAILURE";

export const PATCH_CART_REQUEST = "PATCH_CART_REQUEST";
export const PATCH_CART_SUCCESS = "PATCH_CART_SUCCESS";
export const PATCH_CART_FAILURE = "PATCH_CART_FAILURE";

export const GET_DETAIL_USER_REQUEST = "GET_DETAIL_USER_REQUEST";
export const GET_DETAIL_USER_SUCCESS = "GET_DETAIL_USER_SUCCESS";
export const GET_DETAIL_USER_FAILURE = "GET_DETAIL_USER_FAILURE";

export const GET_ALL_COLLECTIONS_REQUEST = "GET_ALL_COLLECTION_REQUEST";
export const GET_ALL_COLLECTIONS_SUCCESS = "GET_ALL_COLLECTION_SUCCESS";
export const GET_ALL_COLLECTIONS_FAILURE = "GET_ALL_COLLECTION_FAILURE";

export const POST_COLLECTION_REQUEST = "POST_COLLECTION_REQUEST";
export const POST_COLLECTION_SUCCESS = "POST_COLLECTION_SUCCESS";
export const POST_COLLECTION_FAILURE = "POST_COLLECTION_FAILURE";

export const DELETE_COLLECTION_REQUEST = "DELETE_COLLECTION_REQUEST";
export const DELETE_COLLECTION_SUCCESS = "DELETE_COLLECTION_SUCCESS";
export const DELETE_COLLECTION_FAILURE = "DELETE_COLLECTION_FAILURE";

export const GET_DETAIL_COLLECTION_REQUEST = "GET_DETAIL_COLLECTION_REQUEST";
export const GET_DETAIL_COLLECTION_SUCCESS = "GET_DETAIL_COLLECTION_SUCCESS";
export const GET_DETAIL_COLLECTION_FAILURE = "GET_DETAIL_COLLECTION_FAILURE";

export const PUT_DETAIL_COLLECTION_REQUEST = "PUT_DETAIL_COLLECTION_REQUEST";
export const PUT_DETAIL_COLLECTION_SUCCESS = "PUT_DETAIL_COLLECTION_SUCCESS";
export const PUT_DETAIL_COLLECTION_FAILURE = "PUT_DETAIL_COLLECTION_FAILURE";

export const GET_ALL_CATEGORY_REQUEST = "GET_ALL_CATEGORY_REQUEST";
export const GET_ALL_CATEGORY_SUCCESS = "GET_ALL_CATEGORY_SUCCESS";
export const GET_ALL_CATEGORY_FAILURE = "GET_ALL_CATEGORY_FAILURE";

export const GET_COLLECTION_BY_GENDER_REQUEST = "GET_COLLECTION_BY_GENDER_REQUEST";
export const GET_COLLECTION_BY_GENDER_SUCCESS = "GET_COLLECTION_BY_GENDER_SUCCESS";
export const GET_COLLECTION_BY_GENDER_FAILURE = "GET_COLLECTION_BY_GENDER_FAILURE";

export const POST_PRODUCT_REQUEST = "POST_PRODUCT_REQUEST";
export const POST_PRODUCT_SUCCESS = "POST_PRODUCT_SUCCESS";
export const POST_PRODUCT_FAILURE = "POST_PRODUCT_FAILURE";

export const GET_ALL_GENDER_REQUEST = "GET_ALL_GENDER_REQUEST";
export const GET_ALL_GENDER_SUCCESS = "GET_ALL_GENDER_SUCCESS";
export const GET_ALL_GENDER_FAILURE = "GET_ALL_GENDER_FAILURE";

export const GET_ALL_PRODUCTS_REQUEST = "GET_ALL_PRODUCTS_REQUEST";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_ALL_PRODUCTS_FAILURE = "GET_ALL_PRODUCTS_FAILURE";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";

export const GET_DETAIL_PRODUCT_REQUEST = "GET_DETAIL_PRODUCT_REQUEST";
export const GET_DETAIL_PRODUCT_SUCCESS = "GET_DETAIL_PRODUCT_SUCCESS";
export const GET_DETAIL_PRODUCT_FAILURE= "GET_DETAIL_PRODUCT_FAILURE";

export const POST_POST_REQUEST = "POST_POST_REQUEST";
export const POST_POST_SUCCESS = "POST_POST_SUCCESS";
export const POST_POST_FAILURE = "POST_POST_FAILURE";

export const PUT_POST_REQUEST = "PUT_POST_REQUEST";
export const PUT_POST_SUCCESS = "PUT_POST_SUCCESS";
export const PUT_POST_FAILURE = "PUT_POST_FAILURE";

export const GET_ALL_POST_REQUEST = "GET_ALL_POST_REQUEST";
export const GET_ALL_POST_SUCCESS = "GET_ALL_POST_SUCCESS";
export const GET_ALL_POST_FAILURE = "GET_ALL_POST_FAILURE";

export const GET_DETAIL_POST_REQUEST = "GET_DETAIL_POST_REQUEST";
export const GET_DETAIL_POST_SUCCESS = "GET_DETAIL_POST_SUCCESS";
export const GET_DETAIL_POST_FAILURE = "GET_DETAIL_POST_FAILURE";

export const DELETE_POST_REQUEST = "DELETE_POST_REQUEST";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_FAILURE = "DELETE_POST_FAILURE";

export const PUT_PRODUCT_REQUEST = "PUT_PRODUCT_REQUEST";
export const PUT_PRODUCT_SUCCESS = "PUT_PRODUCT_SUCCESS";
export const PUT_PRODUCT_FAILURE = "PUT_PRODUCT_FAILURE";

export const POST_CATEGORY_REQUEST = "POST_CATEGORY_REQUEST";
export const POST_CATEGORY_SUCCESS = "POST_CATEGORY_SUCCESS";
export const POST_CATEGORY_FAILURE = "POST_CATEGORY_FAILURE";
export const SET_REFRESH_STAGE = "SET_REFRESH_STAGE";

export const PUT_CATEGORY_REQUEST = "PUT_CATEGORY_REQUEST";
export const PUT_CATEGORY_SUCCESS = "PUT_CATEGORY_SUCCESS";
export const PUT_CATEGORY_FAILURE = "PUT_CATEGORY_FAILURE";

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";
