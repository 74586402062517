import { apiPath } from "./apiPath";
import { getRequest, postRequest } from "./axiosApi";

export const getListUser = async (params, successCallback, errorCallback) => {
  await getRequest(
    `${apiPath.getListUser}`,
    params,
    successCallback,
    errorCallback
  );
};

export const updateUser = async (
  params,
  successCallback,
  errorCallback
) => {
  await postRequest(
    `${apiPath.updateUser}`,
    params,
    successCallback,
    errorCallback
  );
};
