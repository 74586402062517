export const PATH = {
  admin: "/admin",
  dashboard: `/admin/dashboard`,
  collections: "/admin/collections",
  edit_collection: "/admin/collections/edit-collection",
  create_collection: "/admin/collections/create-collection",
  order: "/admin/order",
  userManagement: "/admin/user-management",
  products: "/admin/products",
  edit_product: "/admin/products/edit-product",
  create_product: "/admin/products/create-product",
  create_post: "/admin/posts/create-post",
  posts: "/admin/posts",
  edit_post: "/admin/posts/edit/post",
  categories: "/admin/categories",
  blogs: "/blogs",
  blogs_detail: "/blogs-detail",
  detail_product: "/product"
};
