import * as React from "react";
import "./Edit-product-component.css";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate, useParams } from "react-router-dom";
import { PATH } from "../../../Routes/router-path";
import * as Yup from "yup";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import PropTypes from "prop-types";
import { Select as BaseSelect, selectClasses } from "@mui/base/Select";
import { Option as BaseOption, optionClasses } from "@mui/base/Option";
import { styled } from "@mui/system";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import {
  createProduct,
  getAllCategory,
  getAllGender,
  getCollectionByGender,
  getDeatilProduct,
  updateProduct,
} from "../../../Redux/App/action";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { OPTIONSGENDER } from "../../../constants/appConstants";
import {
  GET_ALL_CATEGORY_SUCCESS,
  GET_ALL_GENDER_SUCCESS,
  GET_COLLECTION_BY_GENDER_SUCCESS,
  GET_DETAIL_PRODUCT_SUCCESS,
  POST_PRODUCT_FAILURE,
  POST_PRODUCT_SUCCESS,
  PUT_PRODUCT_FAILURE,
  PUT_PRODUCT_SUCCESS,
} from "../../../Redux/App/actionTypes";
import toast, { Toaster } from "react-hot-toast";
import { htmlData } from "./data-default-html";
const EditProduct = () => {
  const navigate = useNavigate();
  let { idProduct } = useParams();
  const [listCategory, setListCategory] = React.useState([]);
  const [category, setCategory] = React.useState([]);
  const [listCollections, setListCollections] = React.useState([]);
  const [collections, setCollections] = React.useState();
  const [listGender, setListGender] = React.useState([]);
  const [gender, setGender] = React.useState();
  const [listStatus, setListStatus] = React.useState([
    {
      name: "Hoạt động",
      race: true,
    },
    {
      name: "Không hoạt động",
      race: false,
    },
  ]);
  const [status, setStatus] = React.useState(listStatus[0]);
  const [initialValues, setInitialValues] = React.useState(null);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    code: Yup.string().required("Mã sản phẩm không được để trống"),
    name: Yup.string().required("Tên sản phẩm không được để trống"),
    status: Yup.object(),
    description: Yup.string(),
    templateInfo: Yup.string().required(
      "Thông tin thêm của sản phẩm không được để trống"
    ),
    price: Yup.number().required("Giá sản phẩm không được để trống"),
    categoryId: Yup.object(),
    genderId: Yup.object(),
    collectionId: Yup.object(),
    colorRequests: Yup.array().of(
      Yup.object().shape({
        colorCode: Yup.string()
          .required("Mã màu không được để trống")
          .matches(
            /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
            "Mã màu phải là mã hex hợp lệ"
          ),
        colorName: Yup.string().required("Tên màu không được để trống"),
        id: Yup.string(),
        imageList: Yup.array().of(
          Yup.object().shape({
            file: Yup.mixed()
              .required("Hình ảnh không được để trống")
              .test(
                "is-file-or-string",
                "File không đúng định dạng",
                (value) => {
                  // Kiểm tra xem value có phải là một tệp tin không
                  return typeof value === "string" || value instanceof File;
                }
              ),
          })
        ),
        sizeList: Yup.array().of(
          Yup.object().shape({
            sizeValue: Yup.string()
              .strict()
              .uppercase("Kích cỡ phải là chữ hoa")
              .matches(/^\S*$/, "Kích cỡ không được chứa khoảng trắng")
              .matches(/^[^\d]*$/, "Kích cỡ không được chứa chữ số")
              .required("Kích cỡ không được để trống")
              .test("unique-size", "Kích cỡ cần là duy nhất", function (value) {
                // 'this.parent' sẽ là đối tượng chứa sizeList (trong trường hợp này là toàn bộ form values)
                // 'this.path' sẽ là đường dẫn tới trường sizeValue trong mảng sizeList
                const currentIndex =
                  this.path.split(".")[this.path.split(".").length - 2];
                const listIndex = this.path.split(".");
                const indexSizeList = parseInt(listIndex[0]?.match(/\d+/)[0]);
                const listSizeVal = JSON.parse(
                  JSON.stringify(
                    this.options.context?.colorRequests[indexSizeList]?.sizeList
                  )
                )?.map((item) => item?.sizeValue);
                const findIndex = listSizeVal?.findIndex(
                  (char, i) =>
                    char === value &&
                    i !== parseInt(currentIndex?.match(/\d+/)[0])
                );
                return findIndex === -1;
              })
              .transform((value) => (value ? value.toUpperCase() : value)), // Chuyển đổi thành chữ hoa
            quantity: Yup.number().required("Số lượng không được để trống"),
            id: Yup.string(),
          })
        ),
      })
    ),
  });

  function onSubmit(fields) {
    const formData = new FormData();
    if (window.location.pathname === PATH.create_product) {
      formData.append("categoryId", fields.categoryId?.race);
      formData.append("code", fields.code);
      formData.append("status", fields.status?.race);
      formData.append("collectionId", fields.collectionId?.race);
      formData.append("description", fields.description);
      formData.append("genderId", fields?.genderId.race);
      formData.append("name", fields.name);
      formData.append("price", fields.price);
      formData.append("templateInfo", fields.templateInfo);
      fields.colorRequests?.forEach((item, index) => {
        formData.append(`colorRequests[${index}].colorCode`, item?.colorCode);
        formData.append(`colorRequests[${index}].colorName`, item?.colorName);
        item?.imageList?.forEach((file, indexFile) => {
          formData.append(
            `colorRequests[${index}].imageList[${indexFile}].file`,
            file?.file
          );
        });
        item?.sizeList?.forEach((size, indexSize) => {
          formData.append(
            `colorRequests[${index}].sizeList[${indexSize}].quantity`,
            size?.quantity
          );
          formData.append(
            `colorRequests[${index}].sizeList[${indexSize}].sizeValue`,
            size?.sizeValue
          );
        });
      });
      dispatch(createProduct(formData)).then((res) => {
        if (res.type === POST_PRODUCT_SUCCESS) {
          toast.success("Tạo sản phẩm thành công", {
            duration: 2000,
          });
          setTimeout(() => {
            navigate(PATH.products);
          }, 500);
        }
        if (res.type === POST_PRODUCT_FAILURE) {
          toast.error(res?.payload?.data?.message, {
            duration: 2000,
          });
        }
      });
    } else if (
      window.location.pathname?.match(PATH.edit_product) &&
      idProduct
    ) {
      formData.append("categoryId", fields.categoryId?.race);
      formData.append("code", fields.code);
      formData.append("status", fields.status?.race);
      formData.append("collectionId", fields.collectionId?.race);
      formData.append("description", fields.description);
      formData.append("genderId", fields?.genderId.race);
      formData.append("name", fields.name);
      formData.append("price", fields.price);
      formData.append("templateInfo", fields.templateInfo);
      fields.colorRequests?.forEach((item, index) => {
        formData.append(`colorRequests[${index}].colorCode`, item?.colorCode);
        formData.append(`colorRequests[${index}].colorName`, item?.colorName);
        formData.append(`colorRequests[${index}].id`, item?.id);
        item?.imageList?.forEach((file, indexFile) => {
          if(file?.file && file?.file instanceof File){
            formData.append(
              `colorRequests[${index}].imageList[${indexFile}].file`,
              file?.file
            );
          } else {
            formData.append(
              `colorRequests[${index}].imageList[${indexFile}].linkImg`,
              file?.linkImg
            );
          }
        });
        item?.sizeList?.forEach((size, indexSize) => {
          formData.append(
            `colorRequests[${index}].sizeList[${indexSize}].quantity`,
            size?.quantity
          );
          formData.append(
            `colorRequests[${index}].sizeList[${indexSize}].sizeValue`,
            size?.sizeValue
          );
          if (size?.id) {
            formData.append(
              `colorRequests[${index}].sizeList[${indexSize}].id`,
              size?.id
            );
          }
        });
      });
      dispatch(updateProduct(idProduct, formData)).then((res) => {
        if (res.type === PUT_PRODUCT_SUCCESS) {
          toast.success("Cập nhật sản phẩm thành công", {
            duration: 2000,
          });
          setTimeout(() => {
            navigate(PATH.products);
          }, 500);
        }
        if (res.type === PUT_PRODUCT_FAILURE) {
          toast.error(res?.payload?.data?.message, {
            duration: 2000,
          });
        }
      });
    }
  }

  function onChangeFile(e, i, values, setValues) {
    if (e?.target?.files.length) {
      values.colorRequests[i].imageList = [];
      for (let index = 0; index < e?.target?.files.length; index++) {
        const newItem = {
          file: e?.target?.files[index],
        };
        values.colorRequests[i].imageList.push(newItem);
        setValues({ ...values });
      }
    }
  }

  function getImage(file) {
    if (file instanceof File) {
      return URL.createObjectURL(file);
    } else {
      return file;
    }
  }

  function handleAddProduct(index, values, setValues) {
    const newItem = {
      colorCode: "",
      colorName: "",
      imageList: [],
      sizeList: [
        {
          sizeValue: "",
          quantity: 0,
        },
      ],
    };
    if (!index) {
      values.colorRequests.push(newItem);
      setValues({ ...values });
    } else {
      values.colorRequests.splice(index, 0, newItem);
      setValues({ ...values });
    }
  }

  function handleRemoveProduct(index, values, setValues) {
    values.colorRequests.splice(index, 1);
    setValues({ ...values });
  }

  function handleRemoveSizeProduct(indexColor, indexSize, values, setValues) {
    values?.colorRequests[indexColor]?.sizeList?.splice(indexSize, 1);
    setValues({ ...values });
  }

  function handleAddSizeProduct(indexColor, indexSize, values, setValues) {
    const newItem = {
      sizeValue: "",
      quantity: 0,
    };
    if (!indexSize && !indexSize !== 0) {
      values?.colorRequests[indexColor]?.sizeList?.push(newItem);
      setValues({ ...values });
    } else {
      values?.colorRequests[indexColor]?.sizeList?.splice(
        indexSize,
        0,
        newItem
      );
      setValues({ ...values });
    }
  }

  async function handleGetCollectionByGender(dataGender) {
    const payloadGender = dataGender || gender;
    let result = [];
    if (payloadGender?.name) {
      const genderData =
        payloadGender?.name === "Nữ" ||
        payloadGender?.name === OPTIONSGENDER.FEMALE
          ? OPTIONSGENDER.FEMALE
          : payloadGender?.name === "Nam" ||
            payloadGender?.name === OPTIONSGENDER.MALE
          ? OPTIONSGENDER.MALE
          : OPTIONSGENDER.UNISEX;
      await dispatch(getCollectionByGender(genderData)).then((res) => {
        if (res.type === GET_COLLECTION_BY_GENDER_SUCCESS) {
          const listData = [
            {
              name: "Không thuộc bộ sưu tập",
              race: 0,
            },
          ];

          const newData = res.payload?.map((item) => ({
            name: item?.setting_title,
            race: item?.setting_id,
          }));
          result = [...listData, ...newData];
          setListCollections(result);
          setCollections(listData[0]);
        }
      });
    }

    return result;
  }

  async function handlePatchData(defaultVal) {
    const dataAllCategory = await dispatch(getAllCategory()).then((res) => {
      if ((res.type = GET_ALL_CATEGORY_SUCCESS)) {
        return res?.payload;
      }
    });

    const dataAllGender = await dispatch(getAllGender()).then((res) => {
      if (res?.type === GET_ALL_GENDER_SUCCESS) {
        return res?.payload?.data;
      }
    });

    const dataSelectCategory =
      dataAllCategory?.map((itemCate) => ({
        name: itemCate?.name,
        race: itemCate?.id,
      })) ?? [];

    const dataSelectGender = dataAllGender?.map((item) => ({
      name:
        item?.setting_title === OPTIONSGENDER.MALE
          ? "Nam"
          : item?.setting_title === OPTIONSGENDER.FEMALE
          ? "Nữ"
          : "Unisex",
      race: item?.setting_id,
    }));

    setListCategory(dataSelectCategory);
    setListGender(dataSelectGender);

    if (window.location.pathname === PATH.create_product) {
      const dataSelectCollection = await handleGetCollectionByGender(
        dataSelectGender[0]
      );
      defaultVal.status = listStatus[0];
      defaultVal.genderId = dataSelectGender[0];
      defaultVal.categoryId = dataSelectCategory[0];
      defaultVal.collectionId = await dataSelectCollection[0];
      setStatus(listStatus[0]);
      setCategory(dataSelectCategory[0]);
      setGender(dataSelectGender[0]);
      setCollections(dataSelectCollection[0]);
    } else if (
      window.location.pathname?.match(PATH.edit_product) &&
      idProduct
    ) {
      const dataDetailProduct = await dispatch(
        getDeatilProduct(idProduct)
      ).then((res) => {
        if (res?.type === GET_DETAIL_PRODUCT_SUCCESS) {
          return res?.payload?.data;
        }
      });

      const dataSelectCollection = await handleGetCollectionByGender({
        name: dataDetailProduct?.gender,
      });

      const colorRequests = await dataDetailProduct?.colorList?.map(
        (resColor) => {
          const imageList = resColor?.images?.map((resImage) => ({
            file: resImage?.linkImg,
            linkImg: resImage?.linkImg,
          }));

          const sizeList = resColor?.productSizeList?.map((resSize) => ({
            sizeValue: resSize?.sizeValue,
            quantity: resSize?.quantity,
            id: resSize?.id,
          }));

          return {
            colorCode: resColor?.colorCode,
            colorName: resColor?.colorName,
            id: resColor?.id,
            imageList,
            sizeList,
          };
        }
      );
      const findValGender = await dataAllGender?.find(
        (item) => item?.setting_title === dataDetailProduct?.gender
      );
      const valPatchGender = await dataSelectGender?.find(
        (item) => item?.race === findValGender?.setting_id
      );

      const findValCategory = await dataAllCategory?.find(
        (item) => item?.id === dataDetailProduct?.category?.id
      );
      const valPatchCategory = await dataSelectCategory?.find(
        (item) => item?.race === findValCategory?.id
      );

      const valPatchCollection = await dataSelectCollection?.find(
        (item) => item?.race === dataDetailProduct?.collection?.id
      );

      defaultVal.code = await dataDetailProduct?.code;
      defaultVal.name = await dataDetailProduct?.name;
      defaultVal.status = (await dataDetailProduct?.status)
        ? listStatus[0]
        : listStatus[1];
      defaultVal.description = await dataDetailProduct?.description;
      defaultVal.templateInfo = await dataDetailProduct?.htmlInfor;
      defaultVal.price = await dataDetailProduct?.price;
      defaultVal.colorRequests = await colorRequests;
      defaultVal.categoryId = await valPatchCategory;
      defaultVal.genderId = await valPatchGender;
      defaultVal.collectionId = await valPatchCollection;
      setStatus(dataDetailProduct?.status ? listStatus[0] : listStatus[1]);
      setCategory(valPatchCategory);
      setGender(valPatchGender);
      setCollections(valPatchCollection);
    }

    setInitialValues(defaultVal);
  }

  React.useEffect(() => {
    let defaultVal = {
      code: "",
      name: "",
      status: "",
      description: "",
      templateInfo: htmlData,
      price: 0,
      categoryId: "",
      collectionId: "",
      genderId: "",
      colorRequests: [
        {
          colorCode: "#000000",
          colorName: "",
          imageList: [],
          sizeList: [
            {
              sizeValue: "",
              quantity: 0,
              id: "",
            },
          ],
        },
      ],
    };

    handlePatchData(defaultVal);
  }, []);

  async function handleOnChangeGender(newValue, values, setValues) {
    const dataSelectCollection = await handleGetCollectionByGender(newValue);
    setValues({
      ...values,
      genderId: newValue,
      collectionId: dataSelectCollection[0],
    });
    setGender(newValue);
  }

  function onChangeSize(i, idxSize, newValue, values, setValues) {
    // let colorRequests = [...values.colorRequests];
    // colorRequests[i]?.sizeList[idxSize]?.sizeValue = newValue;
    // setValues({...values,colorRequests})
  }

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
    e.dataTransfer.dropEffect = "move";
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };

  const handleDrop = (e, newIndex, move) => {
    e.preventDefault();
    const oldIndex = e.dataTransfer.getData("text/plain");
    move(oldIndex, newIndex);
  };

  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerClassName="custom-toatser"
      />
      <Typography
        sx={{
          flex: "1 1 100%",
          display: "flex",
          justifyContent: "space-between",
        }}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        <div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              href={PATH.products}
            >
              <ArrowBackIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Quản lý sản phẩm
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              Chỉnh sửa sản phẩm
            </Typography>
          </Breadcrumbs>
        </div>
      </Typography>
      {initialValues && (
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, values, touched, setValues }) => (
            <Form>
              <div className="form-row">
                <div className="form-group col-12 col-lg-6">
                  <label>Mã sản phẩm</label>
                  <Field
                    name="code"
                    type="text"
                    disabled={idProduct ? true : false}
                    className={
                      "form-control" +
                      (errors.code && errors.code && touched.code
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      const newVal = e?.target?.value
                        ?.trim()
                        ?.split(" ")
                        ?.join("");
                      setValues({ ...values, code: newVal });
                    }}
                  />
                  <ErrorMessage
                    name="code"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group col-12 col-lg-6">
                  <label>Tên sản phẩm</label>
                  <Field
                    name="name"
                    type="text"
                    className={
                      "form-control" +
                      (errors.name && errors.name && touched.name
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group col-12">
                  <label>Mô tả sản phẩm</label>
                  <Field name="description" type="text">
                    {({ field }) => (
                      <Textarea
                        {...field}
                        aria-label="minimum height"
                        minRows={3}
                        maxRows={4}
                        className={
                          "form-control" +
                          (errors.description &&
                          errors.description &&
                          touched.description
                            ? " is-invalid"
                            : "")
                        }
                      />
                    )}
                  </Field>
                </div>

                <div className="form-group col-12">
                  <label>Thông tin thêm của sản phẩm</label>
                  <Field name="templateInfo" type="text">
                    {({ field }) => (
                      <Textarea
                        {...field}
                        aria-label="minimum height"
                        minRows={5}
                        maxRows={7}
                        className={
                          "form-control" +
                          (errors.templateInfo &&
                          errors.templateInfo &&
                          touched.templateInfo
                            ? " is-invalid"
                            : "")
                        }
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="templateInfo"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group col-12 col-lg-4">
                  <label>Giá (VND)</label>
                  <Field
                    name="price"
                    type="number"
                    className={
                      "form-control" +
                      (errors.price && errors.price && touched.price
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => {
                      const newVal = parseFloat(e?.target?.value);
                      setValues({ ...values, price: newVal });
                    }}
                  />

                  <ErrorMessage
                    name="price"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group col-12 col-lg-4">
                  <label>Trạng thái</label>
                  <Select
                    name="status"
                    value={status}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setValues({ ...values, status: newValue });
                        setStatus(newValue);
                      }
                    }}
                  >
                    {listStatus.map((c) => (
                      <Option key={c.name} value={c}>
                        {c.name}
                      </Option>
                    ))}
                  </Select>
                </div>

                <div className="form-group col-6 col-lg-4">
                  <label>Giới tính</label>
                  <Select
                    name="genderId"
                    value={gender}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        handleOnChangeGender(newValue, values, setValues);
                      }
                    }}
                  >
                    {listGender.map((c) => (
                      <Option key={c.name} value={c}>
                        {c.name}
                      </Option>
                    ))}
                  </Select>
                </div>

                <div className="form-group col-6 col-lg-4">
                  <label>Thuộc bộ sưu tập</label>
                  <Select
                    name="collectionId"
                    value={collections}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setValues({ ...values, collectionId: newValue });
                        setCollections(newValue);
                      }
                    }}
                  >
                    {listCollections.map((c) => (
                      <Option key={c.name} value={c}>
                        {c.name}
                      </Option>
                    ))}
                  </Select>
                </div>

                <div className="form-group col-6 col-lg-4">
                  <label>Loại sản phẩm</label>
                  <Select
                    name="categoryId"
                    value={category}
                    onChange={(event, newValue) => {
                      setValues({ ...values, categoryId: newValue });
                      setCategory(newValue);
                    }}
                  >
                    {listCategory.map((c) => (
                      <Option key={c.name} value={c}>
                        {c.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <FieldArray name="colorRequests">
                {({ insert, remove }) =>
                  values.colorRequests.map((item, i) => {
                    const itemColorRequestsErrors =
                      (errors.colorRequests?.length &&
                        errors.colorRequests[i]) ||
                      {};

                    const itemColorRequestsTouched =
                      (touched.colorRequests?.length &&
                        touched.colorRequests[i]) ||
                      {};
                    return (
                      <div key={i}>
                        <div className="list-group-item">
                          <h5 className="card-title">Sản phẩm {i + 1}</h5>
                          <div className="form-row">
                            <div className="form-group col-2 col-lg-1">
                              <label style={{ minHeight: "18px" }}></label>
                              <Field
                                name={`colorRequests.${i}.colorCode`}
                                type="color"
                                className={"form-control"}
                              />
                            </div>
                            <div className="form-group col-10 col-lg-5">
                              <label>Mã màu</label>
                              <Field
                                name={`colorRequests.${i}.colorCode`}
                                type="text"
                                className={
                                  "form-control" +
                                  (itemColorRequestsErrors.colorCode &&
                                  itemColorRequestsErrors.colorCode &&
                                  itemColorRequestsTouched.colorCode
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name={`colorRequests.${i}.colorCode`}
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="form-group col-12 col-lg-6">
                              <label>Tên màu hiển thị</label>
                              <Field
                                name={`colorRequests.${i}.colorName`}
                                type="text"
                                className={
                                  "form-control" +
                                  (itemColorRequestsErrors.colorName &&
                                  itemColorRequestsErrors.colorName &&
                                  itemColorRequestsTouched.colorName
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name={`colorRequests.${i}.colorName`}
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="form-group col-12">
                              <input
                                id={`colorRequests.${i}.imageList`}
                                type="file"
                                className="custom-input-file"
                                multiple
                                onChange={(e) => {
                                  onChangeFile(e, i, values, setValues);
                                }}
                              />

                              <label
                                htmlFor={`colorRequests.${i}.imageList`}
                                className={
                                  "form-control" +
                                  ((itemColorRequestsErrors.colorName &&
                                    itemColorRequestsErrors.colorName &&
                                    itemColorRequestsTouched.colorName) ||
                                  (itemColorRequestsErrors.colorCode &&
                                    itemColorRequestsErrors.colorCode &&
                                    itemColorRequestsTouched.colorCode)
                                    ? " is-invalid"
                                    : "" +
                                      " custom-label-input col-12 custom-upload-list-images mb-5")
                                }
                              >
                                Chọn ảnh
                              </label>
                            </div>
                            <FieldArray name={`colorRequests.${i}.imageList`}>
                              {({ move, remove }) =>
                                values.colorRequests[i].imageList?.map(
                                  (imageItem, idxImage) => {
                                    return (
                                      <div
                                        key={idxImage}
                                        className="col-2 col-lg-1 pb-3"
                                      >
                                        <Field
                                          name={`colorRequests.${i}.imageList.${idxImage}.file`}
                                          type="text"
                                        >
                                          {({ filed }) => (
                                            <div
                                              className="item-drag"
                                              draggable
                                              onDragStart={(e) =>
                                                handleDragStart(e, idxImage)
                                              }
                                              onDragOver={handleDragOver}
                                              onDrop={(e) =>
                                                handleDrop(e, idxImage, move)
                                              }
                                            >
                                              <img
                                                style={{ maxWidth: 100 }}
                                                className="custom-image-product"
                                                src={
                                                  `${getImage(
                                                    imageItem?.file
                                                  )}` + ""
                                                }
                                                alt="preview image"
                                                width="100%"
                                                height="100%"
                                              />
                                              <IconButton
                                                className="custom-remove-item-product-img"
                                                onClick={() => {
                                                  remove(idxImage);
                                                }}
                                              >
                                                <CloseIcon />
                                              </IconButton>
                                            </div>
                                          )}
                                        </Field>
                                      </div>
                                    );
                                  }
                                )
                              }
                            </FieldArray>

                            <FieldArray name={`colorRequests.${i}.sizeList`}>
                              {({ insert, remove }) =>
                                values.colorRequests[i].sizeList?.map(
                                  (value, idxSize) => {
                                    const itemSizeListErrors =
                                      (errors.colorRequests?.length &&
                                        errors.colorRequests[i]?.sizeList
                                          ?.length &&
                                        errors.colorRequests[i]?.sizeList[
                                          idxSize
                                        ]) ||
                                      {};
                                    const itemSizeListTouched =
                                      (touched.colorRequests?.length &&
                                        touched.colorRequests[i]?.sizeList
                                          ?.length &&
                                        touched.colorRequests[i]?.sizeList[
                                          idxSize
                                        ]) ||
                                      {};
                                    return (
                                      <div
                                        key={idxSize}
                                        className="col-12 mb-5"
                                      >
                                        <div className="list-group-item">
                                          <div className="form-row">
                                            <div className="form-group col-12 col-lg-6">
                                              <label>Kích cỡ</label>
                                              <Field
                                                name={`colorRequests.${i}.sizeList.${idxSize}.sizeValue`}
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (itemSizeListErrors.sizeValue &&
                                                  itemSizeListErrors.sizeValue &&
                                                  itemSizeListTouched.sizeValue
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              />
                                              <ErrorMessage
                                                name={`colorRequests.${i}.sizeList.${idxSize}.sizeValue`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                            <div className="form-group col-12 col-lg-6">
                                              <label>Số lượng</label>
                                              <Field
                                                name={`colorRequests.${i}.sizeList.${idxSize}.quantity`}
                                                type="number"
                                                className={
                                                  "form-control" +
                                                  (itemSizeListErrors.quantity &&
                                                  itemSizeListErrors.quantity &&
                                                  itemSizeListTouched.quantity
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              />
                                              <ErrorMessage
                                                name={`colorRequests.${i}.sizeList.${idxSize}.quantity`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <IconButton
                                          className="custom-add-item-product"
                                          onClick={() => {
                                            insert(idxSize + 1, {
                                              sizeValue: "",
                                              quantity: 0,
                                            });
                                          }}
                                        >
                                          <AddCircleOutlineIcon />
                                        </IconButton>

                                        {values?.colorRequests?.length &&
                                          values.colorRequests[i]?.sizeList
                                            ?.length &&
                                          values?.colorRequests[i]?.sizeList
                                            .length > 1 && (
                                            <IconButton
                                              className="custom-remove-item-product"
                                              onClick={() => {
                                                remove(idxSize);
                                              }}
                                            >
                                              <CloseIcon />
                                            </IconButton>
                                          )}
                                      </div>
                                    );
                                  }
                                )
                              }
                            </FieldArray>
                          </div>
                          <IconButton
                            className="custom-add-item-product"
                            onClick={() => {
                              insert(i + 1, {
                                colorCode: "#000000",
                                colorName: "",
                                imageList: [],
                                sizeList: [
                                  {
                                    sizeValue: "",
                                    quantity: 0,
                                  },
                                ],
                              });
                              // handleAddProduct(i, values, setValues);
                            }}
                          >
                            <AddCircleOutlineIcon />
                          </IconButton>
                          <IconButton
                            className="custom-remove-item-product"
                            onClick={() => {
                              remove(i);
                              // handleRemoveProduct(i, values, setValues);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                        <br />
                        <br />
                      </div>
                    );
                  })
                }
              </FieldArray>

              {!values.colorRequests.length && (
                <div className="card-footer text-center border-top-0">
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      handleAddProduct(null, values, setValues);
                    }}
                  >
                    Thêm sản phẩm theo màu
                  </Button>
                </div>
              )}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="submit"
                  variant="contained"
                >
                  Lưu
                </Button>
                <Button
                  variant="outlined"
                  style={{ marginLeft: "1rem" }}
                  onClick={() => {
                    navigate(PATH.products);
                  }}
                >
                  Hủy
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default EditProduct;

function Select(props) {
  const slots = {
    root: ButtonSelect,
    listbox: Listbox,
    popup: Popup,
    ...props.slots,
  };

  return <BaseSelect {...props} slots={slots} />;
}

Select.propTypes = {
  /**
   * The components used for each slot inside the Select.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  slots: PropTypes.shape({
    listbox: PropTypes.elementType,
    popup: PropTypes.elementType,
    root: PropTypes.elementType,
  }),
};

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const ButtonSelect = React.forwardRef(function Button(props, ref) {
  const { ownerState, ...other } = props;
  return (
    <StyledButton type="button" {...other} ref={ref}>
      {other.children}
      <UnfoldMoreRoundedIcon />
    </StyledButton>
  );
});

ButtonSelect.propTypes = {
  children: PropTypes.node,
  ownerState: PropTypes.object.isRequired,
};

const StyledButton = styled("button", { shouldForwardProp: () => true })(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  // min-width: 320px;
  width: 100%;
  padding: 8px 12px;
  border-radius: 8px;
  text-align: left;
  line-height: 1.5;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  position: relative;
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
  }

  &.${selectClasses.focusVisible} {
    outline: 0;
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  & > svg {
    font-size: 1rem;
    position: absolute;
    height: 100%;
    top: 0;
    right: 10px;
  }
  `
);

const Listbox = styled("ul")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  // min-width: 320px;
  width: 100%;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  box-shadow: 0px 2px 6px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.50)" : "rgba(0,0,0, 0.05)"
  };
  `
);

const Option = styled(BaseOption)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &:focus-visible {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
  }

  &.${optionClasses.highlighted}.${optionClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const Popup = styled("div")`
  z-index: 100;
`;

const Paragraph = styled("p")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  margin: 12px 0;
  color: ${theme.palette.mode === "dark" ? grey[200] : grey[700]};
  `
);

const Pre = styled("pre")(
  ({ theme }) => `
  font-family: monospace;
  font-size: 0.75rem;
  color: ${theme.palette.mode === "dark" ? "#FFF" : "#000"};
  `
);
const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);
