import CloseIcon from "@mui/icons-material/Close";
import { Backdrop, CircularProgress, IconButton, List } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import React, { useEffect, useLayoutEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "../CSS/SinglePage.css";
import "../CSS/style.css";
import { setRefreshStage } from "../Redux/App/action";
import ProductPage from "../Routes/ProductPage";
import { getDetailProduct } from "../api/productService";
import Footer from "./Footer-component/Footer-component";
import DrawerBody from "./Product-Page-Component/DrawerBody";

// Drawer components
const drawerWidth = 382;

function formatCurrency(value) {
  // Convert the number to a string
  const strValue = value?.toString();

  // Use a regular expression to format the string with dots
  const formattedValue = strValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  return formattedValue + " VND";
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const SinglePage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const refreshStage = useSelector((state) => state.AppReducer.refreshStage);

  const [opend, setOpend] = useState(false);
  const [mainImage, setMainImage] = useState("");
  const [thumbnails, setThumbnails] = useState([]);
  const [showContent, setShowContent] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [hoveredImage, setHoveredImage] = useState("");
  const [productDetail, setProductDetail] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [colorDetail, setColorDetail] = useState(null);
  const [sizeProduct, setSizeProduct] = useState(null);
  const [sizeVal, setSizeVal] = useState("");
  const [colorVal, setColorVal] = useState("");
  const [colorName, setColorName] = useState("");
  const [quantityVal, setQuantityVal] = useState("");
  const { single } = useSelector((store) => store.AppReducer);

  const handleDrawerClose = () => {
    setOpend(false);
  };
  const urlProduct = window.location.href;
  const idProduct = urlProduct.split("/").pop();
  useEffect(() => {
    getDetailProduct(
      { id: idProduct },
      (res) => {
        console.log("hehe");
        setProductDetail(res.data);
        setColorList(res.data.colorList);
        setColorDetail(res.data?.colorList[0]);
        setThumbnails(res.data?.colorList[0]?.images);
        setMainImage(res.data?.colorList[0]?.images[0]?.linkImg);
        setSizeList(res.data?.colorList[0]?.productSizeList);
        setColorVal(res.data?.colorList[0]?.colorCode);
        setColorName(res.data?.colorList[0]?.colorName);
        setHoveredImage("");
      },
      (err) => {
        console.error(err);
      }
    );
  }, [idProduct, urlProduct, location]);

  const handleClick = () => {
    const product = {
      id: productDetail?.id,
      name: productDetail?.name,
      price: productDetail?.price,
      quantity: 1,
      property: {
        image: colorDetail?.images[0]?.linkImg,
        color: colorVal,
        colorName: colorName,
        size: sizeVal,
        quantity: quantityVal,
      },
    };

    // Get cart items from localStorage
    let cart = localStorage.getItem("cart");
    if (cart) {
      try {
        cart = JSON.parse(cart); // Try parsing the JSON
      } catch (error) {
        console.error("Error parsing cart from localStorage:", error);
        cart = []; // Set cart to empty array if parsing fails
      }
    } else {
      cart = []; // Set cart to empty array if localStorage item is null or empty
    }

    const productExists = cart.some(
      (item) =>
        item.id === product.id &&
        item.property.color === colorVal &&
        item.property.size === sizeVal
    );

    if (productExists) {
      toast.error("Sản phẩm đã tồn tại trong giỏ hàng!", {
        duration: 3000,
      });
    } else {
      // Add product to cart
      cart.push(product);
      // Save updated cart back to localStorage
      localStorage.setItem("cart", JSON.stringify(cart));
      dispatch(setRefreshStage(refreshStage));

      toast.success("Sản phẩm đã được thêm vào giỏ hàng!", {
        duration: 1500,
      });
    }
  };

  const handleChangeColor = (item) => {
    setColorDetail(item);
    setSizeList(item.productSizeList);
    setMainImage(item?.images[0]?.linkImg);
    setThumbnails(item?.images);
    setColorVal(item?.colorCode);
    setColorName(item?.colorName);
    setSizeVal("");
    setHoveredImage("");
  };

  const handleChangeSize = (item) => {
    setSizeVal(item?.sizeValue);
    setQuantityVal(item?.quantity);
  };

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  const handleMainImageClick = () => {
    setIsFullscreen(true); // Activate fullscreen mode
  };

  const handleOverlayClick = () => {
    setIsFullscreen(false); // Deactivate fullscreen mode
  };

  const handleThumbnailHover = (thumb) => {
    setHoveredImage(thumb?.linkImg);
    setMainImage("");
  };

  useLayoutEffect(() => {
    const appEl = document.querySelector(".App");
    if (appEl) {
      appEl.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="main" id="main">
        <div className="left-all">
          <div className="left-div">
            <div
              onClick={toggleContent}
              className={`${
                showContent ? "button-content-op" : "button-content-df "
              }`}
            >
              <span className={`text-11 ${showContent ? "text-bold" : ""}`} style={{display:"flex" , justifyContent:"center"}}>
                THÔNG TIN THÊM
              </span>
            </div>
            <div className={`content-left-div ${showContent ? "open" : ""}`}>
              {/* <div
                dangerouslySetInnerHTML={{
                  __html: `${productDetail.htmlInfor}`,
                }}
              ></div> */}
              <pre
                style={{
                  whiteSpace: "pre-wrap",
                  display: "block",
                  padding: "8px",
                  fontSize: "11px",
                }}
              >
                {productDetail.htmlInfor}
              </pre>
            </div>
          </div>
        </div>
        <div className="middle-all">
          <div className="image-gallery">
            <img
              className="main-image"
              src={hoveredImage || mainImage}
              alt={single?.name}
              onClick={handleMainImageClick}
            />
          </div>
          <div className="thumbnail-list">
            {thumbnails?.map((thumb, index) => (
              <img
                style={{ height: "80px", width: "50px" }}
                key={index}
                src={thumb?.linkImg}
                alt={`Thumbnail ${index}`}
                className={`thumbnail ${
                  hoveredImage === thumb?.linkImg ||
                  mainImage === thumb?.linkImg
                    ? "active"
                    : ""
                }`}
                onClick={() => handleThumbnailHover(thumb)}
              />
            ))}
          </div>
        </div>
        <div className="right-all" style={{ marginRight: "40px" }}>
          <div className="product-info">
            <div className="product-detail-info">
              <span className="text-11 Name-Product">
                {productDetail?.name}
              </span>
              <span className="text-11 Price-Product">
                {formatCurrency(productDetail?.price)}
              </span>
              <br />
              <span className="text-11 Description-product">
                {productDetail?.description}
              </span>
              <br />
            </div>
            <div>
              <div className="product-detail-info-actions">
                <span className="text-11">
                  Màu sắc: {colorDetail?.colorName}
                </span>
              </div>
              <div className="d-flex group-color">
                {colorList?.map((item, index) => {
                  return (
                    <button
                      key={index}
                      className="Button-Color"
                      style={{ backgroundColor: item.colorCode }}
                      onClick={() => {
                        handleChangeColor(item);
                        setSizeProduct();
                      }}
                    >
                      <div className="detail-color"></div>
                      {colorDetail?.colorName === item.colorName ? (
                        <div className="detail-color-selected"></div>
                      ) : null}
                    </button>
                  );
                })}
              </div>
              <div className="d-flex group-size">
                {sizeList?.map((item) => {
                  return (
                    <button
                      key={item?.sizeId}
                      disabled={item?.quantity === 0}
                      onClick={() => {
                        handleChangeSize(item);
                        setSizeProduct(item);
                      }}
                      className={
                        sizeProduct?.sizeValue === item?.sizeValue
                          ? `product-size-info size-button-active `
                          : `product-size-info`
                      }
                    >
                      <span className="text-11">{item?.sizeValue}</span>
                    </button>
                  );
                })}
              </div>
            </div>

            <div className="product-detail-info-buttons">
              <button
                className="button-add-product"
                onClick={handleClick}
                disabled={!colorVal || !sizeVal}
              >
                <span className="text-button">THÊM</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="main-mobile">
        <div className="middle-all">
          <div className="thumbnail-list">
            {thumbnails.map((thumb, index) => (
              <img
                key={index}
                src={thumb?.linkImg}
                alt={`Thumbnail ${index}`}
                onClick={() => handleThumbnailHover(thumb)}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="main-mobile">
        <div className="right-all">
          <div className="product-info">
            <div className="product-detail-info">
              <span className="text-11 Name-Product">
                {productDetail?.name}
              </span>
              <span className="text-11 Price-Product">
                {formatCurrency(productDetail?.price)}
              </span>
            </div>
            <div>
              <div className="product-detail-info-actions">
                <span className="text-11">
                  Màu sắc: {colorDetail?.colorName}
                </span>
              </div>
              <div className="d-flex group-color">
                {colorList?.map((item, index) => (
                  <button
                    key={index}
                    className="Button-Color"
                    style={{ backgroundColor: item.colorCode }}
                    onClick={() => handleChangeColor(item)}
                  >
                    <div className="detail-color"></div>
                    {colorDetail?.colorName === item.colorName ? (
                      <div className="detail-color-selected"></div>
                    ) : null}
                  </button>
                ))}
              </div>
              <div className="d-flex group-size">
                {sizeList?.map((item) => (
                  <button
                    key={item.sizeId}
                    className={
                      sizeProduct?.sizeValue === item?.sizeValue
                        ? `product-size-info size-button-active `
                        : `product-size-info`
                    }
                    onClick={() => {
                      handleChangeSize(item);
                      setSizeProduct(item);
                    }}
                  >
                    <span className="text-11">{item?.sizeValue}</span>
                  </button>
                ))}
              </div>
            </div>

            <div className="product-detail-info-buttons">
              <button
                className="button-add-product"
                onClick={handleClick}
                disabled={!colorVal || !sizeVal}
              >
                <span className="text-button">THÊM</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="match-with">
        <h3 className="" style={{ paddingBottom: "8px", paddingRight: "8px" }}>
          Có thể bạn sẽ thích
        </h3>
        <ProductPage limit={6} />
      </div>
      {isFullscreen && (
        <div className="fullscreen-overlay" onClick={handleOverlayClick}>
          <img src={mainImage} alt="Fullscreen" />
        </div>
      )}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="temporary"
        anchor="right"
        open={opend}
        onClose={handleDrawerClose}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
        </DrawerHeader>
        <List>
          <DrawerBody />
        </List>
      </Drawer>
      <div className="footer-product-detail">
        <Footer />
      </div>
    </>
  );
};

export default SinglePage;
