import * as React from "react";
import "./Loading-component.css";
import { useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";

function Loading(props) {
  const { isLoading } = useSelector((store) => store.AppReducer);
  const { children } = props;
  return (
    <div {...props} className={isLoading ? "root-loader" : ""}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {children}
    </div>
  );
}

export default Loading;
