import * as React from "react";
import "./App.css";
import Header from "./Components/Header-component/Header-component";
import AllRoutes from "./Routes/AllRoutes";
import "swiper/css/bundle";
import "./CSS/style.css";
import { useDispatch, useSelector } from "react-redux";
import { getDetailUser } from "./Redux/App/action";
import "./CSS/style.css"
import GroupButton from './Components/GroupButton/GroupButton';
function App() {
  const dispatch = useDispatch();
  const { isAuth } = useSelector((store) => store?.AuthReducer);
  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (
        (localStorage.getItem("accessToken") &&
          localStorage.getItem("isLogedCEDI") === "true") ||
        isAuth
      ) {
        dispatch(getDetailUser());
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [isAuth]);
  return (
    <div className="App">
      <Header />
      <AllRoutes />
      <GroupButton/>
    </div>
  );
}

export default App;
