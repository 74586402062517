import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getAllProduct } from "../api/productService";
import ProductCard from "../Components/Product-Page-Component/ProductCard";

const ProductPage = ({ limit }) => {
  const [listProduct, setListProduct] = useState([]);
  const [collectionId, setCollectionId] = useState(
    localStorage.getItem("settingId") || 0
  );

  const fetchProducts = (collectionId) => {
    getAllProduct(
      {
        searchData: null,
        product_id: null,
        category_id: null,
        collection_id: collectionId ? collectionId : null,
        gender_id: null,
        size: limit ? limit : 999,
        page: 0,
      },
      (res) => {
        setListProduct(res.data);
      },
      (err) => {
        console.error(err);
      }
    );
  };

  useEffect(() => {
    const settingId = localStorage.getItem("settingId");
    if (settingId) {
      setCollectionId(settingId);
    }
  }, []);

  useEffect(() => {
    fetchProducts(collectionId);
  }, [limit, collectionId]);

  // Custom hook to monitor localStorage change
  useEffect(() => {
    const interval = setInterval(() => {
      const settingId = localStorage.getItem("settingId");
      if (settingId && settingId !== collectionId) {
        setCollectionId(settingId);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [collectionId]);

  return (
    <ProdContainer>
      {listProduct[0] ? (
        <div className="gridlayout">
          {listProduct.map((item) => {
            return <ProductCard key={item?.id} id={item?.id} item={item} />;
          })}
        </div>
      ) : (
        <div>Các sản phẩm chuẩn bị được ra mắt vào thời gian tới</div>
      )}
      <div className="gridlayout"></div>
    </ProdContainer>
  );
};

export default ProductPage;

const ProdContainer = styled.div`
  width: 90%;
  margin: auto;
  padding-top: 150px;
  margin-bottom: 50px;

  .gridlayout {
    display: grid;
    width: 100%;
    gap: 15px;
    grid-template-columns: repeat(6, 1fr);

    @media (max-width: 1200px) {
      grid-template-columns: repeat(6, 1fr); // Tablet in landscape mode
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(6, 1fr); // Tablet in portrait mode
    }

    @media (max-width: 600px) {
      grid-template-columns: repeat(2, 1fr); // Mobile
    }
  }
`;
