export const GET_LOGIN_REQUEST = "GET_LOGIN_REQUEST";
export const GET_LOGIN_SUCCESS = "GET_LOGIN_SUCCESS";
export const GET_LOGIN_FAILURE = "GET_LOGIN_FAILURE";
export const GET_SIGNOUT_SUCCESS = "GET_SIGNOUT_SUCCESS";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";

export const ADD_SIGNUP_REQUEST = "ADD_SIGNUP_REQUEST";
export const ADD_SIGNUP_SUCCESS = "ADD_SIGNUP_SUCCESS";
export const ADD_SIGNUP_FAILURE = "ADD_SIGNUP_FAILURE";