export const DEFAULT_TIME_AUTO_HIDE_TOASTIFY = 3000; // default toastify
export const OPTIONSGENDER = {
  MALE: "MALE",
  FEMALE: "FEMALE",
  UNISEX: "UNISEX",
};
export const OPTIONSTYPEIMG = {
  DESKTOP: "DESKTOP",
  MOBILE: "MOBILE",
};

export const ENUMGENDER = {
  MALE: 33,
  FEMALE: 34,
  UNISEX: 36,
};

export const ROWSPERPAGEOPTIONS = [10, 20, 50];

export const ERROR_CODE = {
  NOT_LOGIN: 1302
}
