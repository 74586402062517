import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../Routes/router-path";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProducts,
  getAllCategory,
  getAllCollections,
  getAllGender,
  getAllProducts,
  getCollectionByGender,
} from "../../Redux/App/action";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { Option as BaseOption, optionClasses } from "@mui/base/Option";
import { Select as BaseSelect, selectClasses } from "@mui/base/Select";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";
import {
  OPTIONSGENDER,
  ROWSPERPAGEOPTIONS,
} from "../../constants/appConstants";
import {
  DELETE_PRODUCT_SUCCESS,
  GET_ALL_CATEGORY_SUCCESS,
  GET_ALL_GENDER_SUCCESS,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_COLLECTION_BY_GENDER_SUCCESS,
  GET_PRODUCTS_SUCCESS,
} from "../../Redux/App/actionTypes";
import useDebounce from "../../untils/useDebouce";
import toast, { Toaster } from "react-hot-toast";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const options = [
  { label: "Sửa", path: PATH.edit_product, action: "redirect" },
  { label: "Xóa", path: "", action: "delete" },
];

const ITEM_HEIGHT = 48;
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function handleDelete(id, dispatch, callBackDelete, handleGetAllProducts) {
  dispatch(deleteProducts([].concat(id))).then((res) => {
    if (res?.type === DELETE_PRODUCT_SUCCESS) {
      if (callBackDelete) {
        if (typeof id === "object") callBackDelete([]);
        if (typeof id === "number") callBackDelete(null);
      }
      toast.success("Xóa thành công", {
        duration: 2000,
      });
      handleGetAllProducts()
    } else {
      toast.error("Xóa thất bại", {
        duration: 2000,
      });
    }
  });
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Tên",
    minWidth: 200,
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Giá",
    minWidth: 200,
  },
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: "Loại sản phẩm",
    minWidth: 200,
  },
  {
    id: "collection",
    numeric: false,
    disablePadding: false,
    label: "Bộ sưu tập",
    minWidth: 200,
  },
  {
    id: "gender",
    numeric: false,
    disablePadding: false,
    label: "Giới tính",
    minWidth: 200,
  },
  {
    id: "color",
    numeric: false,
    disablePadding: false,
    label: "Màu sắc",
    minWidth: 200,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Trạng thái",
    minWidth: 200,
  },
  {
    id: "createdDate",
    numeric: false,
    disablePadding: false,
    label: "Ngày tạo",
    minWidth: 200,
  },
  {
    id: "updatedDate",
    numeric: false,
    disablePadding: false,
    label: "Ngày sửa",
    minWidth: 200,
  },
  {
    id: "",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ minWidth: headCell.minWidth }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, itemSelected, callBackDelete, handleGetAllProducts } =
    props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} Đã chọn
        </Typography>
      ) : (
        <Typography
          sx={{
            flex: "1 1 100%",
            justifyContent: "space-between",
            display: "flex",
          }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <span>Quản lý sản phẩm</span>
          <Button
            variant="contained"
            onClick={() => {
              navigate(PATH.create_product);
            }}
          >
            Tạo mới
          </Button>
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              handleDelete(
                itemSelected,
                dispatch,
                callBackDelete,
                handleGetAllProducts
              );
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  itemSelected: PropTypes.array,
  callBackDelete: PropTypes.func,
  handleGetAllProducts: PropTypes.func,
};

const ProductManagement = () => {
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  // const [rowsPerPage, setRowsPerPage] = React.useState(ROWSPERPAGEOPTIONS[0]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = React.useState(null);

  const [valFilterSearchData, setValFilterSearchData] = React.useState("");
  const [valFilterCategoryId, setvalFilterCategoryId] = React.useState({});
  const [valFilterCollectionId, setValFilterCollectionId] = React.useState({});
  const [valFilterGenderId, setValFilterGenderId] = React.useState({});

  const [listGender, setListGender] = React.useState([]);
  const [listCollections, setListCollections] = React.useState([]);
  const [listCategory, setlistCategory] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    limit: ROWSPERPAGEOPTIONS[0],
    offset: 0,
  });
  const [limit, setLimit] = React.useState(ROWSPERPAGEOPTIONS[0]);
  const [offset, setOffset] = React.useState(0);
  const [totalPage, setTotalPage] = React.useState(0);
  // const searchDebounce = useDebounce({});
  const listProducts =
    useSelector((store) => store.AppReducer.products?.data) ?? [];

  const handleClickAction = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = listProducts.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleAction = (isDelete, path) => {
    if (isDelete) {
      handleDelete(selectedId, dispatch, setSelectedId, handleGetAllProducts);
    } else {
      navigate(`${path}/${selectedId}`);
    }
    handleClose();
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setOffset(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(event.target.value);
    setOffset(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  function handleGetCollectionByGender(id) {
    dispatch(getCollectionByGender(id)).then((res) => {
      if (res.type === GET_COLLECTION_BY_GENDER_SUCCESS) {
        const listData = [
          {
            name: "Tất cả",
            race: null,
          },
        ];

        const newData = res.payload?.map((item) => ({
          name: item?.setting_title,
          race: item?.setting_id,
        }));
        setListCollections([...listData, ...newData]);
        setValFilterCollectionId(listData[0]);
      }
    });
  }
  React.useEffect(() => {
    // dispatch(getAllCollections());
    dispatch(getAllGender()).then((res) => {
      if (res?.type === GET_ALL_GENDER_SUCCESS) {
        const initData = [
          {
            name: "Tất cả",
            race: null,
          },
        ];
        const newData = res?.payload?.data?.map((item) => ({
          name:
            item?.setting_title === OPTIONSGENDER.MALE
              ? "Nam"
              : item?.setting_title === OPTIONSGENDER.FEMALE
              ? "Nữ"
              : "Unisex",
          race: item?.setting_id,
        }));

        setListGender([...initData, ...newData]);
        setValFilterGenderId(initData[0]);
      }
    });
    dispatch(getAllCategory()).then((res) => {
      if ((res.type = GET_ALL_CATEGORY_SUCCESS)) {
        const listData = [
          {
            name: "Tất cả",
            race: 0,
          },
        ];
        const newData =
          res.payload?.map((item) => ({
            name: item?.name,
            race: item?.id,
          })) ?? [];
        setlistCategory([...listData, ...newData]);
        setvalFilterCategoryId(listData[0]);
      }
    });
  }, []);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      handleGetAllProducts();
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [
    valFilterSearchData,
    valFilterCategoryId,
    valFilterCollectionId,
    valFilterGenderId,
    limit,
    offset,
  ]);

  function handleGetAllProducts() {
    const params = {
      searchData: valFilterSearchData,
      category_id: valFilterCategoryId?.race ? valFilterCategoryId?.race : null,
      collection_id: valFilterCollectionId?.race,
      gender_id: valFilterGenderId?.race,
    };
    dispatch(getAllProducts(params, limit, offset)).then((res) => {
      if (res.type === GET_ALL_PRODUCTS_SUCCESS) {
        setTotalPage(res?.payload?.totalProduct);
      }
    });
  }

  React.useEffect(() => {
    if (valFilterGenderId?.race !== undefined) {
      const genderData =
        valFilterGenderId?.name === "Nữ"
          ? OPTIONSGENDER.FEMALE
          : valFilterGenderId?.name === "Nam"
          ? OPTIONSGENDER.MALE
          : OPTIONSGENDER.UNISEX;
      handleGetCollectionByGender(genderData);
    }
  }, [valFilterGenderId]);
  function formatCurrency(value) {
    // Convert the number to a string
    const strValue = value.toString();

    // Use a regular expression to format the string with dots
    const formattedValue = strValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return formattedValue + " VND";
  }
  return (
    <Box sx={{ width: "100%" }}>
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerClassName="custom-toatser"
      />

      <Paper sx={{ width: "100%", mb: 2, overflow: "hidden" }}>
        <EnhancedTableToolbar
          numSelected={selected?.length}
          itemSelected={selected}
          callBackDelete={setSelected}
          handleGetAllProducts={handleGetAllProducts}
        />
        <Box sx={{ width: "100%" }}>
          <div className="form-row">
            <div className="col-6 col-lg-3">
              <Search className="custom-search-collections">
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  value={valFilterSearchData}
                  onChange={(e) => {
                    setValFilterSearchData(e.target.value);
                  }}
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </div>

            <div className="col-6 col-lg-3">
              <Select
                value={valFilterGenderId}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setValFilterGenderId(newValue);
                  }
                }}
              >
                {listGender.map((c) => (
                  <Option key={c.name} value={c}>
                    {c.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="col-6 col-lg-3">
              <Select
                value={valFilterCollectionId}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setValFilterCollectionId(newValue);
                  }
                }}
              >
                {listCollections?.map((c) => (
                  <Option key={c.name} value={c}>
                    {c.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="col-6 col-lg-3">
              <Select
                value={valFilterCategoryId}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setvalFilterCategoryId(newValue);
                  }
                }}
              >
                {listCategory.map((c) => (
                  <Option key={c.name} value={c}>
                    {c.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </Box>
        <TableContainer sx={{ maxHeight: "65vh" }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            stickyHeader
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={listProducts.length}
            />
            <TableBody>
              {listProducts.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                const valPrice = row?.price;
                const listColor = row?.colorList
                  ?.map((co) => co?.colorName)
                  ?.join(", ");
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onClick={(event) => handleClick(event, row.id)}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell>{formatCurrency(valPrice)}</TableCell>
                    <TableCell>{row.categoryName}</TableCell>
                    <TableCell>
                      {row.collectionName === "collectionnull"
                        ? "Không thuộc bộ sưu tập nào"
                        : row.collectionName}
                    </TableCell>
                    <TableCell>
                      {row.gender.toUpperCase() === "GENDER_MALE"
                        ? "Nam"
                        : row.gender === "GENDER_FEMALE"
                        ? "Nữ"
                        : "Unisex"}
                    </TableCell>
                    <TableCell>{listColor}</TableCell>
                    <TableCell>
                      {row.status ? "Đang hoạt động" : "Không hoạt động"}
                    </TableCell>
                    <TableCell>{row.createDate}</TableCell>
                    <TableCell>{row.updateDate}</TableCell>
                    <TableCell>
                      <div>
                        <IconButton
                          aria-label="more"
                          id={`long-button`}
                          aria-controls={open ? `long-menu` : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={(e) => {
                            handleClickAction(e, row.id);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={ROWSPERPAGEOPTIONS}
          component="div"
          count={totalPage}
          rowsPerPage={limit}
          page={offset}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Menu
        id={`long-menu`}
        MenuListProps={{
          "aria-labelledby": `long-button`,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.path}
            onClick={() => {
              handleAction(option.action === "delete", option.path);
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default ProductManagement;

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

function Select(props) {
  const slots = {
    root: ButtonSelect,
    listbox: Listbox,
    popup: Popup,
    ...props.slots,
  };

  return <BaseSelect {...props} slots={slots} />;
}

const ButtonSelect = React.forwardRef(function Button(props, ref) {
  const { ownerState, ...other } = props;
  return (
    <StyledButton type="button" {...other} ref={ref}>
      {other.children}
      <UnfoldMoreRoundedIcon />
    </StyledButton>
  );
});

ButtonSelect.propTypes = {
  children: PropTypes.node,
  ownerState: PropTypes.object.isRequired,
};

const StyledButton = styled("button", { shouldForwardProp: () => true })(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  // min-width: 320px;
  width: 100%;
  padding: 8px 12px;
  border-radius: 8px;
  text-align: left;
  line-height: 1.5;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  position: relative;
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
  }

  &.${selectClasses.focusVisible} {
    outline: 0;
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  & > svg {
    font-size: 1rem;
    position: absolute;
    height: 100%;
    top: 0;
    right: 10px;
  }
  `
);

const Listbox = styled("ul")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  // min-width: 320px;
  width: 100%;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  box-shadow: 0px 2px 6px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.50)" : "rgba(0,0,0, 0.05)"
  };
  `
);

const Option = styled(BaseOption)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &:focus-visible {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
  }

  &.${optionClasses.highlighted}.${optionClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const Popup = styled("div")`
  z-index: 100;
`;
