import { Link, Navigate, useNavigate } from "react-router-dom";
import "../CSS/Login.css";
import { useState, useRef } from "react";
import { userApi } from "../api/userApi";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import toast, { Toaster } from "react-hot-toast";
import * as types from "../Redux/Auth/actionTypes";
import Footer from "../Components/Footer-component/Footer-component";
import { ReactComponent as Logo } from "../constants/svg/DA-01.svg";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { saveUserInfo } from "../untils/storage";
const LogIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuth } = useSelector((store) => store.AuthReducer);
  const [open, setOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isActiveForgot, setIsActiveForgot] = useState(1);
  const [data, setData] = useState({
    userName: "",
    email: "",
    password: "",
    otp: "",
  });

  const handleVerifyAdmin = async (e) => {
    e.preventDefault();
    setOpen(true);
    return userApi
      .verifyAdmin(data)
      .then((response) => {
        toast.success("Đăng nhập thành công", {
          duration: 1500,
        });
        saveUserInfo(
          response?.accessToken,
          response?.email,
          response?.id,
          response?.roles
        );
        localStorage.setItem("accessToken", response?.accessToken);
        localStorage.setItem("isLogedCEDI", "true");
        localStorage.setItem("role", "ROLE_ADMIN");
        setOpen(false);
        return dispatch({ type: types.GET_LOGIN_SUCCESS, payload: response });
      })
      .catch((error) => {
        toast.error("Nhập sai mã OTP, Vui lòng kiểm tra lại !", {
          duration: 3000,
        });
        setOpen(false);
      });
  };

  const handleLogin = async (e) => {
    setOpen(true);
    e.preventDefault();
    dispatch({ type: types.GET_LOGIN_REQUEST });
    if (data?.userName && data?.password) {
      return userApi
        .loginAccount(data)
        .then((response) => {
          if (response?.code === 200) {
            toast.success(
              "Đăng nhập thành công, Vui lòng lấy mã OTP để xác nhận !",
              {
                duration: 1500,
              }
            );
            setIsAdmin(true);
            setOpen(false);
          } else {
            toast.success("Đăng nhập thành công", {
              duration: 1500,
            });
            saveUserInfo(
              response?.accessToken,
              response?.email,
              response?.id,
              response?.roles
            );
            localStorage.setItem("accessToken", response?.accessToken);
            localStorage.setItem("isLogedCEDI", "true");
            localStorage.setItem("role", "ROLE_GUEST");
            setOpen(false);
            return dispatch({
              type: types.GET_LOGIN_SUCCESS,
              payload: response,
            });
          }
        })
        .catch((error) => {
          if (
            error?.data?.errorCode === 1102 ||
            error?.data?.errorCode === 1002
          ) {
            toast.error(
              "userName hoặc mật khẩu không đúng. Vui lòng kiểm tra lại !",
              {
                duration: 3000,
              }
            );
            setOpen(false);
          } else {
            toast.error(
              "Tên tài khoản không tồn tại. Quý khách vui lòng kiểm tra lại !",
              {
                duration: 5000,
              }
            );
            setOpen(false);
          }
          dispatch({ type: types.GET_LOGIN_FAILURE });
        });
    } else {
      toast.error("Vui lòng nhập Email và mật khẩu !", {
        duration: 3000,
      });
      setOpen(false);
    }
  };
  const handleForgot = async (e) => {
    setOpen(true);
    e.preventDefault();
    dispatch({ type: types.GET_LOGIN_REQUEST });
    if (data?.email) {
      return userApi
        .forgetPassword(data)
        .then((response) => {
          toast.success(
            "Vui lòng kiểm tra hòm thư Email để nhận mã OTP của bạn",
            {
              duration: 5000,
            }
          );
          setIsActiveForgot(3);
          setOpen(false);
          return dispatch({
            type: types.FORGOT_PASSWORD_SUCCESS,
            payload: response,
          });
        })
        .catch((error) => {
          if (error?.data?.errorCode === 1202) {
            toast.error("Email không đúng. Vui lòng kiểm tra lại !", {
              duration: 3000,
            });
            setOpen(false);
          } else {
            toast.error("Server Error!", {
              duration: 5000,
            });
            setOpen(false);
          }
        });
    } else {
      toast.error("Vui lòng nhập Email !", {
        duration: 3000,
      });
      setOpen(false);
    }
  };
  const handleConfirmOtp = async (e) => {
    e.preventDefault();
    setOpen(true);
    if (data?.email && data?.otp) {
      return userApi
        .resetPassword(data)
        .then((response) => {
          toast.success(
            "Mật khẩu của bạn đã được thay đổi. Vui lòng kiểm tra hòm thư Email của bạn !",
            {
              duration: 5000,
            }
          );
          setIsActiveForgot(1);
          setOpen(false);
          return dispatch({
            type: types.FORGOT_PASSWORD_SUCCESS,
            payload: response,
          });
        })
        .catch((error) => {
          if (error?.data?.errorCode === 1402) {
            toast.error("OTP không chính xác !", {
              duration: 3000,
            });
            setOpen(false);
          } else {
            toast.error("Server Error!", {
              duration: 5000,
            });
            setOpen(false);
          }
        });
    } else {
      toast.error("Vui lòng nhập Email !", {
        duration: 3000,
      });
      setOpen(false);
    }
  };
  if (isAuth) {
    return <Navigate to={`/`} />;
  }
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="navbar_space">
        <div className="navbar-logo">
          <Logo
            style={{ height: "140px" }}
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
      </div>
      <div className="Login_main_box">
        <div className="Login_second_box">
          {isActiveForgot === 1 ? (
            isAdmin ? (
              <div className="Login_second_box1">
                <h2>NHẬP MÃ OTP</h2>
                <form action="">
                  <input
                    type="text"
                    placeholder="NHẬP OTP"
                    onChange={(e) => setData({ ...data, otp: e.target.value })}
                  />
                  <br />
                  <br />
                  <button onClick={handleVerifyAdmin}>
                    XÁC NHẬN ĐĂNG NHẬP
                  </button>
                  <br />
                  <b
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={handleLogin}
                  >
                    Gửi lại mã OTP
                  </b>
                </form>
              </div>
            ) : (
              <div className="Login_second_box1">
                <h2>ĐĂNG NHẬP</h2>
                <form action="">
                  <label htmlFor="">TÊN ĐĂNG NHẬP</label>
                  <br />
                  <input
                    type="text"
                    placeholder="Tên đăng nhập"
                    onChange={(e) =>
                      setData({ ...data, userName: e.target.value })
                    }
                  />
                  <br />
                  <br />
                  <label htmlFor="">MẬT KHẨU</label>
                  <br />
                  <input
                    type="password"
                    placeholder="Enter Password"
                    onChange={(e) =>
                      setData({ ...data, password: e.target.value })
                    }
                  />
                  <br />
                  <br />
                  <button onClick={handleLogin}>ĐĂNG NHẬP</button>
                  <br />
                  <b
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => setIsActiveForgot(2)}
                  >
                    BẠN ĐÃ QUÊN MẬT KHẨU ?
                  </b>
                </form>
              </div>
            )
          ) : isActiveForgot === 2 ? (
            <div className="Login_second_box1">
              <h2>QUÊN MẬT KHẨU</h2>
              <form action="">
                <label htmlFor="">ĐỊA CHỈ E-MAIL</label>
                <br />
                <br />
                <input
                  type="email"
                  placeholder="Enter Email"
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                />
                <br />
                <br />
                <button onClick={handleForgot}>QUÊN MẬT KHẨU</button>
                <br />
                <b
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => setIsActiveForgot(1)}
                >
                  QUAY LẠI ĐĂNG NHẬP ?
                </b>
              </form>
            </div>
          ) : (
            <div className="Login_second_box1">
              <h2>NHẬP MÃ OTP</h2>
              <form action="">
                <input
                  type="text"
                  placeholder="NHẬP OTP"
                  onChange={(e) => setData({ ...data, otp: e.target.value })}
                />
                <br />
                <br />
                <button onClick={handleConfirmOtp}>
                  XÁC NHẬN LẤY LẠI MẬT KHẨU
                </button>
                <br />
                <b
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={handleForgot}
                >
                  Gửi lại mã OTP
                </b>
              </form>
            </div>
          )}
          <div className="Login_second_box2">
            <h2>ĐĂNG KÝ</h2>
            <p>
              NẾU BẠN VẪN CHƯA CÓ TÀI KHOẢN{" "}
              <span>
                <b>https://vaidiepanh.com/</b>
              </span>
              , HÃY SỬ DỤNG TÙY CHỌN NÀY ĐỂ TRUY CẬP MẪU ĐĂNG KÝ.
            </p>
            <p>
              BẰNG CÁCH CUNG CẤP CHO CHÚNG TÔI THÔNG TIN CỦA BẠN, MUA HÀNG TRÊN{" "}
              <b>https://vaidiepanh.com/</b> SẼ NHANH HƠN VÀ BẠN SẼ CÓ MỘT TRẢI
              NGHIỆM THÚ VỊ.
            </p>
            <button
              onClick={() => {
                navigate("/signin");
              }}
            >
              TẠO TÀI KHOẢN
            </button>
          </div>

          <Container classname="signbox">
            <p>
              BẠN KHÔNG CÓ TÀI KHOẢN? <Link to={`/signin`}>ĐĂNG KÝ</Link>
            </p>
          </Container>
          <div></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

const Container = styled.div`
  display: none;


@media only screen and (min-width: 769px) and (max-width:845px){
      display:block;
}

@media only screen and (min-width: 481px) and (max-width:768px){

      display:block;

}

@media only screen and (min-width:320px) and (max-width:480px){

      display:block;

}

@media only screen and (max-width: 320px){

      display:block;

`;

export default LogIn;
