import React from "react";
import { Box } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import FacebookIcon from "@mui/icons-material/Facebook";
import Zalo from "../../constants/mock-images/zalo.svg";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

const GroupButton = () => {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:600px)");
  const handleFacebookClick = () => {
    window.open("https://www.facebook.com/vaidepdiepanh", "_blank");
  };
  const handleZaloClick = () => {
    window.open("https://zalo.me/0976999378", "_blank");
  };
  const handlePhoneClick = () => {
    window.location.href = "tel:0976999378";
  };

  if (location.pathname.includes("/admin")) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 146,
        right: isMobile ? -15 : 20,
        zIndex: 9999,
        display: "flex",
        flexDirection: "column",
        p: 1,
        m: 1,
      }}
    >
      <IconButton
        onClick={handlePhoneClick}
        sx={{
          width: 44,
          height: 44,
          margin: "8px",
          backgroundColor: "#4cd86b",
          color: "white",
          transition: "transform 0.3s ease",
          "&:hover": {
            transform: "scale(1.2)",
            backgroundColor: "#366440",
          },
        }}
        aria-label="LocalPhoneIcon"
      >
        <LocalPhoneIcon />
      </IconButton>
      <IconButton
        onClick={handleFacebookClick}
        sx={{
          width: 44,
          height: 44,
          margin: "8px",
          backgroundColor: "#1878f3",
          color: "white",
          transition: "transform 0.3s ease",
          "&:hover": {
            transform: "scale(1.2)",
            backgroundColor: "#003bee",
          },
        }}
        aria-label="FacebookIcon"
      >
        <FacebookIcon />
      </IconButton>
      <IconButton
        onClick={handleZaloClick}
        sx={{
          width: 44,
          height: 44,
          margin: "8px",
          backgroundColor: "white",
          color: "white",
          transition: "transform 0.3s ease",
          "&:hover": {
            transform: "scale(1.2)",
            backgroundColor: "white",
          },
        }}
        aria-label="SendIcon"
      >
        <img
          src={Zalo}
          alt=""
          style={{ height: "44px", width: "44px", borderRadius: "50%" }}
        />
      </IconButton>
    </Box>
  );
};

export default GroupButton;
