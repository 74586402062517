import * as React from "react";
import "./Blog-component.css";

import Footer from "../Footer-component/Footer-component";
import { useDispatch, useSelector } from "react-redux";
import { getAllPost } from "../../Redux/App/action";
import { createSearchParams, useNavigate } from "react-router-dom";
import { PATH } from "../../Routes/router-path";

const Blog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const blogs = useSelector((store) => store.AppReducer?.posts?.content) ?? [];
  const topRef = React.useRef(null);
  React.useEffect(() => {
    dispatch(
      getAllPost({
        name: "",
        pageIndex: 0,
        pageSize: 999,
      })
    );
    topRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <div>
      <div className="container-blog">
        <div className="banner-custom">
          <span className="banner-title" ref={topRef}>Blog</span>
        </div>
        <div className="container-items">
          {blogs.map((item, key) => {
            return (
              <div key={key} className="item">
                <div
                  className="blog-link"
                  onClick={() => {
                    navigate({
                      pathname: PATH.blogs_detail,
                      search: createSearchParams({
                        id: item?.id,
                      }).toString(),
                    });
                  }}
                ></div>
                <article>
                  <img src={item.imgUrl} alt="" />
                  <span>
                    <a>{item.title}</a>
                  </span>
                  <h2 className="custom-body-blog">
                    <a>{item.body}</a>
                  </h2>
                </article>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
