import * as React from "react";
import "./Edit-collection-component.css";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PATH } from "../../../Routes/router-path";
import * as Yup from "yup";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import PropTypes from "prop-types";
import { Select as BaseSelect, selectClasses } from "@mui/base/Select";
import { Option as BaseOption, optionClasses } from "@mui/base/Option";
import { styled } from "@mui/system";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import {
  createCollection,
  getDetailCollection,
  updateDetailCollection,
} from "../../../Redux/App/action";
import { useParams } from "react-router-dom";
import { OPTIONSGENDER, OPTIONSTYPEIMG } from "../../../constants/appConstants";
import {
  POST_COLLECTION_SUCCESS,
  PUT_DETAIL_COLLECTION_SUCCESS,
  GET_DETAIL_COLLECTION_SUCCESS,
  PUT_DETAIL_COLLECTION_FAILURE,
  POST_COLLECTION_FAILURE,
} from "../../../Redux/App/actionTypes";
import toast, { Toaster } from "react-hot-toast";

const EditCollection = () => {
  const defaultValidationSchema = Yup.object().shape({
    title: Yup.string().required("Tên bộ sưu tập không được để trống"),
    description: Yup.string().required("Miêu tả không được để trống"),
    isBanner: Yup.object(),
    status: Yup.object(),
    gender: Yup.object(),
    images: Yup.array().of(
      Yup.object().shape({
        img: Yup.mixed()
          .required("Ảnh không được để trống")
          .test("is-file-or-string", "Ảnh không đúng địng dạng", (value) => {
            // Kiểm tra xem value có phải là một tệp tin không
            return typeof value === "string" || value instanceof File;
          }),
        id: Yup.number(),
        sizeImg: Yup.string().required("sizeImg of tickets is required"),
      })
    ),
  });

  const navigate = useNavigate();
  const [character, setCharacter] = React.useState(characters[0]);
  const [isBanner, setIsBanner] = React.useState(optionBanner[0]);
  const [status, setStatus] = React.useState(optionStatus[0]);
  const [initialValues, setInitialValues] = React.useState(null);
  const [validationSchema, setValidationSchema] = React.useState(
    defaultValidationSchema
  );
  const dispath = useDispatch();
  let { idCollection } = useParams();

  React.useEffect(() => {
    if (window.location.pathname === PATH.create_collection) {
      setStatus(optionStatus[0]);
      setCharacter(characters[0]);
      setIsBanner(optionBanner[0]);
      setInitialValues({
        title: "",
        description: "",
        isBanner: optionBanner[0],
        status: optionStatus[0],
        gender: characters[0],
        images: [{ img: "", sizeImg: optionTypeImg[0] }],
      });
    }
    if (window.location.pathname?.match(PATH.edit_collection) && idCollection) {
      dispath(getDetailCollection(idCollection)).then((res) => {
        if (res?.type === GET_DETAIL_COLLECTION_SUCCESS) {
          const data = res?.payload;
          const listImages = data?.images?.map((item) => {
            return {
              id: item?.id,
              img: item.linkImg,
              sizeImg: optionTypeImg.find(
                (imgSize) => imgSize === item.sizeImg
              ),
            };
          });
          const patchData = {
            title: data.setting_title,
            description: data.desciption,
            status: optionStatus.find((item) => item?.race === data?.status),
            isBanner:
              optionBanner.find((item) => item.race === data.isBanner) ||
              optionBanner[0],
            gender: characters.find((item) => item.race === data.gender),
            images: listImages,
          };
          setStatus(optionStatus.find((item) => item?.race === data?.status));
          setCharacter(characters.find((item) => item.race === data.gender));
          setIsBanner(optionBanner.find((item) => item.race === data.isBanner));
          setInitialValues(patchData);
        }
      });
    }
  }, [window.location.pathname]);

  function onSubmit(fields) {
    const formData = new FormData();
    if (window.location.pathname?.match(PATH.edit_collection) && idCollection) {
      formData.append("setting_title", fields.title);
      formData.append("gender", fields.gender?.race);
      formData.append("isBanner", fields.isBanner?.race);
      formData.append("status", fields.status?.race);
      formData.append("description", fields.description);
      formData.append("setting_id", idCollection);
      fields.images?.forEach((item, index) => {
        if (typeof item?.img !== "string") {
          formData.append(`imageList[${index}].file`, item?.img);
          formData.append(`imageList[${index}].sizeImg`, item?.sizeImg);
          // formData.append(`imageList[${index}].id`, item?.id);
        }
      });
      dispath(updateDetailCollection(formData)).then((res) => {
        if (res.type === PUT_DETAIL_COLLECTION_SUCCESS) {
          toast.success("Cập nhật thành công", {
            duration: 2000,
          });
          setTimeout(() => {
            navigate(PATH.collections);
          }, 500);
        } else if (res.type === PUT_DETAIL_COLLECTION_FAILURE) {
          toast.error("Cập nhật lỗi", {
            duration: 2000,
          });
        }
      });
    } else if (window.location.pathname === PATH.create_collection) {
      formData.append("setting_title", fields.title);
      formData.append("gender", fields.gender?.race);
      formData.append("status", fields.status?.race);
      formData.append("isBanner", fields.isBanner?.race);
      formData.append("description", fields.description);
      fields.images?.forEach((item, index) => {
        formData.append(`imageList[${index}].file`, item?.img);
        formData.append(`imageList[${index}].sizeImg`, item?.sizeImg);
      });

      dispath(createCollection(formData)).then((res) => {
        if (res.type === POST_COLLECTION_SUCCESS) {
          toast.success("Tạo mới thành công", {
            duration: 2000,
          });
          setTimeout(() => {
            navigate(PATH.collections);
          }, 500);
        } else if (res.type === POST_COLLECTION_FAILURE) {
          toast.error("Tạo mới lỗi", {
            duration: 2000,
          });
        }
      });
    }
  }

  const onChangeBanner = (newVal, values, setValues) => {
    const images = [...values.images];
    if (newVal?.race && images?.length === 1) {
      images.push({ img: "", sizeImg: optionTypeImg[1] });
    } else {
      if (images?.length === 2) {
        images.pop();
      }
    }
    setValues({ ...values, images, isBanner: newVal });
  };

  function onChangeFile(e, i, values, setValues) {
    if (e?.target?.files[0]) {
      const images = [...values.images];
      // const formData = new FormData()
      // formData.append("file", e.target.files[0])
      // const objectUrl = URL.createObjectURL(e.target.files[0]);
      images[i].img = e.target.files[0];
      setValues({ ...values, images });
    }
  }

  function removeFile(i, values, setValues) {
    const images = [...values.images];
    images[i].img = "";
    setValues({ ...values, images });
  }

  function getImage(file) {
    if (file instanceof File) {
      return URL.createObjectURL(file);
    } else {
      return file;
    }
  }

  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerClassName="custom-toatser"
      />
      <Typography
        sx={{
          flex: "1 1 100%",
          display: "flex",
          justifyContent: "space-between",
        }}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        <div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              href={PATH.collections}
            >
              <ArrowBackIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Bộ sưu tập
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              {idCollection ? "Chỉnh sửa bộ sưu tập" : "Tạo mới bộ sưu tập"}
            </Typography>
          </Breadcrumbs>
        </div>
      </Typography>
      {initialValues && (
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, values, touched, setValues, validateField }) => (
            <Form>
              <Field name="title">
                {() => (
                  <div className="form-row">
                    <div className="form-group col-12 col-lg-6">
                      <label>Tên bộ sưu tập</label>
                      <Field
                        name="title"
                        type="text"
                        className={
                          "form-control" +
                          (errors.title && errors.title && touched.title
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group col-12 col-lg-6">
                      <label>Miêu tả</label>
                      <Field
                        name="description"
                        type="text"
                        className={
                          "form-control" +
                          (errors.description &&
                          errors.description &&
                          touched.description
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group col-12 col-lg-4">
                      <label>Giới tính</label> {}
                      <Select
                        name="gender"
                        value={character}
                        onChange={(event, newValue) => {
                          setValues({ ...values, gender: newValue });
                          setCharacter(newValue);
                        }}
                      >
                        {characters.map((c) => (
                          <Option key={c.name} value={c}>
                            {c.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className="form-group col-12 col-lg-4">
                      <label>Bộ sưu tập hiện đầu trang</label>
                      <Select
                        name="isBanner"
                        value={isBanner}
                        onChange={(event, newValue) => {
                          setIsBanner(newValue);
                          onChangeBanner(newValue, values, setValues);
                        }}
                      >
                        {optionBanner.map((c) => (
                          <Option key={c.name} value={c}>
                            {c.name}
                          </Option>
                        ))}
                      </Select>
                    </div>

                    <div className="form-group col-12 col-lg-4">
                      <label>Trạng thái</label>
                      <Select
                        name="status"
                        value={status}
                        onChange={(event, newValue) => {
                          setValues({ ...values, status: newValue });
                          setStatus(newValue);
                        }}
                      >
                        {optionStatus.map((c) => (
                          <Option key={c.name} value={c}>
                            {c.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                )}
              </Field>
              <FieldArray name="images">
                {() =>
                  values.images.map((item, i) => {
                    const itemErrors =
                      (errors.images?.length && errors.images[i]) || {};
                    const itemTouched =
                      (touched.images?.length && touched.images[i]) || {};
                    return (
                      <div key={i} className="list-group-item">
                        <h5 className="card-title">Hiển thị {item.sizeImg}</h5>
                        <div className="form-row">
                          <div className="form-group col-6 col-lg-3">
                            <label>Hiển thị cho</label>
                            <Field
                              name={`images.${i}.sizeImg`}
                              type="text"
                              disabled
                              className={
                                "form-control" +
                                (itemErrors.sizeImg &&
                                itemErrors.sizeImg &&
                                itemTouched.sizeImg
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name={`images.${i}.sizeImg`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="form-group col-6 col-lg-9">
                            <label>Chọn ảnh</label>
                            <Field
                              name={`images.${i}.img`}
                              type="file"
                              className={
                                "form-control" +
                                (itemErrors.img &&
                                itemErrors.img &&
                                itemTouched.img
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              {(event) => (
                                <>
                                  <input
                                    {...event?.filed}
                                    id={`images.${i}.img`}
                                    name={`images.${i}.img`}
                                    type="file"
                                    className="custom-input-file"
                                    onChange={(e) => {
                                      onChangeFile(e, i, values, setValues);
                                    }}
                                  />

                                  <label
                                    htmlFor={`images.${i}.img`}
                                    className={
                                      "form-control" +
                                      (itemErrors.img &&
                                      itemErrors.img &&
                                      itemTouched.img
                                        ? " is-invalid"
                                        : "" + " custom-label-input")
                                    }
                                  >
                                    {values.images[i].img
                                      ? values.images[i].img.name ||
                                        values.images[i].img
                                      : "Chọn ảnh:"}
                                  </label>
                                </>
                              )}
                            </Field>
                            <ErrorMessage
                              name={`images.${i}.img`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="col-12 list-group-item custom-preview-img">
                            {values.images[i].img && (
                              <>
                                <div className="cancel-img">
                                  <IconButton
                                    onClick={() => {
                                      removeFile(i, values, setValues);
                                    }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </div>
                                <img
                                  src={`${getImage(values.images[i].img)}` + ""}
                                  alt="preview image"
                                  width="100%"
                                  height="100%"
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })
                }
              </FieldArray>
              <div className="card-footer text-center border-top-0">
                <div>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      console.log(values, errors);
                    }}
                  >
                    Lưu
                  </Button>
                  <Button
                    variant="outlined"
                    style={{ marginLeft: "1rem" }}
                    onClick={() => {
                      navigate(PATH.collections);
                    }}
                  >
                    Hủy
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default EditCollection;

function Select(props) {
  const slots = {
    root: ButtonSelect,
    listbox: Listbox,
    popup: Popup,
    ...props.slots,
  };

  return <BaseSelect {...props} slots={slots} />;
}

Select.propTypes = {
  /**
   * The components used for each slot inside the Select.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  slots: PropTypes.shape({
    listbox: PropTypes.elementType,
    popup: PropTypes.elementType,
    root: PropTypes.elementType,
  }),
};

const characters = [
  { name: "UNISEX", race: OPTIONSGENDER.UNISEX },
  { name: "NAM", race: OPTIONSGENDER.MALE },
  { name: "NỮ", race: OPTIONSGENDER.FEMALE },
];

const optionBanner = [
  { name: "Hiện ngẫu nhiên", race: false },
  { name: "Hiện đầu trang", race: true },
];

const optionStatus = [
  { name: "Đang hoạt động", race: true },
  { name: "Không hoạt động", race: false },
];

const optionTypeImg = [OPTIONSTYPEIMG.DESKTOP, OPTIONSTYPEIMG.MOBILE];

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const ButtonSelect = React.forwardRef(function Button(props, ref) {
  const { ownerState, ...other } = props;
  return (
    <StyledButton type="button" {...other} ref={ref}>
      {other.children}
      <UnfoldMoreRoundedIcon />
    </StyledButton>
  );
});

ButtonSelect.propTypes = {
  children: PropTypes.node,
  ownerState: PropTypes.object.isRequired,
};

const StyledButton = styled("button", { shouldForwardProp: () => true })(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  // min-width: 320px;
  width: 100%;
  padding: 8px 12px;
  border-radius: 8px;
  text-align: left;
  line-height: 1.5;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  position: relative;
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
  }

  &.${selectClasses.focusVisible} {
    outline: 0;
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  & > svg {
    font-size: 1rem;
    position: absolute;
    height: 100%;
    top: 0;
    right: 10px;
  }
  `
);

const Listbox = styled("ul")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  // min-width: 320px;
  width: 100%;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  box-shadow: 0px 2px 6px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.50)" : "rgba(0,0,0, 0.05)"
  };
  `
);

const Option = styled(BaseOption)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &:focus-visible {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
  }

  &.${optionClasses.highlighted}.${optionClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const Popup = styled("div")`
  z-index: 100;
`;

const Paragraph = styled("p")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  margin: 12px 0;
  color: ${theme.palette.mode === "dark" ? grey[200] : grey[700]};
  `
);

const Pre = styled("pre")(
  ({ theme }) => `
  font-family: monospace;
  font-size: 0.75rem;
  color: ${theme.palette.mode === "dark" ? "#FFF" : "#000"};
  `
);
