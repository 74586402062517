import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { menuCollection } from "../../api/cartService";
import * as types from "../../Redux/Auth/actionTypes";
import { clearUserInfo, getToken } from "../../untils/storage";
import "./menuCustom.css";
import { PATH } from "../../Routes/router-path";

const MenuCustom = ({ icon }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openNamList, setOpenNamList] = useState(false);
  const [openNuList, setOpenNuList] = useState(false);
  const [maleList, setMaleList] = useState([]);
  const [femaleList, setFemaleList] = useState([]);
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const navigate = useNavigate();
  const token = getToken();
  const [isLogin, setIsLogin] = useState(token ? true : false);
  const toggleDrawer = (open) => (event) => {
    if (
      event?.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setIsOpen(false);
  };

  const handleLogout = () => {
    clearUserInfo();
    dispatch({ type: types.GET_SIGNOUT_SUCCESS, payload: [] });
    window.location.reload();
  };

  const handleToggleNamList = (event) => {
    event.stopPropagation();
    setOpenNamList(!openNamList);
  };

  const handleToggleNuList = (event) => {
    event.stopPropagation();
    setOpenNuList(!openNuList);
  };

  const handleItemClick = (settingId) => {
    localStorage.setItem("settingId", settingId);
    navigate("/products");
  };

  useEffect(() => {
    menuCollection(
      {
        gender: "MALE",
      },
      (res) => {
        setMaleList(res);
      },
      (err) => {}
    );
  }, []);

  useEffect(() => {
    menuCollection(
      {
        gender: "FEMALE",
      },
      (res) => {
        setFemaleList(res);
      },
      (err) => {}
    );
  }, []);

  const loginItem = "Đăng Nhập";
  const logoutItem = "Đăng xuất";

  const list = (
    <Box
      className={`drawer-box ${
        isMobile || isTablet ? "drawer-auto" : "drawer-fixed"
      }`}
      role="presentation"
      onClick={(event) => {
        if (
          event.target.tagName !== "BUTTON" &&
          event.target.tagName !== "DIV"
        ) {
          toggleDrawer(false)(event);
        }
      }}
      onKeyDown={(event) => {
        if (event.key === "Escape") {
          toggleDrawer(false)(event);
        }
      }}
    >
      <Box className="drawer-close">
        <IconButton onClick={toggleDrawer(false)}>
          <CloseIcon className="icon-white" />
        </IconButton>
      </Box>
      <List>
        <ListItemButton onClick={handleToggleNuList}>
          <ListItemIcon></ListItemIcon>
          <ListItemText primary={"Nữ"} />
          {openNuList ? <RemoveIcon className="rotate-icon" /> : <AddIcon />}
        </ListItemButton>
        <Collapse in={openNuList} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {femaleList.map((item) => {
              return (
                <ListItem
                  key={item?.setting_title}
                  disablePadding
                  onClick={() => handleItemClick(item?.setting_id)}
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary={item?.setting_title} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      </List>
      <Divider />
      <List>
        <ListItemButton onClick={handleToggleNamList}>
          <ListItemIcon></ListItemIcon>
          <ListItemText primary={"Nam"} />
          {openNamList ? <RemoveIcon className="rotate-icon" /> : <AddIcon />}
        </ListItemButton>
        <Collapse in={openNamList} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {maleList.map((item) => {
              return (
                <ListItem
                  key={item?.setting_title}
                  disablePadding
                  onClick={() => handleItemClick(item?.setting_id)}
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary={item?.setting_title} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Collapse>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              localStorage.setItem("settingId", null);
              navigate("/products");
            }}
          >
            <ListItemIcon></ListItemIcon>
            <ListItemText primary={"Sản phẩm của chúng tôi"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              navigate(PATH.blogs);
            }}
          >
            <ListItemIcon></ListItemIcon>
            <ListItemText primary={"Blogs"} />
          </ListItemButton>
        </ListItem>
        {(isMobile || isTablet) && (
          <div>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate("/cart");
                }}
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary={"Giỏ hàng"} />
              </ListItemButton>
            </ListItem>
          </div>
        )}
      </List>
      {(isMobile || isTablet) && (
        <>
          <Divider />
          {isLogin ? (
            <div>
              <ListItemButton
                onClick={() => {
                  navigate("/order-history");
                }}
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary={"Đơn hàng"} />
              </ListItemButton>
              <ListItem disablePadding>
                <ListItemButton onClick={handleLogout}>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary={logoutItem} className="link-text" />
                </ListItemButton>
              </ListItem>
            </div>
          ) : (
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleNavigation("/login")}>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary={loginItem} className="link-text" />
              </ListItemButton>
            </ListItem>
          )}
        </>
      )}
    </Box>
  );

  return (
    <div>
      <IconButton onClick={toggleDrawer(true)}>
        {icon ? icon : <MenuIcon />}
      </IconButton>
      <Drawer
        anchor={isMobile ? "bottom" : "right"}
        open={isOpen}
        onClose={toggleDrawer(false)}
        hysteresis={1}
        onOpen={toggleDrawer(true)}
        PaperProps={{ className: `drawer-paper ${isOpen ? "open" : ""}` }}
        BackdropProps={{ className: "drawer-backdrop" }}
      >
        {list}
      </Drawer>
    </div>
  );
};

export default MenuCustom;
