import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  TablePagination,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getAllOder, updateStatusProduct } from "../../api/cartService";
import toast, { Toaster } from "react-hot-toast";

const Order = () => {
  const [orderList, setOrderList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchPhoneNumber, setSearchPhoneNumber] = useState("");
  const [searchCreatedDate, setSearchCreatedDate] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    getAllOder(
      { pageSize: rowsPerPage, pageIndex: page },
      (res) => {
        setOrderList(res.content);
        setTotalItems(res.totalElements);
      },
      (err) => {
        console.error(err);
      }
    );
  }, [rowsPerPage, page]);

  const handleUpdateProduct = (id, newState) => {
    updateStatusProduct(
      {
        id,
        state: newState,
      },
      (res) => {
        toast.success("Đổi trạng thái thành công!", {
          duration: 3000,
        });
        setOrderList((prevOrderList) =>
          prevOrderList.map((order) =>
            order.id === id ? { ...order, status: newState } : order
          )
        );
      },
      (err) => {
        toast.error("Đổi trạng thái thất bại!", {
          duration: 1500,
        });
        console.error("Lỗi khi đổi trạng thái:", err);
      }
    );
  };

  const handleStatusChange = (event, id) => {
    const newState = event.target.value;
    if (
      getStatusText(newState) === "Đơn hàng hủy bỏ" &&
      getStatusText(orderList?.find((order) => order.id === id).status) ===
        "Đơn hàng hoàn thành"
    ) {
      toast.error("Đơn hàng đã hoàn thành, không thể hủy bỏ!", {
        duration: 1500,
      });
    } else {
      handleUpdateProduct(id, newState);
    }
  };

  const formatDateTime = (dateTimeString) => {
    const d = new Date(dateTimeString);
    d.setHours(d.getHours() + 7);

    const day = d.getDate().toString().padStart(2, "0");
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const year = d.getFullYear();
    const hours = d.getHours().toString().padStart(2, "0");
    const minutes = d.getMinutes().toString().padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const getStatusText = (status) => {
    if (status === "REJECT") {
      return "Đơn hàng hủy bỏ";
    } else if (status === "ACCEPT") {
      return "Đồng ý đơn hàng";
    } else if (status === "DONE") {
      return "Đơn hàng hoàn thành";
    } else if (status === "SHIPPING") {
      return "Đang giao hàng";
    } else {
      return "Chờ xác nhận";
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "createdDate", headerName: "Ngày tạo", width: 150 },
    { field: "fullName", headerName: "Tên đầy đủ", width: 150 },
    { field: "phoneNumber", headerName: "Số điện thoại", width: 150 },
    { field: "address", headerName: "Địa chỉ", width: 200 },
    { field: "status", headerName: "Trạng thái", width: 180 },
    { field: "totalCost", headerName: "Tổng chi phí", width: 150 },
    { field: "listProduct", headerName: " Đơn hàng", width: 300 },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatCurrency = (value) => {
    if (value == null) return "";
    const strValue = value.toString();
    const formattedValue = strValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return formattedValue + " VND";
  };

  const filteredOrderList = orderList?.filter(
    (order) =>
      order?.phoneNumber?.includes(searchPhoneNumber) &&
      (searchCreatedDate
        ? new Date(order.createdDate).toLocaleDateString("en-CA") ===
          new Date(searchCreatedDate).toLocaleDateString("en-CA")
        : true) &&
      order?.status?.includes(searchStatus)
  );

  const slicedOrderList = Array.isArray(filteredOrderList)
    ? filteredOrderList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : [];

  return (
    <TableContainer component={Paper}>
      <Toaster position="top-center" reverseOrder={false} />
      <div style={{ display: "flex", marginBottom: "20px" }}>
        <TextField
          label="Nhập số điện thoại"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchPhoneNumber}
          onChange={(e) => setSearchPhoneNumber(e.target.value)}
        />
        <TextField
          label="Chọn ngày"
          type="date"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchCreatedDate}
          onChange={(e) => setSearchCreatedDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControl variant="outlined" fullWidth margin="normal" size="medium">
          <InputLabel id="search-status-label">Tìm theo trạng thái</InputLabel>
          <Select
            labelId="search-status-label"
            id="search-status"
            value={searchStatus}
            onChange={(e) => setSearchStatus(e.target.value)}
            label="Tìm theo trạng thái"
          >
            <MenuItem value="">Tất cả</MenuItem>
            <MenuItem value="WAITING">Chờ xác nhận</MenuItem>
            <MenuItem value="ACCEPT">Đồng ý</MenuItem>
            <MenuItem value="REJECT">Xóa đơn</MenuItem>
            <MenuItem value="SHIPPING">Đang giao hàng</MenuItem>
            <MenuItem value="DONE">Hoàn thành</MenuItem>
          </Select>
        </FormControl>
      </div>

      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.field} align="center">
                {column.headerName}
              </TableCell>
            ))}
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0 ? slicedOrderList : filteredOrderList).map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {columns.map((column) => (
                <TableCell key={column.field} align="center">
                  {column.field === "createdDate" ? (
                    formatDateTime(row[column.field])
                  ) : column.field === "totalCost" ? (
                    formatCurrency(row?.totalCost)
                  ) : column.field === "listProduct" ? (
                    <ul
                      style={{
                        minWidth: 200,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {row.listProduct.map((product) => (
                        <p className="flex-column" key={product.productId}>
                          <div className="d-flex">
                            <span>Sản phẩm : </span>
                            <span>{product.productName}</span>
                          </div>
                          <div className="d-flex">
                            <span>Màu: </span>
                            <span>{product.colorName}</span>
                          </div>
                          <div className="d-flex">
                            <span>Số lượng: </span>
                            <span>{product.quantity}</span>
                          </div>
                          <div className="d-flex">
                            <span>Size: </span>
                            <span>{product.size}</span>
                          </div>
                        </p>
                      ))}
                    </ul>
                  ) : column.field === "status" ? (
                    getStatusText(row[column.field])
                  ) : (
                    row[column.field]
                  )}
                </TableCell>
              ))}
              <TableCell align="center">
                <Select
                  size="small"
                  style={{ minWidth: "180px" }}
                  value={row.status}
                  onChange={(event) => handleStatusChange(event, row.id)}
                >
                  <MenuItem value="WAITING">Chờ xác nhận</MenuItem>
                  <MenuItem value="ACCEPT">Đồng ý</MenuItem>
                  {getStatusText(row.status) !== "Đơn hàng hoàn thành" && (
                    <MenuItem value="REJECT">Xóa đơn</MenuItem>
                  )}
                  <MenuItem value="SHIPPING">Đang giao hàng</MenuItem>
                  <MenuItem value="DONE">Hoàn thành</MenuItem>
                </Select>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, totalItems]}
        component="div"
        count={filteredOrderList.length ? filteredOrderList.length : totalItems}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default Order;
