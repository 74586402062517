import { apiPath } from "./apiPath";
import { getRequest, postRequest, putRequest } from "./axiosApi";

export const createdOder = async (params, successCallback, errorCallback) => {
  await postRequest(
    `${apiPath.createdOder}`,
    params,
    successCallback,
    errorCallback
  );
};

export const getAllOder = async (params, successCallback, errorCallback) => {
  await getRequest(
    `${apiPath.getAllOder}`,
    params,
    successCallback,
    errorCallback
  );
};

export const updateStatusProduct = async (
  params,
  successCallback,
  errorCallback
) => {
  await putRequest(
    `${apiPath.updateOder}/?id=${params?.id}&&state=${params?.state}`,
    params,
    successCallback,
    errorCallback
  );
};

export const getAllCollections = async (
  params,
  successCallback,
  errorCallback
) => {
  await getRequest(
    `${apiPath.getAllCollection}`,
    params,
    successCallback,
    errorCallback
  );
};

export const menuCollection = async (
  params,
  successCallback,
  errorCallback
) => {
  await getRequest(
    `${apiPath.menuCollection}`,
    params,
    successCallback,
    errorCallback
  );
};

export const getOderByUserID = async (
  params,
  successCallback,
  errorCallback
) => {
  await getRequest(
    `${apiPath.getOderByUserID}?id=${params?.id}`,
    params,
    successCallback,
    errorCallback
  );
};
