import * as React from "react";
import "./base-admin.css";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ListIcon from '@mui/icons-material/List';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CollectionsIcon from "@mui/icons-material/Collections";
import { PATH } from "../../Routes/router-path";
import CategoryIcon from '@mui/icons-material/Category';
import Posts from "../Post-mangement-component/Post-mangement-component";
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const menuAdmin = [
  // {
  //   label: "Thống kê",
  //   icon: <DashboardIcon />,
  //   key: PATH.dashboard,
  // },
  {
    label: "Quản lý Bộ sưu tập",
    icon: <CollectionsIcon />,
    key: PATH.collections,
  },
  {
    label: "Quản lý Loại sản phẩm",
    icon: <FormatListBulletedIcon />,
    key: PATH.categories,
  },
  {
    label: "Quản lý sản phẩm",
    icon: <CategoryIcon />,
    key: PATH.products,
  },
  {
    label: "Quản lý blog",
    icon: <DynamicFeedIcon />,
    key: PATH.posts,
  },
  {
    label: "Quản lý Đơn hàng",
    icon: <ShoppingCartIcon />,
    key: PATH.order,
  },
  {
    label: "Quản lý Người dùng",
    icon: <ManageAccountsIcon />,
    key: PATH.userManagement,
  },
];

const BaseAdmin = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const navigate = useNavigate()

  const handleDrawer = () => {
    setOpen(!open);
  };

  return (
    <div className="container-navbar-admin">
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawer}>
            {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuAdmin.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                className={
                  window.location.pathname.match(item.key) ? "item-selected" : ""
                }
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={()=>{
                    navigate(item.key)
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box
        className="container-outlet-admin"
        component="div"
        sx={{ flexGrow: 1, p: 3 }}
      >
        <Outlet />
      </Box>
    </div>
  );
};

export default BaseAdmin;
