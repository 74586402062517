import React, { useState } from "react";
import "../CSS/SignIn.css";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer-component/Footer-component";
import { userApi } from "../api/userApi";
import toast, { Toaster } from "react-hot-toast";
import { ReactComponent as Logo } from "../constants/svg/DA-01.svg";
import { Backdrop, CircularProgress } from "@mui/material";
const SignIn = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState();
  const [email, setEmail] = useState();
  const [fullName, setFullName] = useState();
  const [password, setPassword] = useState();
  const [rePassword, setRePassword] = useState();
  const [phone, setPhone] = useState();
  const [alertMessage, setAlertMessage] = useState();
  const [alertVisible, setAlertVisible] = useState(false);
  const [isActiveVerify, setIsActiveVerify] = useState(false);
  const [otp, setOtp] = useState("");
  const [alertType, setPopupAlertType] = useState("primary");
  const history = useNavigate();
  const [validated, setValidated] = useState(false);
  const [open, setOpen] = useState(false);

  // Initialize Firebase
  const onclickhandler = async (e) => {
    e.preventDefault();
    setAlertVisible(false);
    const form = e.currentTarget;
    setValidated(true);
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity()) {
      const regUsername = /^(?=[a-zA-Z0-9._]{4,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
      const regEmail =
        /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
      const regPassword =
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;
      const regPhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/;

      if (!regUsername.test(username)) {
        setAlertMessage(
          "Vui lòng nhập đúng định dạng tên phải chứa từ 4 - 20 ký tự và không chứa kí tự đặc biệt"
        );
        setAlertVisible(true);
        setPopupAlertType("danger");
        return;
      } else if (!regEmail.test(email)) {
        setAlertMessage("Email không đúng định dạng");
        setAlertVisible(true);
        setPopupAlertType("danger");
        return;
      } else if (!regPhoneNumber.test(phone)) {
        setAlertMessage("Vui lòng nhập đúng định dạng số điện thoại");
        setAlertVisible(true);
        setPopupAlertType("danger");
        return;
      } else if (!regPassword.test(password)) {
        setAlertMessage(
          "Mật khẩu phải có ít nhất 8 ký tự trong đó có ít nhất 1 ký tự đặc biệt 1 chữ hoa 1 chữ thường và 1 số!"
        );
        setAlertVisible(true);
        setPopupAlertType("danger");
        return;
      } else if (password !== rePassword) {
        setAlertMessage("Mật khẩu nhập lại không trùng khớp");
        setAlertVisible(true);
        setPopupAlertType("danger");
        return;
      }
      setOpen(true);
      try {
        const param = {
          username: username,
          email: email,
          password: password,
          fullName: fullName,
          phone: phone,
        };
        const response = await userApi.registerAccount(param);
        toast.success(
          "Đăng ký thành công, Vui lòng kiểm tra email của bạn để nhận mã OTP",
          {
            duration: 1500,
          }
        );
        setIsActiveVerify(true);
        setOpen(false);
      } catch (responseError) {
        setAlertMessage(
          "Có lỗi gì đó đang xảy ra ! Vui lòng chờ trong giây lát"
        );
        setOpen(false);
        setAlertVisible(true);
        setPopupAlertType("danger");
      }
    }
  };
  const handleVerify = async () => {
    setOpen(true);
    try {
      const param = {
        username: username,
        otp: otp,
      };
      const response = await userApi.verifyAccount(param);
      toast.success("Tạo tài khoản thành công", {
        duration: 1500,
      });
      setIsActiveVerify(false);
      navigate("/login");
    } catch (responseError) {
      if (responseError?.data?.errorCode === 1405) {
        toast.error("OTP chưa chính xác. Vui lòng kiểm tra lại !", {
          duration: 3000,
        });
        setOpen(false);
      } else if (responseError?.data?.errorCode === 1403) {
        toast.error("Mã OTP của bạn đã hết hạn hết hạn !", {
          duration: 3000,
        });
        setOpen(false);
      } else if (responseError?.data?.errorCode === 1404) {
        toast.error("Tài Khoản này đã tồn tại trên hệ thống !", {
          duration: 3000,
        });
        setOpen(false);
      }
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="navbar_space">
        <div className="navbar-logo" >
          <Logo
            style={{ height: "140px" }}
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {isActiveVerify === false ? (
        <div className="signin_main_box">
          <h3>THÔNG TIN CHI TIẾT</h3>
          {alertVisible === true ? (
            <b style={{ color: "red" }}>{alertMessage}*</b>
          ) : (
            ""
          )}

          <div className="signin_second_box">
            <div>
              <form action="">
                <label htmlFor="">TÊN TÀI KHOẢN</label>
                <br />
                {/* <input type="password" placeholder='Enter Password' name="password" onChange={(e)=>setData({...data,name:e.target.value})} /><br /><br /> */}
                <input
                  type="text"
                  placeholder="Nhập tài khoản"
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
                <br />
                <br />
                <hr />
                <label htmlFor="">E-MAIL</label>
                <br />
                {/* <input type="email" placeholder='Enter Email' name="email" onChange={onchnageHandler}/><br /><br /> */}
                <input
                  type="email"
                  placeholder="Nhập Email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <br />
                <br />
                <hr />
                <label htmlFor="">HỌ VÀ TÊN</label>
                <br />
                <input
                  type="text"
                  placeholder="Nhập Họ và Tên"
                  onChange={(e) => setFullName(e.target.value)}
                />
                <br />
                <br />
                <hr />
              </form>
            </div>
            <div>
              <label htmlFor=""></label>
              <br />
              {/* <input type="email" placeholder=''/><br /><br /> */}
              <label htmlFor="">MẬT KHẨU</label>
              <br />
              {/* <input type="password" placeholder='Enter Password' name="password" onChange={(e)=>setData({...data,name:e.target.value})} /><br /><br /> */}
              <input
                type="password"
                placeholder="Nhập mật khẩu"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <br />
              <br />
              <hr />
              <label htmlFor="">NHẬP LẠI MẬT KHẨU</label>
              <br />
              <input
                type="password"
                placeholder="Nhập lại mật khẩu"
                onChange={(e) => setRePassword(e.target.value)}
              />
              <br />
              <br />
              <hr />
              <label htmlFor="">ĐỊA CHỈ</label>
              <br />
              <input type="text" placeholder="Nhập địa chỉ" />
              <br />
              <br />
              <hr />
            </div>
            <div></div>
          </div>
          <div className="submitSection">
            <div className="prefix_telephone">
              <div>
                PREFIX <br />
                +84
              </div>
              <div>
                <label htmlFor="">SỐ ĐIỆN THOẠI</label>
                <br />
                <input
                  type="text"
                  placeholder="Nhập số điện thoại"
                  onChange={(e) => setPhone(e.target.value)}
                />
                <hr />
              </div>
            </div>
            <div className="checkbox_input">
              <input type="checkbox" />
              <label htmlFor="">
                TÔI MUỐN NHẬN TIN TỨC{" "}
                <span>
                  <b>Vải Diệp anh</b>
                </span>{" "}
                TRÊN E-MAIL CỦA TÔI
              </label>
              <br />
              <input type="checkbox" />
              <label htmlFor="">TÔI CHẤP NHẬN TUYÊN BỐ QUYỀN RIÊNG TƯ</label>
            </div>
            <button type="submit" onClick={onclickhandler}>
              TẠO TÀI KHOẢN
            </button>
            <br />
            <b
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => {
                navigate("/login");
              }}
            >
              Bạn đã có tài khoản ?
            </b>
          </div>
        </div>
      ) : (
        <div className="signin_main_box">
          <div className="submitSection">
            <div className="prefix_telephone">
              <div>
                <label htmlFor="">OTP</label>
                <br />
                <input
                  type="text"
                  placeholder="Nhập OTP"
                  onChange={(e) => setOtp(e.target.value)}
                />
                <hr />
              </div>
            </div>
            <button type="submit" onClick={handleVerify}>
              XÁC NHẬN OTP
            </button>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default SignIn;
