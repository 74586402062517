import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TablePagination from "@mui/material/TablePagination";
import { useEffect, useState } from "react";
import { getListUser, updateUser } from "../../api/userSevice";
import { TableHead } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";

const UserManager = () => {
  const [listUser, setListUser] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleUpdateUser = (user) => {
    updateUser(
      { username: user.username, status: user.active },
      (res) => {
        setRefresh(!refresh)
        toast.success("Đổi trạng thái thành công ", {
          duration: 3000,
        });
      },
      (err) => {
        toast.error("Đổi trạng thái thất bại!", {
          duration: 1500,
        });
      }
    );
  };

  useEffect(() => {
    getListUser(
      { role: 2, page: page, size: rowsPerPage },
      (res) => {
        setListUser(res.data);
        setTotalItems(res.totalItems)
      },
      (err) => {
        console.error(err);
      }
    );
  }, [page, rowsPerPage,refresh]);

  return (
    <Paper>
      <Toaster position="top-center" reverseOrder={false} />

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Tên tài khoản</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="center">Trạng thái</TableCell>
              <TableCell align="left">Số điện thoại</TableCell>
              <TableCell align="left">Ngày tạo</TableCell>
              <TableCell align="center">Vai trò</TableCell>
              <TableCell align="center">Hành động</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listUser?.map((user) => (
              <TableRow
                key={user.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="center">
                  {user.username}
                </TableCell>
                <TableCell align="left">{user.email}</TableCell>
                <TableCell align="center">
                  {user.active === true ? "Đang Kích hoạt" : "Chưa kích hoạt  "}
                </TableCell>
                <TableCell align="left">{user.phoneNumber || "N/A"}</TableCell>
                <TableCell align="left">{user.createdAt || "N/A"}</TableCell>
                <TableCell align="center">
                  {user.role === "ROLE_GUEST" ? "Guest" : user.role}
                </TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleUpdateUser(user)}
                  >
                    Đổi trạng thái
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalItems}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default UserManager;
