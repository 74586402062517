import * as React from "react";
import Logo from "../../constants/svg/abc.jpg";
import "./Footer-component.css";
import "../../CSS/style.css";

const Footer = () => {
  return (
    <div className="footer">
      <section className="container-footer">
        <div className="footer_detail">
          <div className="footer_detail_detail">
            <span className="text-bold text-13">Thời Trang Diệp Anh </span>
            <a
              className="text-13"
              href="https://www.facebook.com/vaidepdiepanh"
              style={{ color: "white" }}
            >
              FACEBOOK
            </a>
            <a
              className="text-13"
              href="https://zalo.me/0976999378"
              style={{ color: "white" }}
            >
              Zalo
            </a>
          </div>
          <div className="footer_detail_detail">
            <span className="text-bold text-13">Chính sách</span>
            <a
              className="text-13"
              style={{ color: "white" }}
              href="/purchase-policy"
            >
              Chính sách mua hàng
            </a>
            <a
              className="text-13"
              style={{ color: "white" }}
              href="/service-terms"
            >
              Điều khoản dịch vụ
            </a>
          </div>
          <div className="footer_detail_detail">
            <span className="text-13 text-bold">THÔNG TIN LIÊN HỆ</span>
            <p className="text-13">DỆT MAY DIỆP ANH</p>
            <p className="text-13">
              <span className="text-13">Địa Chỉ:</span> KCN NINH HIỆP , GIA LÂM
              , HÀ NỘI
            </p>
            <p className="text-13">
              <span className="text-13">Điện thoại:</span> 0976 999 378
            </p>
            <p className="text-13">
              <span className="text-13">Email:</span> detmaydiepanh@gmail.com
            </p>
          </div>
        </div>
        <div className="footer-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3723.0051381089756!2d105.951333!3d21.072457!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135a8141a0c3af7%3A0xf93954a3fb801a86!2zS2h1IGPDtG5nIG5naGnhu4dwIE5pbmggSGnhu4dwLCBOaW5oIEhp4buHcCwgR2lhIEzDom0sIEjDoCBO4buZaSwgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2sus!4v1718903403911!5m2!1svi!2sus"
            width="100%"
            height="100%"
            className="map"
            loading="lazy"
          ></iframe>
        </div>
      </section>

      <div className="footer_bottom">
        <img src={Logo} alt="" />
      </div>
    </div>
  );
};

export default Footer;
