import {
  Backdrop,
  Box,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from "react";
import { getOderByUserID } from "../api/cartService";
import "../CSS/OrderHistory.css"; // Thêm file CSS để tùy chỉnh giao diện

const OrderHistory = () => {
  const [cartData, setCartData] = useState([]);
  const [open, setOpen] = useState(false);

  function formatCurrency(value) {
    const strValue = value.toString();
    const formattedValue = strValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return formattedValue + " VND";
  }

  const getStatusText = (status) => {
    switch (status) {
      case "REJECT":
        return (
          <Typography variant="body2" color="error">
            Đơn hàng hủy bỏ
          </Typography>
        );
      case "ACCEPT":
      case "DONE":
        return (
          <Typography variant="body2" color="primary">
            {status === "ACCEPT" ? "Đồng ý đơn hàng" : "Đơn hàng hoàn thành"}
          </Typography>
        );
      case "SHIPPING":
        return (
          <Typography variant="body2" style={{ color: "rgb(246, 136, 0)" }}>
            Đang giao hàng
          </Typography>
        );
      default:
        return (
          <Typography variant="body2" style={{ color: "rgb(246, 136, 0)" }}>
            Chờ xác nhận
          </Typography>
        );
    }
  };

  const formatDateTime = (dateTimeString) => {
    const d = new Date(dateTimeString);
    d.setHours(d.getHours() + 7);

    const day = d.getDate().toString().padStart(2, "0");
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const year = d.getFullYear();
    const hours = d.getHours().toString().padStart(2, "0");
    const minutes = d.getMinutes().toString().padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  useEffect(() => {
    const idUser = localStorage.getItem("id");
    setOpen(true);
    getOderByUserID(
      { id: idUser },
      (res) => {
        setCartData(res);
        setOpen(false);
      },
      (err) => {
        setOpen(false);
      }
    );
  }, []);

  return (
    <Box sx={{ padding: 2 }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography variant="h5" gutterBottom>
        Lịch sử đơn hàng
      </Typography>
      {cartData.length === 0 ? (
        <Typography variant="body2">Không có đơn hàng nào.</Typography>
      ) : (
        cartData.map((order) => (
          <Card key={order.id} sx={{ marginBottom: 2 }}>
            <CardContent sx={{ padding: 2 }}>
              <div className="contentCart">
                <div className="cart">
                  <div style={{ width: "50%" }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Mã đơn hàng: {order.id}
                    </Typography>
                    <Typography variant="body2">
                      <b>Ngày tạo:</b> {formatDateTime(order.createdDate)}
                    </Typography>
                    <Typography variant="body2">
                      <b>Họ tên:</b> {order.fullName}
                    </Typography>
                  </div>
                  <div style={{ width: "100%" }}>
                    <Typography variant="body2">
                      <b>Địa chỉ:</b> {order.address}
                    </Typography>
                    <Typography variant="body2">
                      <b>Số điện thoại:</b> {order.phoneNumber}
                    </Typography>
                    <Typography variant="body2">
                      <b>Tổng chi phí:</b> {formatCurrency(order.totalCost)}
                    </Typography>
                    <Typography variant="body2">
                      <b>Trạng thái:</b> {getStatusText(order.status)}
                    </Typography>
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Sản phẩm
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Tên sản phẩm</TableCell>
                          <TableCell align="center">Màu sắc</TableCell>
                          <TableCell align="center">Kích cỡ</TableCell>
                          <TableCell align="center">Số lượng</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {order.listProduct.map((product) => (
                          <TableRow key={product.id}>
                            <TableCell component="th" scope="row">
                              {product.productName}
                            </TableCell>
                            <TableCell align="center">
                              {product.colorName}
                            </TableCell>
                            <TableCell align="center">{product.size}</TableCell>
                            <TableCell align="center">
                              {product.quantity}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </CardContent>
          </Card>
        ))
      )}
    </Box>
  );
};

export default OrderHistory;
