import { PATH } from "./router-path";

import LogIn from "../Routes/LogIn";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Help from "../Components/Help";
import SignIn from "../Routes/SignIn";
import SinglePage from "../Components/SinglePage";
import ProductPage from "../Routes/ProductPage";
import Cart from "../Components/Cart";
import Checkout from "../Components/Checkout";
import Companylogin from "./Company";
import PaymentMethod from "../Components/PaymentMethod";
import Search from "../Components/Search";
import CardDetail from "../Routes/CardDetail";
import OTP from "./OTP";
import ProtectedRoute from "../Components/ProtectedRoute";
import HomePage from "../Components/Home-page-component/Home-page-component";
import ProductCartTest from "../Components/Product-Page-Component/ProductCartTest";
import Blog from "../Components/Blog-component/Blog-component";
import BaseAdmin from "../Components/base-admin/base-admin";
import Dashboard from "../Components/Dashboard-component/Dashboard-component";
import Collections from "../Components/Collections-component/Collections-component";
import EditCollection from "../Components/Collections-component/Edit-collection-component/Edit-collection-component";
import Order from "../Components/Order-component/Order-component";
import UserManager from "../Components/UserManager-component/UserManager-component";
import ProductManagement from "../Components/Products-management-component/Products-management-component";
import EditProduct from "../Components/Products-management-component/Edit-product-component/Edit-product-component";
import PurchasePolicy from "../Components/PurchasePolicy";
import Posts from "../Components/Post-mangement-component/Post-mangement-component";
import EditPost from "../Components/Post-mangement-component/Edit-post-component/Edit-post-component";
import BlogDetail from "../Components/blog-detail-component/blog-detail-component";
import Category from "../Components/Category-component/Category-component";
import ServiceTerms from "../Components/ServiceTerms.jsx";
import PrivateRoute from "./privateRouter.jsx";
import OderHistory from "../Components/OderHistory.jsx";
import PageNotFound from "../Components/PageNotFound.jsx";
import UserInfo from "../Components/UserInfo/UserInfo.jsx";

const AllRoutes = () => {
  return (
    <Routes>
      <Route path={"*"} element={<PageNotFound />} />
      <Route exact path="/" element={<HomePage />}></Route>
      <Route path="/product/:id" element={<SinglePage />}></Route>
      {/* <Route path="/help" element={<Help />}></Route> */}
      {/* <Route path="/company" element={<Companylogin />}></Route> */}
      <Route path="/login" element={<LogIn />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/cart" element={<Cart />}></Route>
      <Route path={PATH.blogs} element={<Blog />}></Route>
      <Route path={`${PATH.blogs_detail}`} element={<BlogDetail />}></Route>
      <Route
        path="/cart"
        element={
          <ProtectedRoute>
            <Cart />
          </ProtectedRoute>
        }
      ></Route>
      <Route path="/checkout" element={<Checkout />}></Route>
      <Route
        path="/paymentMethod"
        element={
          <ProtectedRoute>
            <PaymentMethod />
          </ProtectedRoute>
        }
      ></Route>
      <Route path="/products" element={<ProductPage />}></Route>
      <Route path="/search" element={<Search />}></Route>
      <Route path="/fillcarddetail" element={<CardDetail />}></Route>
      <Route path="/otp" element={<OTP />}></Route>
      <Route path="/purchase-policy" element={<PurchasePolicy />}></Route>
      <Route path="/service-terms" element={<ServiceTerms />}></Route>
      <Route path="/order-history" element={<OderHistory />}></Route>
      <Route path="/user-info" element={<UserInfo />}></Route>

      <Route
        path={PATH.admin}
        element={
          <PrivateRoute>
            <BaseAdmin />
          </PrivateRoute>
        }
      >
        <Route
          path={PATH.admin}
          element={<Navigate to={PATH.collections} />}
        ></Route>

        <Route
          path={PATH.dashboard}
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path={PATH.collections}
          element={
            <PrivateRoute>
              <Collections />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path={`${PATH.edit_collection}/:idCollection`}
          element={
            <PrivateRoute>
              <EditCollection />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path={PATH.create_collection}
          element={
            <PrivateRoute>
              <EditCollection />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path={PATH.order}
          element={
            <PrivateRoute>
              <Order />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path={PATH.userManagement}
          element={
            <PrivateRoute>
              <UserManager />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path={PATH.products}
          element={
            <PrivateRoute>
              <ProductManagement />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path={PATH.create_product}
          element={
            <PrivateRoute>
              <EditProduct />{" "}
            </PrivateRoute>
          }
        ></Route>

        <Route
          path={`${PATH.edit_product}/:idProduct`}
          element={
            <PrivateRoute>
              <EditProduct />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path={PATH.posts}
          element={
            <PrivateRoute>
              <Posts />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path={PATH.create_post}
          element={
            <PrivateRoute>
              <EditPost />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path={`${PATH.edit_post}/:idPost`}
          element={
            <PrivateRoute>
              <EditPost />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path={PATH.categories}
          element={
            <PrivateRoute>
              <Category />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path={`${PATH.edit_product}/:id`}
          element={
            <PrivateRoute>
              <EditProduct />
            </PrivateRoute>
          }
        ></Route>
      </Route>

      {/* <Route path='/fillcarddetail' element={<ProtectedRoute><CardDetail /></ProtectedRoute>}></Route> */}
      {/* <Route path='/otp' element={<ProtectedRoute><OTP /></ProtectedRoute>}></Route> */}
    </Routes>
  );
};

export default AllRoutes;
