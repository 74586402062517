import React, { useEffect, useLayoutEffect, useState } from "react";
import "./Home-page-component.css";
import Footer from "../Footer-component/Footer-component";
import { useDispatch, useSelector } from "react-redux";
import { getAllCollections } from "../../Redux/App/action";
import { useNavigate } from "react-router-dom";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}
const HomePage = () => {
  const dispatch = useDispatch();
  const collections = useSelector((store) => store?.AppReducer?.collections);
  const navigate = useNavigate();
  const [width, height] = useWindowSize();
  const handleItemClick = (settingId) => {
    localStorage.setItem("settingId", settingId);
    navigate("/products");
  };
  const handleIntersection = (idEl) => {
    var options = {
      root: window.document.querySelector(".App"), //The element that is used as the viewport for checking visiblity of the target
      rootMargin: "0px", //This value serve to grow or shrink the root element's bounding box before intersection computing
      threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0], //Indicate at what percentage of the target's visibility the observer's callback should be executed
    };
    var callback = function (entries, observer) {
      entries.forEach((entry) => {
        let boxImage = entry.target.querySelector(".display-image");
        let boxTitle = entry.target.querySelector(".title-collection");
        let boxAction = entry.target.querySelector(
          ".link-prod-from-collection"
        );
        let visiblePct = Math.floor(entry.intersectionRatio * 100);
        if (visiblePct > 50) {
          if (boxImage.dataset.visible === "true") {
            boxImage.dataset.visible = "false";
          }
          if (boxTitle.dataset.visible === "true") {
            boxTitle.dataset.visible = "false";
          }
          if (boxAction.dataset.visible === "true") {
            boxAction.dataset.visible = "false";
          }
        }
      });
    };

    var observer = new IntersectionObserver(callback, options);

    const allItem = document.querySelectorAll(`#${idEl}`);
    if (allItem?.length) {
      allItem.forEach((item) => {
        observer.observe(item);
      });
    }
  };

  async function handleGetDataCollections() {
    await dispatch(getAllCollections());
    handleIntersection("container-div-img");
  }

  useEffect(() => {
    handleGetDataCollections();
    return () => {
      handleIntersection("container-div-img");
    };
  }, []);

  return (
    <div id="root-collection">
      <section className="container-section-img">
        <figure
          tabIndex="-1"
          id="figure-collection"
          className="figure-collection"
          onClick={() => handleItemClick(collections[0]?.setting_id)}
        >
          <div id="container-div-img" className="container-div-img">
            <a tabIndex="-1" className="link-img" href=""></a>
            <div className="container-img-display">
              <img
                data-visible="true"
                className="display-image"
                src={collections[0]?.images[0]?.linkImg}
                alt=""
              />
            </div>
            <div
              id="container-action-collection"
              className="container-action-collection"
              tabIndex="-10"
            >
              <div
                data-visible="true"
                className="title-collection"
                onClick={() => handleItemClick(collections[0]?.setting_id)}
              >
                {collections[0]?.setting_title}
              </div>
              <a
                data-visible="true"
                className="link-prod-from-collection"
                href=""
                onClick={() => handleItemClick(collections[0]?.setting_id)}
              >
                {collections[0]?.desciption}
              </a>
            </div>
          </div>
        </figure>
      </section>
      <section className="container-section-collection">
        {collections.map((data, key) => {
          if (key && data?.status) {
            return (
              <figure
                key={key}
                tabIndex="-1"
                id="figure-collection"
                className="figure-collection"
                onClick={() => handleItemClick(data?.setting_id)}
              >
                <div id="container-div-img" className="container-div-img">
                  <a tabIndex="-1" className="link-img" href=""></a>
                  <div
                    className="container-img-display-collection"
                  >
                    <img
                      data-visible="true"
                      className="display-image"
                      src={data?.images[0]?.linkImg}
                      alt=""
                    />
                  </div>
                  <div
                    id="container-action-collection"
                    className="container-action-collection"
                    tabIndex="-10"
                    onClick={() => handleItemClick(data?.setting_id)}
                  >
                    <div
                      data-visible="true"
                      className="title-collection"
                      onClick={() => handleItemClick(data?.setting_id)}
                    >
                      {data?.setting_title}
                    </div>
                    <a
                      data-visible="true"
                      className="link-prod-from-collection"
                      href=""
                      onClick={() => handleItemClick(data?.setting_id)}
                    >
                      {data?.desciption}
                    </a>
                  </div>
                </div>
              </figure>
            );
          }
        })}
      </section>

      <Footer />
    </div>
  );
};

export default HomePage;
