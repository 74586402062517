import React from "react";
import Img404 from "../constants/mock-images/404.jpg";

const PageNotFound = () => {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <img src={Img404} alt="" style={{ height: "100%", width: "100%" ,objectFit:"cover"}} />
    </div>
  );
};

export default PageNotFound;
