import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { createdOder } from "../api/cartService";
import toast, { Toaster } from "react-hot-toast";
import { Formik, Form, Field, ErrorMessage } from "formik";
import CheckoutCSS from "../CSS/Checkout.css"; // Import CSS file

import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { setRefreshStage } from "../Redux/App/action";
import { useNavigate } from "react-router-dom";

const CheckoutSchema = Yup.object().shape({
  fullName: Yup.string()
    .max(200, "Họ và Tên không được vượt quá 200 ký tự")
    .required("Vui lòng nhập Họ và Tên"),
  shippingAddress: Yup.string().required("Vui lòng nhập Địa chỉ nhận hàng"),
  phoneNumber: Yup.string().required("Vui lòng nhập Số điện thoại"),
});

function formatCurrency(value) {
  // Convert the number to a string
  const strValue = value.toString();

  // Use a regular expression to format the string with dots
  const formattedValue = strValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  return formattedValue + " VND";
}

const Checkout = () => {
  const [cartData, setCartData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refreshStage = useSelector((state) => state.AppReducer.refreshStage);
  const idUser = localStorage.getItem("id");

  let sum = 0;
  cartData &&
    cartData.forEach((element) => {
      sum += element.price * element.quantity;
    });

  useEffect(() => {
    const storedCartData = localStorage.getItem("cart");
    if (storedCartData) {
      setCartData(JSON.parse(storedCartData));
    } else {
      setCartData([]);
    }
  }, [refreshStage]);

  const handleCreatedOrder = (values, resetForm) => {
    const dataProduct = cartData.map((item) => ({
      productId: item.id,
      productName: item.name,
      colorName: item.property.colorName,
      color: item.property.color,
      size: item.property.size,
      quantity: item.quantity,
    }));

    createdOder(
      {
        userId: idUser ? idUser : "",
        phoneNumber: values.phoneNumber,
        fullName: values.fullName,
        address: values.shippingAddress,
        listProduct: dataProduct,
        totalCost: sum,
      },
      (res) => {
        toast.success("Đặt hàng thành công!", {
          duration: 3000,
        });
        localStorage.setItem("cart", "");
        dispatch(setRefreshStage(refreshStage));
        resetForm();
        setTimeout(() => {
          navigate("/"); // Navigate to home after 5 seconds
        }, 5000);
      },
      (err) => {
        toast.error("Đặt hàng thất bại!", {
          duration: 1500,
        });
      }
    );
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <Container>
        <div className="layout-content">
          <div className="main-content">
            <Formik
              initialValues={{
                fullName: "",
                shippingAddress: "",
                phoneNumber: "",
              }}
              validationSchema={CheckoutSchema}
              onSubmit={(values, { resetForm }) => {
                handleCreatedOrder(values, resetForm);
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className="justify-center">
                    <div
                      className="delivery-address "
                      style={{ maxWidth: 500 }}
                    >
                      <span>Họ tên</span>
                      <Field
                        type="text"
                        name="fullName"
                        placeholder="Họ và Tên"
                      />
                      <ErrorMessage
                        name="fullName"
                        component="div"
                        className="error-message"
                      />
                      <span>Địa chỉ</span>
                      <Field
                        type="text"
                        name="shippingAddress"
                        placeholder="Địa chỉ nhận hàng"
                      />
                      <ErrorMessage
                        name="shippingAddress"
                        component="div"
                        className="error-message"
                      />
                      <span>Số điện thoại</span>
                      <Field
                        type="tel"
                        name="phoneNumber"
                        placeholder="Số điện thoại"
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>

                  <div className="delivery-group">
                    <h2>Sản Phẩm</h2>
                    <div className="checkoutDeliverypro">
                      {cartData.map((item) => (
                        <img
                          src={item.property.image}
                          alt={item.producttitle}
                          key={item.id}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="bottom-btn">
                    <button type="submit" className="bottom-btn-link">
                      Hoàn tất thanh toán
                    </button>
                    <div>
                      <div>
                        <b>Thành Tiền {formatCurrency(sum)} </b>
                      </div>
                      <div>Chưa bao gồm thuế</div>
                      <div>* Và giá vận chuyển</div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  font-size: smaller;
  position: relative;
  cursor: pointer;

  /* Import styles from Checkout.css */
  ${CheckoutCSS}
`;

export default Checkout;
