export const htmlData = `<div style="display: flex; flex-direction: column;">
    <span style="padding: 8px 8px 0 8px; font-size: 11px;">CHẤT LIỆU</span>
    <span style="text-align: justify; padding: 8px; font-size: 11px;">
        Chúng tôi đang triển khai các chương trình giám sát nhằm đảm bảo sự tuân thủ các tiêu chuẩn của chúng tôi về xã
        hội, môi trường, cũng như về độ an toàn và tính lành mạnh của các sản phẩm. Nhằm đánh giá sự tuân thủ các tiêu
        chuẩn này, chúng tôi đã phát triển một chương trình kiểm toán và các kế hoạch cải thiện liên tục.
    </span>
    <span style="padding: 8px 8px 0 8px; font-size: 11px;">LỚP NGOÀI
    </span>
    <span style="text-align: justify; padding: 8px; font-size: 11px;">
        74% vải pôliexte
        <br>
        19% vải thun vitcô
        <br>
        7% elastane
    </span>
    <span style="padding: 8px 8px 0 8px; font-size: 11px;">LỚP LÓT
    </span>
    <span style="text-align: justify; padding: 8px; font-size: 11px;">
        100% vải pôliexte
    </span>
    <span style="padding: 8px 8px 0 8px; font-size: 11px;">CHĂM SÓC
    </span>
    <span style="text-align: justify; padding: 8px; font-size: 11px;">
        Chăm sóc đúng cách quần áo của mình tức là bạn đang bảo vệ môi trường
        <br>
        Để giữ cho áo khoác của bạn sạch sẽ, bạn chỉ cần phơi chúng ở nơi thông thoáng và lau sạch bằng khăn vải hoặc
        bàn chải chuyên dụng. Quá trình này có lợi hơn cho vải và cũng tránh việc tiêu thụ nước và năng lượng trong quá
        trình giặt.
    </span>
    <span style="padding: 8px 8px 0 8px; font-size: 11px;">Hướng dẫn bảo quản quần áo</span>
    <span style="text-align: justify; padding: 8px; font-size: 11px;">
        Giặt máy ở nhiệt độ tối đa 30ºC, vắt ở tốc độ thấp
        <br> Không sử dụng nước tẩy / thuốc tẩy
        <br>
        Giặt ở nhiệt độ tối đa 110ºC
        <br>
        Giặt khô với tetrachloroethylene
        <br>
        Không sử dụng máy sấy
        <br>
        Giặt riêng </span>

    <span style="padding: 8px 8px 0 8px; font-size: 11px;">NGUỒN GỐC</span>
    <span style="text-align: justify; padding: 8px; font-size: 11px;">
        Chúng tôi phối hợp với các nhà cung cấp, người lao động, công đoàn và các tổ chức quốc tế nhằm phát triển một
        chuỗi cung ứng mà trong đó quyền con người được tôn trọng và thúc đẩy, góp phần thực hiện các Mục tiêu Phát
        triển Bền vững của Liên Hợp Quốc.
        <br>
        Bằng việc hợp tác với các nhà cung cấp, chúng tôi nỗ lực tìm hiểu các cơ sở và quy trình mà họ sử dụng để sản
        xuất các sản phẩm may mặc của chúng tôi với mục đích truy xuất nguồn gốc của các sản phẩm. </span>
    <span style="padding: 8px 8px 0 8px; font-size: 11px;">Sản phẩm này được sản xuất với các nguồn gốc như sau:

    </span>
    <span style="text-align: justify; padding: 8px; font-size: 11px;">
        Sản xuất tại Maroc
        <br>
        Sản xuất tại Việt Nam
        <br>
        Sản xuất tại Tây Ban Nha
    </span>
</div>`