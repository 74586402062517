import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Avatar,
  Stack,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import { updatePassword } from "../../Redux/App/action";
import { UPDATE_PASSWORD_FAILURE, UPDATE_PASSWORD_SUCCESS } from "../../Redux/App/actionTypes";

const UserInfo = () => {
  const auth = useSelector((store) => store.AuthReducer);
  const user = useSelector((store) => store.AppReducer);
  const dispatch = useDispatch();
const regPassword =
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;
  // Define validation schema using Yup
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required("Vui lòng nhập mật khẩu hiện tại.")
      .matches(regPassword, "Mật khẩu phải có ít nhất 8 ký tự trong đó có ít nhất 1 ký tự đặc biệt 1 chữ hoa 1 chữ thường và 1 số!"),
    newPassword: Yup.string()
      .required("Vui lòng nhập mật khẩu mới.")
      .matches(regPassword, "Mật khẩu phải có ít nhất 8 ký tự trong đó có ít nhất 1 ký tự đặc biệt 1 chữ hoa 1 chữ thường và 1 số!"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Mật khẩu xác nhận không khớp.")
      .required("Vui lòng xác nhận mật khẩu mới."),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const param = {
      oldPassword: values.currentPassword,
      password: values.newPassword,
    };
    dispatch(updatePassword( user?.userInfo?.id, param)).then((res) => {
      if (res.type === UPDATE_PASSWORD_SUCCESS) {
        toast.success("Đổi mật khẩu thành công", {
          duration: 3000,
        });
        resetForm();
      } else if (res.type === UPDATE_PASSWORD_FAILURE) {
        toast.error("Sai mật khẩu, vui lòng kiểm tra lại mật khẩu của bạn !", {
          duration: 3000,
        });
        return;
      }
    });
  };

  // Function to generate string avatar based on username
  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: "#f6b000",
      },
      children: `${name ? name.charAt(0).toUpperCase() : ""}`,
    };
  };

  // Handle click event for avatar
  const handleClick = () => {
    // Handle click event if needed
    console.log("Avatar clicked");
  };

  return (
    <><Toaster position="top-center" reverseOrder={false} />
    <Container maxWidth="sm">
      <Typography variant="h6" gutterBottom>
        Thông tin người dùng
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2}>
            <Avatar
              {...stringAvatar(
                auth?.userInfo?.username || user?.userInfo?.username
              )}
              onClick={handleClick}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            Tên đầy đủ: {user.userInfo.fullname}
          </Typography>
          <Typography variant="subtitle1">
            Email: {user.userInfo.email}
          </Typography>
          <Typography variant="subtitle1">
            Username: {user.userInfo.username}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Formik
            initialValues={{
              currentPassword: "",
              newPassword: "",
              confirmPassword: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      type="password"
                      name="currentPassword"
                      as={TextField}
                      label="Mật khẩu hiện tại"
                      fullWidth
                      error={errors.currentPassword && touched.currentPassword}
                      helperText={touched.currentPassword && errors.currentPassword}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type="password"
                      name="newPassword"
                      as={TextField}
                      label="Mật khẩu mới"
                      fullWidth
                      error={errors.newPassword && touched.newPassword}
                      helperText={touched.newPassword && errors.newPassword}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type="password"
                      name="confirmPassword"
                      as={TextField}
                      label="Xác nhận mật khẩu mới"
                      fullWidth
                      error={errors.confirmPassword && touched.confirmPassword}
                      helperText={touched.confirmPassword && errors.confirmPassword}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Đổi mật khẩu
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Container>
    </>
  );
};

export default UserInfo;
