import axiosApi from "../../api/axiosApi";
import { userApi } from "../../api/userApi";
import * as types from "./actionTypes";
const getProduct =
  (payload = "products", limit = "") =>
  (dispatch) => {
    dispatch({ type: types.GET_PRODUCTS_REQUEST });
    return axiosApi
      .get(`https://zara-mock-server.herokuapp.com/${payload}?_limit=${limit}`)
      .then((r) => {
        return dispatch({ type: types.GET_PRODUCTS_SUCCESS, payload: r.data });
      })
      .catch((e) => {
        dispatch({ type: types.GET_PRODUCTS_FAILURE });
      });
  };

const getSingleProduct = (payload) => (dispatch) => {
  dispatch({ type: types.GET_SINGLE_REQUEST });
  return axiosApi
    .get(`https://zara-mock-server.herokuapp.com/products/${payload}`)
    .then((r) => {
      return dispatch({ type: types.GET_SINGLE_SUCCESS, payload: r.data });
    })
    .catch((e) => {
      dispatch({ type: types.GET_SINGLE_FAILURE });
    });
};

const postCart = (payload) => (dispatch) => {
  dispatch({ type: types.POST_CART_REQUEST });
  return axiosApi
    .post("https://zara-mock-server.herokuapp.com/cart", payload)
    .then((r) => {
      return dispatch({ type: types.POST_CART_SUCCESS });
    })
    .catch((e) => {
      dispatch({ type: types.POST_CART_FAILURE });
    });
};

const getCart = (payload) => (dispatch) => {
  dispatch({ type: types.GET_CART_REQUEST });
  return axiosApi
    .get("https://zara-mock-server.herokuapp.com/cart")
    .then((r) => {
      return dispatch({ type: types.GET_CART_SUCCESS, payload: r.data });
    })
    .catch((e) => {
      dispatch({ type: types.GET_CART_FAILURE });
    });
};

const deleteCart = (id) => (dispatch) => {
  dispatch({ type: types.DELETE_CART_REQUEST });

  return axiosApi
    .delete(`https://zara-mock-server.herokuapp.com/cart/${id}`)
    .then((r) => {
      return dispatch({ type: types.DELETE_CART_SUCCESS });
    })
    .catch((e) => {
      dispatch({ type: types.DELETE_CART_FAILURE });
    });
};
const patchcart =
  ({ qnty, id }) =>
  (dispatch) => {
    dispatch({ type: types.PATCH_CART_REQUEST });

    return axiosApi
      .patch(`https://zara-mock-server.herokuapp.com/cart/${id}`, {
        quantity: qnty,
      })
      .then((r) => {
        return dispatch({ type: types.PATCH_CART_SUCCESS });
      })
      .catch((e) => {
        dispatch({ type: types.PATCH_CART_FAILURE });
      });
  };

const getDetailUser = () => (dispatch) => {
  dispatch({ type: types.GET_DETAIL_USER_REQUEST });
  return userApi
    .getUserDetail()
    .then((res) => {
      return dispatch({ type: types.GET_DETAIL_USER_SUCCESS, payload: res });
    })
    .catch(() => {
      return dispatch({ type: types.GET_DETAIL_USER_FAILURE });
    });
};

const getAllCollections = (name) => (dispatch) => {
  dispatch({ type: types.GET_ALL_COLLECTIONS_REQUEST });
  return userApi
    .getAllcollections(name ? name : undefined)
    .then((res) => {
      return dispatch({
        type: types.GET_ALL_COLLECTIONS_SUCCESS,
        payload: res,
      });
    })
    .catch(() => {
      return dispatch({ type: types.GET_ALL_COLLECTIONS_FAILURE });
    });
};

const createCollection = (payload) => (dispatch) => {
  dispatch({ type: types.POST_COLLECTION_REQUEST });
  return userApi
    .createCollection(payload)
    .then((res) => {
      return dispatch({ type: types.POST_COLLECTION_SUCCESS, payload: res });
    })
    .catch(() => {
      return dispatch({ type: types.POST_COLLECTION_FAILURE });
    });
};

const deleteCollections = (listId) => (dispatch) => {
  dispatch({ type: types.DELETE_COLLECTION_REQUEST });
  return userApi
    .deleteCollections(listId)
    .then((res) => {
      return dispatch({ type: types.DELETE_COLLECTION_SUCCESS, payload: res });
    })
    .catch((e) => {
      return dispatch({ type: types.DELETE_COLLECTION_FAILURE, payload: e });
    });
};

const getDetailCollection = (id) => (dispatch) => {
  dispatch({ type: types.GET_DETAIL_COLLECTION_REQUEST });
  return userApi
    .getDetailCollection(id)
    .then((res) => {
      return dispatch({
        type: types.GET_DETAIL_COLLECTION_SUCCESS,
        payload: res,
      });
    })
    .catch(() => {
      return dispatch({ type: types.GET_DETAIL_COLLECTION_FAILURE });
    });
};

const updateDetailCollection = (payload) => (dispatch) => {
  dispatch({ type: types.PUT_DETAIL_COLLECTION_REQUEST });
  return userApi
    .updateDetailCollection(payload)
    .then((res) => {
      return dispatch({
        type: types.PUT_DETAIL_COLLECTION_SUCCESS,
        payload: res,
      });
    })
    .catch(() => {
      return dispatch({ type: types.PUT_DETAIL_COLLECTION_FAILURE });
    });
};

const getAllCategory = () => (dispatch) => {
  dispatch({ type: types.GET_ALL_CATEGORY_REQUEST });
  return userApi
    .getAllCategory()
    .then((res) => {
      return dispatch({ type: types.GET_ALL_CATEGORY_SUCCESS, payload: res });
    })
    .catch(() => {
      return dispatch({ type: types.GET_ALL_CATEGORY_FAILURE });
    });
};

const getCollectionByGender = (payload) => (dispatch) => {
  dispatch({ type: types.GET_COLLECTION_BY_GENDER_REQUEST });
  return userApi
    .getCollectionByGender(payload)
    .then((res) => {
      return dispatch({
        type: types.GET_COLLECTION_BY_GENDER_SUCCESS,
        payload: res,
      });
    })
    .catch(() => {
      return dispatch({ type: types.GET_COLLECTION_BY_GENDER_FAILURE });
    });
};

const createProduct = (payload) => (dispatch) => {
  dispatch({ type: types.POST_PRODUCT_REQUEST });
  return userApi
    .createProduct(payload)
    .then((res) => {
      return dispatch({ type: types.POST_PRODUCT_SUCCESS, payload: res });
    })
    .catch((err) => {
      return dispatch({ type: types.POST_PRODUCT_FAILURE, payload: err });
    });
};
const getAllGender = () => (dispatch) => {
  dispatch({ type: types.GET_ALL_GENDER_REQUEST });
  return userApi
    .getAllGender()
    .then((res) => {
      return dispatch({ type: types.GET_ALL_GENDER_SUCCESS, payload: res });
    })
    .catch(() => {
      return dispatch({ type: types.GET_ALL_GENDER_FAILURE });
    });
};

const getAllProducts = (params, size, page) => (dispatch) => {
  dispatch({ type: types.GET_ALL_PRODUCTS_REQUEST });
  return userApi
    .getAllProducts(params, size, page)
    .then((res) => {
      return dispatch({ type: types.GET_ALL_PRODUCTS_SUCCESS, payload: res });
    })
    .catch(() => {
      return dispatch({ type: types.GET_ALL_PRODUCTS_FAILURE });
    });
};

const deleteProducts = (listId) => (dispatch) => {
  dispatch({ type: types.DELETE_PRODUCT_REQUEST });
  return userApi
    .deleteProducts(listId)
    .then((res) => {
      return dispatch({ type: types.DELETE_PRODUCT_SUCCESS });
    })
    .catch(() => {
      return dispatch({ type: types.DELETE_PRODUCT_FAILURE });
    });
};

const getDeatilProduct = (id) => (dispatch) => {
  dispatch({ type: types.GET_DETAIL_PRODUCT_REQUEST });
  return userApi
    .getDetailProduct(id)
    .then((res) => {
      return dispatch({ type: types.GET_DETAIL_PRODUCT_SUCCESS, payload: res });
    })
    .catch(() => {
      return dispatch({ type: types.GET_DETAIL_PRODUCT_FAILURE });
    });
};

const getAllPost = (params) => (dispatch) => {
  dispatch({ type: types.GET_ALL_POST_REQUEST });
  return userApi
    .getAllPost(params)
    .then((res) => {
      return dispatch({ type: types.GET_ALL_POST_SUCCESS, payload: res });
    })
    .catch(() => {
      return dispatch({ type: types.GET_ALL_POST_FAILURE });
    });
};

const createPost = (param) => (dispatch) => {
  dispatch({ type: types.POST_POST_REQUEST });
  return userApi
    .createPost(param)
    .then((res) => {
      return dispatch({ type: types.POST_POST_SUCCESS, payload: res });
    })
    .catch(() => {
      return dispatch({ type: types.POST_POST_FAILURE });
    });
};

const updatePost = (param) => (dispatch) => {
  dispatch({ type: types.PUT_POST_REQUEST });
  return userApi
    .updatePost(param)
    .then((res) => {
      return dispatch({ type: types.PUT_POST_SUCCESS });
    })
    .catch(() => {
      return dispatch({ type: types.PUT_POST_FAILURE });
    });
};

const getDetailPostById = (id) => (dispatch) => {
  dispatch({ type: types.GET_DETAIL_POST_REQUEST });
  return userApi
    .getDetailPostById(id)
    .then((res) => {
      return dispatch({ type: types.GET_DETAIL_POST_SUCCESS, payload: res });
    })
    .catch(() => {
      return dispatch({ type: types.GET_DETAIL_POST_FAILURE });
    });
};

const deletePostById = (id) => (dispatch) => {
  dispatch({ type: types.DELETE_POST_REQUEST });
  return userApi
    .deletePostById(id)
    .then((res) => {
      return dispatch({ type: types.DELETE_POST_SUCCESS });
    })
    .catch(() => {
      return dispatch({ type: types.DELETE_POST_FAILURE });
    });
};

const updateProduct = (id, params) => (dispatch) => {
  dispatch({ type: types.PUT_PRODUCT_REQUEST });
  return userApi
    .updateProduct(id, params)
    .then((res) => {
      return dispatch({ type: types.PUT_PRODUCT_SUCCESS });
    })
    .catch(() => {
      return dispatch({ type: types.PUT_PRODUCT_FAILURE });
    });
}

const createCategory = (payload) => (dispatch) =>{
  dispatch({ type: types.POST_CATEGORY_REQUEST });
  return userApi
    .createCategory(payload)
    .then((res) => {
      return dispatch({ type: types.POST_CATEGORY_SUCCESS});
    })
    .catch(() => {
      return dispatch({ type: types.POST_CATEGORY_FAILURE });
    });
}

const updateCategory = (id,payload) => (dispatch) =>{
  dispatch({ type: types.PUT_CATEGORY_REQUEST });
  return userApi
    .updateCategory(id,payload)
    .then((res) => {
      return dispatch({ type: types.PUT_CATEGORY_SUCCESS});
    })
    .catch(() => {
      return dispatch({ type: types.PUT_CATEGORY_FAILURE });
    });
}

const deleteCategory = (id) => (dispatch) =>{
  dispatch({ type: types.DELETE_CATEGORY_REQUEST });
  return userApi
    .deleteCategory(id)
    .then((res) => {
      return dispatch({ type: types.DELETE_CATEGORY_SUCCESS});
    })
    .catch(() => {
      return dispatch({ type: types.DELETE_CATEGORY_FAILURE });
    });
}
const updatePassword = (id,payload) => (dispatch) =>{
  dispatch({ type: types.UPDATE_PASSWORD_REQUEST });
  return userApi
    .updateInfo(payload,id)
    .then((res) => {
      return dispatch({ type: types.UPDATE_PASSWORD_SUCCESS});
    })
    .catch(() => {
      return dispatch({ type: types.UPDATE_PASSWORD_FAILURE });
    });
}
const setRefreshStage = (payload) => (dispatch) => {
  dispatch({ type: types.SET_REFRESH_STAGE });
  return dispatch({ type: types.SET_REFRESH_STAGE, payload: !payload });
};
export {
  getProduct,
  getCart,
  postCart,
  deleteCart,
  getSingleProduct,
  patchcart,
  getDetailUser,
  createCollection,
  getAllCollections,
  deleteCollections,
  getDetailCollection,
  updateDetailCollection,
  getAllCategory,
  getCollectionByGender,
  createProduct,
  getAllGender,
  getAllProducts,
  deleteProducts,
  getDeatilProduct,
  getAllPost,
  createPost,
  updatePost,
  getDetailPostById,
  deletePostById,
  updateProduct,
  createCategory,
  setRefreshStage,
  updateCategory,
  deleteCategory,
  updatePassword
};
