import * as React from "react";
import "./blog-detail-component.css";

import Footer from "../Footer-component/Footer-component";
import { useDispatch, useSelector } from "react-redux";
import { getDetailPostById } from "../../Redux/App/action";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import { PATH } from "../../Routes/router-path";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const BlogDetail = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const detailBlogs = useSelector((store) => store.AppReducer?.post);
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(getDetailPostById(searchParams?.get("id")));
  }, []);

  return (
    <div>
      <div className="container-blog">
        <div className="banner">
          <img width={"100%"} src={detailBlogs?.imgUrl} alt="detailBlogs" />
        </div>
        <div className="container-blog-detail">
          <Typography
            sx={{
              flex: "1 1 100%",
              display: "flex",
              justifyContent: "space-between",
            }}
            color="inherit"
            variant="subtitle1"
            component="div"
            className="pb-3"
          >
            <div>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={PATH.blogs}
                >
                  <ArrowBackIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Blogs
                </Link>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  Chi tiết blog
                </Typography>
              </Breadcrumbs>
            </div>
          </Typography>
          <Box sx={{ flexGrow: 1, p: 3 }}>
            <h1>{detailBlogs?.title}</h1>
            <pre className="pl-3 custom-display-des-post">{detailBlogs?.body}</pre>
          </Box>

          {detailBlogs?.subPostList &&
            detailBlogs?.subPostList?.map((data, key) => (
              <Box key={key} sx={{ flexGrow: 1, p: 3 }}>
                {data?.imgUrl && (
                  <div style={{display:"flex",alignItems:"center",justifyContent:"center"}} className="container-img-detail-blogs">
                    <img width={"100%"} src={data?.imgUrl} alt="detailBlogs" />
                  </div>
                )}
                {data?.subTitle && <h2>{data?.subTitle}</h2>}
                {data?.subDescription && <pre className="pl-3 custom-display-des-post">{data?.subDescription}</pre>}
              </Box>
            ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogDetail;
