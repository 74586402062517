import InventoryIcon from "@mui/icons-material/Inventory";
import LogoutIcon from "@mui/icons-material/Logout";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  Autocomplete,
  Avatar,
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { ReactComponent as Logo } from "../../constants/svg/cedi-logo.svg";
import { ReactComponent as Logo } from "../../constants/svg/qiU0Nb01.svg";
import * as types from "../../Redux/Auth/actionTypes";
import { clearUserInfo } from "../../untils/storage";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuCustom from "../menu/MenuCustom";
import SettingsIcon from "@mui/icons-material/Settings";
import "./Header-component.css";
import "../../CSS/style.css";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { getAllProducts } from "../../Redux/App/action";
import { GET_ALL_PRODUCTS_SUCCESS } from "../../Redux/App/actionTypes";
import { PATH } from "../../Routes/router-path";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [classHeader, setClassHeader] = useState("header-un-highlighted");
  const [isTopScroll, setIsTopScroll] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isShowHeader, setIsShowHeader] = useState(true);
  const [cartData, setCartData] = useState([]);
  const refreshStage = useSelector((state) => state.AppReducer.refreshStage);
  const [isAdmin, setIsAdmin] = useState(false);
  const auth = useSelector((store) => store.AuthReducer);
  const user = useSelector((store) => store.AppReducer);
  const [anchorEl, setAnchorEl] = useState(null);
  const [listProducts, setListProducts] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    clearUserInfo();
    dispatch({ type: types.GET_SIGNOUT_SUCCESS, payload: [] });
    navigate("/login");
    setAnchorEl(false);
  };
  const handleAdmin = () => {
    navigate("/admin");
    setAnchorEl(false);
  };
  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleBlur = () => {
    setExpanded(false);
  };

  const handleDisplayHeader = () => {
    if (window.location.pathname === "/") {
      const positionY = document.querySelector(".App")?.scrollTop,
        conditionSpaceY = 0;
      if (positionY > conditionSpaceY) {
        setClassHeader("header-un-highlighted");
        setIsTopScroll(false);
      } else {
        setClassHeader("header-highlighted");
        setIsTopScroll(true);
      }
    }
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    const convertName = name.toLocaleUpperCase();
    return {
      sx: {
        bgcolor: stringToColor(convertName.toLocaleUpperCase()),
      },
      children:
        convertName.split(" ").length > 1
          ? `${convertName.split(" ")[0][0]}${convertName.split(" ")[1][0]}`
          : `${convertName.split(" ")[0][0]}`,
    };
  }

  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role === "ROLE_ADMIN") {
      setIsAdmin(true);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    document
      .querySelector(".App")
      .addEventListener("scroll", handleDisplayHeader);
    return () => {
      document
        .querySelector(".App")
        .removeEventListener("scroll", handleDisplayHeader);
    };
  }, []);

  useEffect(() => {
    const params = {
      searchData: searchVal,
      category_id: null,
      collection_id: null,
      gender_id: null,
    };
    const timer = setTimeout(() => {
      if (searchVal?.length) {
        dispatch(getAllProducts(params, 9999, 0)).then((res) => {
          if (res.type === GET_ALL_PRODUCTS_SUCCESS) {
            setListProducts(res?.payload?.data ?? []);
          }
        });
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [searchVal]);

  useEffect(() => {
    if (
      window.location.pathname === "/login" ||
      window.location.pathname === "/signin"
    ) {
      setIsShowHeader(false);
    } else {
      setIsShowHeader(true);
    }
    setTimeout(() => {
      if (
        document.querySelector(".App")?.scrollTop <= 0 &&
        window.location.pathname === "/"
      ) {
        setIsTopScroll(true);
      } else {
        setIsTopScroll(false);
      }
    }, 100);
    if (window.location.pathname === "/") {
      setClassHeader("header-highlighted");
    } else {
      setClassHeader("header-un-highlighted");
    }
  }, [window.location.pathname]);

  useEffect(() => {
    const storedCartData = localStorage.getItem("cart");
    if (storedCartData) {
      setCartData(JSON.parse(storedCartData));
    } else {
      setCartData([]);
    }
  }, [refreshStage]);

  return isShowHeader ? (
    <div
      className={`container-header-cedi ${classHeader} ${
        isTopScroll ? "header-top" : "header-under-top"
      }`}
    >
      <div className="header-cedi">
        <div
          className={`btn-search-custom`}
          style={{ widthL: !expanded ? "auto" : "100%" }}
        >
          {!expanded && (
            <IconButton onClick={handleToggleExpand}>
              <SearchIcon />
            </IconButton>
          )}

          {expanded && (
            <Autocomplete
              id="highlights-demo"
              className={`highlights-search-product ${classHeader}`}
              // sx={{width:"100%" }}
              options={listProducts}
              freeSolo
              loading
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="standard-basic"
                  className="text-field-search-product"
                  variant="standard"
                  placeholder="Tìm tên sản phẩm"
                  onBlur={handleBlur}
                  value={searchVal}
                  onChange={(e) => {
                    setSearchVal(e?.target?.value);
                  }}
                />
              )}
              renderOption={(props, option, { inputValue }) => {
                const { key, ...optionProps } = props;
                const matches = match(option.name, inputValue, {
                  insideWords: true,
                });
                const parts = parse(option.name, matches);

                return (
                  <div
                    key={key}
                    {...optionProps}
                    onClick={() => {
                      setSearchVal("");
                      setExpanded(false);
                      navigate(`${PATH.detail_product}/${option?.id}`);
                    }}
                  >
                    <div className="d-flex">
                      <div className="d-flex pr-2">
                        <img
                          className="custom-thum-product"
                          width={100}
                          height={100}
                          src={option?.colorList[0]?.images[0]?.linkImg}
                          alt={key}
                        />
                      </div>
                      <div>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                              textDecoration: part.highlight
                                ? "underline"
                                : "unset",
                            }}
                          >
                            {part.text}
                          </span>
                        ))}

                        <ul>
                          <li>Loại sản phẩm: {option.categoryName}</li>
                          <li>
                            Thuộc bộ sưu tập:{" "}
                            {option.collectionName === "collectionnull"
                              ? "Không thuộc bộ sưu tập nào"
                              : option.collectionName}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              }}
            />
          )}
        </div>

        <div className={`logo-top-cedi logo-${isTopScroll}`}>
          <Logo
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
        <div className="action-header">
          <div>
            <IconButton onClick={() => navigate("/cart")}>
              <Badge badgeContent={cartData?.length} color="info">
                <ShoppingCartIcon style={{ color: "black" }} />
              </Badge>
            </IconButton>
          </div>
          <div>
            <MenuCustom />
          </div>
          <div>
            {auth?.userInfo?.username || user?.userInfo?.username ? (
              <Stack direction="row" spacing={2}>
                <Avatar
                  {...stringAvatar(
                    auth?.userInfo?.username || user?.userInfo?.username
                  )}
                  onClick={handleClick}
                />
              </Stack>
            ) : (
              <IconButton
                style={{ zIndex: 1000 }}
                onClick={() => {
                  navigate("/login");
                }}
              >
                <PersonIcon style={{ color: "black" }} />
              </IconButton>
            )}
          </div>
        </div>
        <div className="action-MoreVertIcon">
          <MenuCustom icon={<MoreVertIcon style={{ color: "black" }} />} />
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={handleAdmin}
          hidden={!isAdmin}
          style={{ width: 200 }}
        >
          <div
            className="d-flex justify-space-between"
            style={{ width: "100%" }}
          >
            <SettingsIcon />
            <div style={{ width: "100%", paddingLeft: "20px" }}>
              <span> Quản lý hệ thống</span>
            </div>
          </div>
        </MenuItem>
        <MenuItem onClick={() => {
          navigate("order-history");
          setAnchorEl(false);
        }}>
          <div
            className="d-flex justify-space-between"
            style={{ width: "100%" }}
          >
            <InventoryIcon />
            <div style={{ width: "100%", paddingLeft: "20px" }}>
              <span>Đơn hàng</span>
            </div>
          </div>
        </MenuItem>
        <MenuItem onClick={() => {
          navigate("user-info");
          setAnchorEl(false);
          }}>
          <div
            className="d-flex justify-space-between"
            style={{ width: "100%" }}
          >
            <AccountCircleIcon />
            <div style={{ width: "100%", paddingLeft: "20px" }}>
              <span>Thông tin tài khoản</span>
            </div>
          </div>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <div
            className="d-flex justify-space-between"
            style={{ width: "100%" }}
          >
            <LogoutIcon />
            <div style={{ width: "100%", paddingLeft: "20px" }}>
              <span>Đăng xuất</span>
            </div>
          </div>
        </MenuItem>
      </Menu>
    </div>
  ) : (
    <></>
  );
};

export default Header;
