import React from "react";
import { Navigate } from "react-router-dom";
import { getRole, getToken } from "../untils/storage";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = () => {
    const token = getToken();
    const role = getRole();
    if (token && role === "ROLE_ADMIN") return true;
    return false;
  };

  return isAuthenticated() ? children : <Navigate to={"/"} />;
};

export default PrivateRoute;
