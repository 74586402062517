export const BASE_URL = "https://api.vaidiepanh.com";
// export const BASE_URL = "http://localhost:8080";

export const apiPath = {
  //User
  getListUser: BASE_URL + "/api/admin/users",
  updateUser: BASE_URL + "/api/admin/users/active",

  //product
  getDetailProduct: BASE_URL + "/api/product/get-product-by-id",
  getAllProduct: BASE_URL + "/api/product/filter-all-product",

  //cart
  createdOder: BASE_URL + "/api/order/create",
  getAllOder: BASE_URL + "/api/order",
  updateOder: BASE_URL + "/api/order/update-state",
  getOderByUserID: BASE_URL + "/api/order/get-by-user-id",
  // collection
  getAllCollection: BASE_URL + "/api/collections/all-collection",
  menuCollection: BASE_URL + "/api/collections/collection-gender",
};
