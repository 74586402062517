import React from "react";
import styled from "styled-components";

const Footer = () => {
  return (
    <Container className="footer">
      <div className="footer_display">
        <div>
          <h3>Map</h3>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d701.7611214903108!2d105.94760318053189!3d21.07331003162869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135a8141a0c3af7%3A0xf93954a3fb801a86!2zS2h1IGPDtG5nIG5naGnhu4dwIE5pbmggSGnhu4dwLCBOaW5oIEhp4buHcCwgR2lhIEzDom0sIEjDoCBO4buZaSwgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1718717937495!5m2!1svi!2s"
            width="300"
            height="300"
            allowfullscreen=""
            title="ban do"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div>
          <h3>Hệ thống cửa hàng</h3>
          <div>FACEBOOK</div>
          <div>Zalo</div>
        </div>
        <div>
          <h3>Hệ thống cửa hàng</h3>
          <div>FACEBOOK</div>
          <div>Zalo</div>
        </div>
        <div>
          <h3>Thông tin doanh nghiệp</h3>
          <div>CÔNG TY CỔ PHẦN TẬP ĐOÀN CEDI</div>
          <div>
            Văn Phòng : CN04-12 Cụm Công Nghiệp , Ninh Hiệp , Gia Lâm , Hà Nội
          </div>
          <div>Điện thoại: 0868.925.866</div>
          <div>Email: ceo@cedi.vn</div>
        </div>
      </div>

      <div className="footer_bottom"></div>
    </Container>
  );
};
const Container = styled.div`
  // // background-color: #000;
  // padding: 5rem 4rem;
  // display: flex;
  // flex-direction: column;
  // .footer_display {
  //   display: flex;
  //   align-items: flex-start;
  //   gap: 30px;
  // }
`;

export default Footer;
