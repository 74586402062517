import * as types from "./actionTypes";

const initialState = {
  products: [],
  cart: [],
  single: [],
  isLoading: false,
  isError: false,
  userInfo: {},
  collection: {},
  collections: [],
  categories: [],
  collectionByGender: [],
  product: {},
  posts: [],
  post: {},
  refreshStage: false,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_REFRESH_STAGE:
      return { ...state, refreshStage: payload };

    case types.GET_PRODUCTS_REQUEST:
      return { ...state, isLoading: true };

    case types.GET_PRODUCTS_SUCCESS:
      return { ...state, isLoading: false, products: payload };

    case types.GET_PRODUCTS_FAILURE:
      return { ...state, isLoading: false, isError: true };

    case types.GET_SINGLE_REQUEST:
      return { ...state, isLoading: true };

    case types.GET_SINGLE_SUCCESS:
      return { ...state, isLoading: false, single: payload };

    case types.GET_SINGLE_FAILURE:
      return { ...state, isLoading: false, isError: true };

    case types.GET_CART_REQUEST:
      return { ...state, isLoading: true };

    case types.GET_CART_SUCCESS:
      return { ...state, isLoading: false, cart: payload };

    case types.GET_CART_FAILURE:
      return { ...state, isLoading: false, isError: true, cart: [] };

    case types.DELETE_CART_REQUEST:
      return { ...state, isLoading: true };

    case types.DELETE_CART_SUCCESS:
      return { ...state, isLoading: false, cart: payload };

    case types.DELETE_CART_FAILURE:
      return { ...state, isLoading: false, isError: true, cart: [] };

    case types.POST_CART_REQUEST:
      return { ...state, isLoading: true };

    case types.POST_CART_SUCCESS:
      return { ...state, isLoading: false, cart: payload };

    case types.POST_CART_FAILURE:
      return { ...state, isLoading: false, isError: true, cart: [] };

    case types.PATCH_CART_REQUEST:
      return { ...state, isLoading: true };

    case types.PATCH_CART_SUCCESS:
      return { ...state, isLoading: false, cart: payload };

    case types.PATCH_CART_FAILURE:
      return { ...state, isLoading: false, isError: true, cart: [] };

    case types.GET_DETAIL_USER_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_DETAIL_USER_SUCCESS:
      return { ...state, isLoading: false, userInfo: payload };
    case types.GET_DETAIL_USER_FAILURE:
      return { ...state, isLoading: false, userInfo: {} };

    case types.GET_ALL_COLLECTIONS_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_ALL_COLLECTIONS_SUCCESS:
      return { ...state, isLoading: false, collections: payload };
    case types.GET_ALL_COLLECTIONS_FAILURE:
      return { ...state, isLoading: false, collections: [] };

    case types.POST_COLLECTION_REQUEST:
      return { ...state, isLoading: true };
    case types.POST_COLLECTION_SUCCESS:
      return { ...state, isLoading: false };
    case types.POST_COLLECTION_FAILURE:
      return { ...state, isLoading: false };

    case types.DELETE_COLLECTION_REQUEST:
      return { ...state, isLoading: true };
    case types.DELETE_COLLECTION_SUCCESS:
      return { ...state, isLoading: false };
    case types.DELETE_COLLECTION_FAILURE:
      return { ...state, isLoading: false };

    case types.GET_DETAIL_COLLECTION_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_DETAIL_COLLECTION_SUCCESS:
      return { ...state, isLoading: false, collection: payload };
    case types.GET_DETAIL_COLLECTION_FAILURE:
      return { ...state, isLoading: false, collection: {} };

    case types.PUT_DETAIL_COLLECTION_REQUEST:
      return { ...state, isLoading: true };
    case types.PUT_DETAIL_COLLECTION_SUCCESS:
      return { ...state, isLoading: false };
    case types.PUT_DETAIL_COLLECTION_FAILURE:
      return { ...state, isLoading: false };

    case types.GET_ALL_CATEGORY_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_ALL_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, categories: payload };
    case types.GET_ALL_CATEGORY_FAILURE:
      return { ...state, isLoading: false, categories: [] };

    case types.GET_COLLECTION_BY_GENDER_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_COLLECTION_BY_GENDER_SUCCESS:
      return { ...state, isLoading: false, collectionByGender: payload };
    case types.GET_COLLECTION_BY_GENDER_FAILURE:
      return { ...state, isLoading: false, collectionByGender: [] };

    case types.POST_PRODUCT_REQUEST:
      return { ...state, isLoading: true };
    case types.POST_PRODUCT_SUCCESS:
      return { ...state, isLoading: false };
    case types.POST_PRODUCT_FAILURE:
      return { ...state, isLoading: false };

    case types.GET_ALL_GENDER_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_ALL_GENDER_SUCCESS:
      return { ...state, isLoading: false };
    case types.GET_ALL_GENDER_FAILURE:
      return { ...state, isLoading: false };

    case types.GET_ALL_PRODUCTS_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_ALL_PRODUCTS_SUCCESS:
      return { ...state, isLoading: false, products: payload };
    case types.GET_ALL_PRODUCTS_FAILURE:
      return { ...state, isLoading: false, products: [] };

    case types.DELETE_PRODUCT_REQUEST:
      return { ...state, isLoading: true };
    case types.DELETE_PRODUCT_SUCCESS:
      return { ...state, isLoading: false };
    case types.DELETE_PRODUCT_FAILURE:
      return { ...state, isLoading: false };

    case types.GET_DETAIL_PRODUCT_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_DETAIL_PRODUCT_SUCCESS:
      return { ...state, isLoading: false, product: payload };
    case types.GET_DETAIL_PRODUCT_FAILURE:
      return { ...state, isLoading: false, product: {} };

    case types.POST_POST_REQUEST:
      return { ...state, isLoading: true };
    case types.POST_POST_SUCCESS:
      return { ...state, isLoading: false };
    case types.POST_POST_FAILURE:
      return { ...state, isLoading: false };

    case types.PUT_POST_REQUEST:
      return { ...state, isLoading: true };
    case types.PUT_POST_SUCCESS:
      return { ...state, isLoading: false };
    case types.PUT_POST_FAILURE:
      return { ...state, isLoading: false };

    case types.GET_ALL_POST_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_ALL_POST_SUCCESS:
      return { ...state, isLoading: false, posts: payload };
    case types.GET_ALL_POST_FAILURE:
      return { ...state, isLoading: false, posts: [] };

    case types.GET_DETAIL_POST_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_DETAIL_POST_SUCCESS:
      return { ...state, isLoading: false, post: payload };
    case types.GET_DETAIL_POST_FAILURE:
      return { ...state, isLoading: false, post: {} };

    case types.DELETE_POST_REQUEST:
      return { ...state, isLoading: true };
    case types.DELETE_POST_SUCCESS:
      return { ...state, isLoading: false };
    case types.DELETE_POST_FAILURE:
      return { ...state, isLoading: false };

    case types.PUT_PRODUCT_REQUEST:
      return { ...state, isLoading: true };
    case types.PUT_PRODUCT_SUCCESS:
      return { ...state, isLoading: false };
    case types.PUT_PRODUCT_FAILURE:
      return { ...state, isLoading: false };

    case types.POST_CATEGORY_REQUEST:
      return { ...state, isLoading: true };
    case types.POST_CATEGORY_SUCCESS:
      return { ...state, isLoading: false };
    case types.POST_CATEGORY_FAILURE:
      return { ...state, isLoading: false };

    case types.PUT_CATEGORY_REQUEST:
      return { ...state, isLoading: true };
    case types.PUT_CATEGORY_SUCCESS:
      return { ...state, isLoading: false };
    case types.PUT_CATEGORY_FAILURE:
      return { ...state, isLoading: false };

    case types.DELETE_CATEGORY_REQUEST:
      return { ...state, isLoading: true };
    case types.DELETE_CATEGORY_SUCCESS:
      return { ...state, isLoading: false };
    case types.DELETE_CATEGORY_FAILURE:
      return { ...state, isLoading: false };

    case types.UPDATE_PASSWORD_REQUEST:
      return { ...state, isLoading: true };
    case types.UPDATE_PASSWORD_SUCCESS:
      return { ...state, isLoading: false };
    case types.UPDATE_PASSWORD_FAILURE:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
