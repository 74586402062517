import { apiPath } from "./apiPath";
import { getRequest, postRequest } from "./axiosApi";

export const getDetailProduct = async (
  params,
  successCallback,
  errorCallback
) => {
  await getRequest(
    `${apiPath.getDetailProduct}/?productId=${params.id}`,
    params,
    successCallback,
    errorCallback
  );
};

export const getAllProduct = async (params, successCallback, errorCallback) => {
  await postRequest(
    `${apiPath.getAllProduct}/?size=${params.size}`,
    params,
    successCallback,
    errorCallback
  );
};
