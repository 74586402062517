import * as React from "react";
import "./Category-component.css";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../Routes/router-path";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  createCategory,
  deleteCategory,
  getAllCategory,
  updateCategory,
} from "../../Redux/App/action";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import useDebounce from "../../untils/useDebouce";
import {
  DELETE_CATEGORY_FAILURE,
  DELETE_CATEGORY_SUCCESS,
  GET_ALL_CATEGORY_SUCCESS,
  POST_CATEGORY_SUCCESS,
  PUT_CATEGORY_SUCCESS,
} from "../../Redux/App/actionTypes";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ROWSPERPAGEOPTIONS } from "../../constants/appConstants";
import { Select as BaseSelect, selectClasses } from "@mui/base/Select";
import { Option as BaseOption, optionClasses } from "@mui/base/Option";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";
import toast, { Toaster } from "react-hot-toast";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      textOverflow: "ellipsis",
      "&:focus": {
        width: "100%",
        backgroundColor: "rgb(243, 248, 253)",
      },
      "&:hover": {
        width: "100%",
        backgroundColor: "rgb(243, 248, 253)",
      },
    },
  },
}));

const options = [
  { label: "Sửa", path: PATH.edit_collection, action: "redirect" },
  { label: "Xóa", path: "", action: "delete" },
];

function handleDelete(
  id,
  dispatch,
  callBackDelete,
  setIsShowCreate,
  setIsShowEdit,
  setDetailCategory,
  setInitialValues
) {
  dispatch(deleteCategory([].concat(id))).then((res) => {
    if (res?.type === DELETE_CATEGORY_SUCCESS) {
      if (callBackDelete) {
        if (typeof id === "object") callBackDelete([]);
        if (typeof id === "number") callBackDelete(null);
      }
      toast.success("Xóa thành công", {
        duration: 2000,
      });
      dispatch(getAllCategory()).then((res) => {
        if (res?.type === GET_ALL_CATEGORY_SUCCESS) {
          setIsShowCreate(false);
          setIsShowEdit(false);
          setDetailCategory(null);
          setInitialValues(null);
        } else {
          toast.errors("Tải danh sách loại sản phẩm thất bại", {
            duration: 2000,
          });
        }
      });
    } else if (res?.type === DELETE_CATEGORY_FAILURE) {
      toast.errors("Xóa thất bại", {
        duration: 2000,
      });
    }
  });
}

const ITEM_HEIGHT = 48;
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Tên",
    minWidth: 200,
  },
  {
    id: "createdDate",
    numeric: false,
    disablePadding: false,
    label: "Ngày tạo",
    minWidth: 200,
  },
  {
    id: "updatedDate",
    numeric: false,
    disablePadding: false,
    label: "Ngày sửa",
    minWidth: 200,
  },
  {
    id: "",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ minWidth: headCell.minWidth }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    itemSelected,
    callBackDelete,
    setIsShowCreate,
    setInitialValues,
    setIsShowEdit,
    listStatus,
    setStatus,
    setDetailCategory,
  } = props;
  const dispatch = useDispatch();

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} Đã chọn
          </Typography>
        ) : (
          <Typography
            sx={{
              flex: "1 1 100%",
              justifyContent: "space-between",
              display: "flex",
            }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            <span>Quản lý loại sản phẩm</span>
            <Button
              variant="contained"
              onClick={() => {
                setIsShowEdit(false);
                setIsShowCreate(true);
                const initVal = {
                  name: "",
                  status: listStatus[0],
                };
                setStatus(listStatus[0]);
                setInitialValues(initVal);
              }}
            >
              Tạo mới
            </Button>
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton
              onClick={() => {
                handleDelete(
                  itemSelected,
                  dispatch,
                  callBackDelete,
                  setIsShowCreate,
                  setIsShowEdit,
                  setDetailCategory,
                  setInitialValues
                );
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
      </Toolbar>
    </>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  itemSelected: PropTypes.array,
  callBackDelete: PropTypes.func,
  setIsShowCreate: PropTypes.func,
  setInitialValues: PropTypes.func,
  setIsShowEdit: PropTypes.func,
  listStatus: PropTypes.array,
  setStatus: PropTypes.func,
  setDetailCategory: PropTypes.func,
};

const Category = () => {
  const defaultValidationSchema = Yup.object().shape({
    name: Yup.string().required("title of tickets is required"),
    status: Yup.object(),
  });
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(ROWSPERPAGEOPTIONS[0]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState("");
  const searchDebounce = useDebounce(searchValue.trim());
  const [initialValues, setInitialValues] = React.useState();
  const categories = useSelector((store) => store.AppReducer.categories);
  const [detailCategory, setDetailCategory] = React.useState(null);
  const [isShowEdit, setIsShowEdit] = React.useState(false);
  const [isShowCreate, setIsShowCreate] = React.useState(false);
  const [listStatus, setListStatus] = React.useState([
    {
      name: "Hoạt động",
      race: true,
    },
    {
      name: "Không hoạt động",
      race: false,
    },
  ]);
  const [status, setStatus] = React.useState(listStatus[0]);
  const handleClickAction = (event, id, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
    setDetailCategory(row);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = categories.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleAction = (isDelete, path) => {
    if (isDelete) {
      handleDelete(
        selectedId,
        dispatch,
        setSelectedId,
        setIsShowCreate,
        setIsShowEdit,
        setDetailCategory,
        setInitialValues
      );
    } else {
      setIsShowCreate(false);
      setIsShowEdit(true);
      const valPatch = {
        name: detailCategory?.name,
        status: detailCategory?.status ? listStatus[0] : listStatus[1],
      };
      setStatus(detailCategory?.status ? listStatus[0] : listStatus[1]);

      setInitialValues(valPatch);
    }
    handleClose();
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - categories.length) : 0;

  let visibleRows = React.useMemo(
    () =>
      stableSort(categories, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, categories]
  );

  function onSubmit(fields) {
    const dataSave = {
      name: fields?.name,
      status: fields?.status?.race,
    };
    if (isShowCreate) {
      dispatch(createCategory(dataSave)).then((res) => {
        if (res?.type === POST_CATEGORY_SUCCESS) {
          toast.success("Tạo thành công", {
            duration: 2000,
          });
          dispatch(getAllCategory()).then((resAll) => {
            if (resAll?.type === GET_ALL_CATEGORY_SUCCESS) {
              setIsShowCreate(false);
              setIsShowEdit(false);
              setDetailCategory(null);
              setInitialValues(null);
            }
          });
        } else {
          toast.errors("Xóa thất bại", {
            duration: 2000,
          });
        }
      });
    }

    if (isShowEdit) {
      dispatch(updateCategory(detailCategory?.id, dataSave)).then((res) => {
        if (res?.type === PUT_CATEGORY_SUCCESS) {
          toast.success("Cập nhật thành công", {
            duration: 2000,
          });
          dispatch(getAllCategory()).then((resAll) => {
            if (resAll?.type === GET_ALL_CATEGORY_SUCCESS) {
              setIsShowCreate(false);
              setIsShowEdit(false);
              setDetailCategory(null);
              setInitialValues(null);
            }
          });
        } else {
          toast.errors("Cập nhật thất bại", {
            duration: 2000,
          });
        }
      });
    }
  }

  React.useEffect(() => {
    dispatch(getAllCategory());
  }, []);

  return (
    <>
      {(isShowEdit || isShowCreate) && (
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2, overflow: "hidden" }}>
            <Box>
              {initialValues && (
                <Formik
                  initialValues={initialValues}
                  enableReinitialize
                  validationSchema={defaultValidationSchema}
                  onSubmit={onSubmit}
                >
                  {({ errors, values, touched, setValues, validateField }) => (
                    <Form>
                      <Typography
                        sx={{
                          flex: "1 1 100%",
                          justifyContent: "space-between",
                          display: "flex",
                        }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                      >
                        <span>
                          {isShowEdit
                            ? "Chỉnh sửa loại sản phẩm"
                            : "Tạo mới loại sản phẩm"}{" "}
                        </span>

                        <div className=" text-center border-top-0">
                          <div>
                            <Button type="submit" variant="contained">
                              Lưu
                            </Button>
                            <Button
                              variant="outlined"
                              style={{ marginLeft: "1rem" }}
                              onClick={() => {
                                setIsShowEdit(false);
                                setIsShowCreate(false);
                                setInitialValues(null);
                                setDetailCategory(null);
                              }}
                            >
                              Hủy
                            </Button>
                          </div>
                        </div>
                      </Typography>
                      <Field name="name">
                        {() => (
                          <div className="form-row">
                            <div className="form-group col-12 col-lg-6">
                              <label>Tên loại sản phẩm</label>
                              <Field
                                name="name"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.name && errors.name && touched.name
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="form-group col-12 col-lg-6">
                              <label>Trạng thái</label>
                              <Select
                                name="status"
                                value={status}
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    setValues({ ...values, status: newValue });
                                    setStatus(newValue);
                                  }
                                }}
                              >
                                {listStatus.map((c) => (
                                  <Option key={c.name} value={c}>
                                    {c.name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                        )}
                      </Field>
                    </Form>
                  )}
                </Formik>
              )}
            </Box>
          </Paper>
        </Box>
      )}

      <Box sx={{ width: "100%" }}>
        <Toaster
          position="top-center"
          reverseOrder={false}
          containerClassName="custom-toatser"
        />
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            itemSelected={selected}
            callBackDelete={setSelected}
            setIsShowCreate={setIsShowCreate}
            setInitialValues={setInitialValues}
            setIsShowEdit={setIsShowEdit}
            listStatus={listStatus}
            setStatus={setStatus}
            setDetailCategory={setDetailCategory}
          />
          <Search className="custom-search-collections">
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
          <TableContainer sx={{ maxHeight: "65vh" }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              stickyHeader
              aria-label="sticky table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={categories.length}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, row.id)}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell>{row.createdDate}</TableCell>
                      <TableCell>{row.updatedDate}</TableCell>
                      <TableCell>
                        <div>
                          <IconButton
                            aria-label="more"
                            id={`long-button`}
                            aria-controls={open ? `long-menu` : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => {
                              handleClickAction(e, row.id, row);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={ROWSPERPAGEOPTIONS}
            component="div"
            count={categories.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        <Menu
          id={`long-menu`}
          MenuListProps={{
            "aria-labelledby": `long-button`,
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option.path}
              onClick={() => {
                handleAction(option.action === "delete", option.path);
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </>
  );
};

export default Category;

function Select(props) {
  const slots = {
    root: ButtonSelect,
    listbox: Listbox,
    popup: Popup,
    ...props.slots,
  };

  return <BaseSelect {...props} slots={slots} />;
}

Select.propTypes = {
  /**
   * The components used for each slot inside the Select.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  slots: PropTypes.shape({
    listbox: PropTypes.elementType,
    popup: PropTypes.elementType,
    root: PropTypes.elementType,
  }),
};

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const ButtonSelect = React.forwardRef(function Button(props, ref) {
  const { ownerState, ...other } = props;
  return (
    <StyledButton type="button" {...other} ref={ref}>
      {other.children}
      <UnfoldMoreRoundedIcon />
    </StyledButton>
  );
});

ButtonSelect.propTypes = {
  children: PropTypes.node,
  ownerState: PropTypes.object.isRequired,
};

const StyledButton = styled("button", { shouldForwardProp: () => true })(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  // min-width: 320px;
  width: 100%;
  padding: 8px 12px;
  border-radius: 8px;
  text-align: left;
  line-height: 1.5;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  position: relative;
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
  }

  &.${selectClasses.focusVisible} {
    outline: 0;
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  & > svg {
    font-size: 1rem;
    position: absolute;
    height: 100%;
    top: 0;
    right: 10px;
  }
  `
);

const Listbox = styled("ul")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  // min-width: 320px;
  width: 100%;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  box-shadow: 0px 2px 6px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.50)" : "rgba(0,0,0, 0.05)"
  };
  `
);

const Option = styled(BaseOption)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &:focus-visible {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
  }

  &.${optionClasses.highlighted}.${optionClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);
const Popup = styled("div")`
  z-index: 100;
`;
