import * as React from "react";
// import "./Edit-collection-component.css";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../../Routes/router-path";
import * as Yup from "yup";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import {
  createPost,
  getDetailPostById,
  updatePost,
} from "../../../Redux/App/action";
import { useParams } from "react-router-dom";
import {
  GET_DETAIL_POST_SUCCESS,
  POST_POST_SUCCESS,
  PUT_POST_SUCCESS,
} from "../../../Redux/App/actionTypes";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import toast, { Toaster } from "react-hot-toast";
import { styled } from "@mui/system";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";

const EditPost = () => {
  const defaultValidationSchema = Yup.object().shape({
    title: Yup.string(),
    body: Yup.string(),
    file: Yup.mixed().test(
      "is-file-or-string",
      "Invalid file format",
      (value) => {
        // Kiểm tra xem value có phải là một tệp tin không
        return (
          typeof value === "string" ||
          value instanceof File ||
          typeof value === "undefined"
        );
      }
    ),
    subPostReqList: Yup.array().of(
      Yup.object().shape({
        subTitle: Yup.string(),
        subDescription: Yup.string(),
        file: Yup.mixed().test(
          "is-file-or-string",
          "Invalid file format",
          (value) => {
            // Kiểm tra xem value có phải là một tệp tin không
            return (
              typeof value === "string" ||
              value instanceof File ||
              typeof value === "undefined"
            );
          }
        ),
        id: Yup.number()
      })
    ),
  });

  const navigate = useNavigate();
  const [initialValues, setInitialValues] = React.useState(null);
  const [validationSchema, setValidationSchema] = React.useState(
    defaultValidationSchema
  );
  const dispath = useDispatch();
  let { idPost } = useParams();

  React.useEffect(() => {
    if (window.location.pathname === PATH.create_post) {
      setInitialValues({
        title: "",
        body: "",
        file: "",
        subPostReqList: [
          {
            subTitle: "",
            subDescription: "",
            file: "",
          },
        ],
      });
    }
    if (window.location.pathname?.match(PATH.edit_post) && idPost) {
      dispath(getDetailPostById(idPost)).then((res) => {
        if (res?.type === GET_DETAIL_POST_SUCCESS) {
          const data = res?.payload;
          const subPostList = data?.subPostList?.map((item) => {
            return {
              subTitle: item?.subTitle ?? "",
              subDescription: item?.subDescription ?? "",
              file: item?.imgUrl ?? "",
              id: item?.id
            };
          });
          const patchData = {
            title: data.title ?? "",
            body: data.body ?? "",
            file: data.imgUrl ?? "",
            subPostReqList: subPostList,
          };
          setInitialValues(patchData);
        }
      });
    }
  }, [window.location.pathname]);

  function onSubmit(fields) {
    const formData = new FormData();
    if (window.location.pathname?.match(PATH.edit_post) && idPost) {
      formData.append("title", fields.title);
      formData.append("id", idPost);
      formData.append("body", fields.body);
      if (typeof fields.file !== "string") {
        formData.append("file", fields.file);
      }
      fields.subPostReqList?.forEach((item, index) => {
        if (typeof item?.file !== "string") {
          formData.append(`subPostReqList[${index}].file`, item?.file);
        }
        if(item?.id){
          formData.append(`subPostReqList[${index}].id`, item?.id);
        }
        formData.append(`subPostReqList[${index}].subTitle`, item?.subTitle);
        formData.append(
          `subPostReqList[${index}].subDescription`,
          item?.subDescription
        );
      });

      dispath(updatePost(formData)).then((res) => {
        if (res.type === PUT_POST_SUCCESS) {
          toast.success("Cập nhật thành công", {
            duration: 2000,
          });
          setTimeout(() => {
            navigate(PATH.posts);
          }, 500);
        } else {
          toast.error("Cập nhật thành công", {
            duration: 2000,
          });
        }
      });
    }

    if (window.location.pathname === PATH.create_post) {
      formData.append("title", fields.title);
      formData.append("body", fields.body);
      formData.append("file", fields.file);
      fields.subPostReqList?.forEach((item, index) => {
        formData.append(`subPostReqList[${index}].file`, item?.file);
        formData.append(`subPostReqList[${index}].subTitle`, item?.subTitle);
        formData.append(
          `subPostReqList[${index}].subDescription`,
          item?.subDescription
        );
      });

      dispath(createPost(formData)).then((res) => {
        if (res.type === POST_POST_SUCCESS) {
          toast.success("Tạo thành công", {
            duration: 2000,
          });
          setTimeout(() => {
            navigate(PATH.posts);
          }, 500);
        } else {
          toast.error("Tạo thất bại", {
            duration: 2000,
          });
        }
      });
    }
  }

  function onChangeFile(e, i, values, setValues) {
    if (e?.target?.files[0]) {
      const subPostReqList = [...values.subPostReqList];
      subPostReqList[i].file = e.target.files[0];
      setValues({ ...values, subPostReqList });
    }
  }

  function onChangeFileBlog(e, values, setValues) {
    if (e?.target?.files[0]) {
      setValues({ ...values, file: e.target.files[0] });
    }
  }

  function removeFileBlog(values, setValues) {
    setValues({ ...values, file: "" });
  }

  function removeFile(i, values, setValues) {
    const subPostReqList = [...values.subPostReqList];
    subPostReqList[i].file = "";
    setValues({ ...values, subPostReqList });
  }

  function handleAddSubBlog(i, values, setValues) {
    const newItem = {
      subTitle: "",
      subDescription: "",
      file: "",
    };
    if (!i && values.subPostReqList?.length === 1) {
      values.subPostReqList.push(newItem);
      setValues({ ...values });
    } else {
      values.subPostReqList.splice(i, 0, newItem);
      setValues({ ...values });
    }
  }

  function handleRemoveSubBlog(i, values, setValues) {
    values.subPostReqList.splice(i, 1);
    setValues({ ...values });
  }

  function getImage(file) {
    if (file instanceof File) {
      return URL.createObjectURL(file);
    } else {
      return file;
    }
  }

  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerClassName="custom-toatser"
      />

      <Typography
        sx={{
          flex: "1 1 100%",
          display: "flex",
          justifyContent: "space-between",
        }}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        <div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              href={PATH.posts}
            >
              <ArrowBackIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Quản lý blog
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              Chỉnh sửa blog
            </Typography>
          </Breadcrumbs>
        </div>
      </Typography>
      {initialValues && (
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, values, touched, setValues, validateField }) => (
            <Form>
              <Field name="title">
                {() => (
                  <div className="form-row">
                    <div className="form-group col-12">
                      <label>Tiêu đề blog</label>
                      <Field
                        name="title"
                        type="text"
                        className={
                          "form-control" +
                          (errors.title && errors.title && touched.title
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group col-12">
                      <label>Miêu tả</label>
                      <Field name="body" type="text">
                        {({ field }) => (
                          <Textarea
                            {...field}
                            aria-label="minimum height"
                            minRows={3}
                            maxRows={4}
                            className={
                              "form-control" +
                              (errors.body && errors.body && touched.body
                                ? " is-invalid"
                                : "")
                            }
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="body"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group col-12">
                      <label>Chọn ảnh</label>
                      <Field
                        name="file"
                        type="file"
                        className={
                          "form-control" +
                          (errors.file && errors.file && errors.file
                            ? " is-invalid"
                            : "")
                        }
                      >
                        {(event) => (
                          <>
                            <input
                              {...event?.filed}
                              id="blog-file"
                              name="file"
                              type="file"
                              className="custom-input-file"
                              onChange={(e) => {
                                onChangeFileBlog(e, values, setValues);
                              }}
                            />

                            <label
                              htmlFor="blog-file"
                              className={
                                "form-control" +
                                (errors.file && errors.file && errors.file
                                  ? " is-invalid"
                                  : "" + " custom-label-input")
                              }
                            >
                              {values?.file
                                ? values?.file?.name || values?.file
                                : "Chọn ảnh:"}
                            </label>
                          </>
                        )}
                      </Field>
                      <ErrorMessage
                        name="file"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="col-12 list-group-item custom-preview-img">
                      {values.file && (
                        <>
                          <div className="cancel-img">
                            <IconButton
                              onClick={() => {
                                removeFileBlog(values, setValues);
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>
                          <img
                            src={`${getImage(values.file)}` + ""}
                            alt="preview image"
                            width="100%"
                            height="100%"
                          />
                        </>
                      )}
                    </div>
                  </div>
                )}
              </Field>
              <FieldArray name="subPostReqList">
                {({ insert, remove }) =>
                  values.subPostReqList?.map((item, i) => {
                    const itemErrors =
                      (errors.subPostReqList?.length &&
                        errors.subPostReqList[i]) ||
                      {};
                    const itemTouched =
                      (touched.subPostReqList?.length &&
                        touched.subPostReqList[i]) ||
                      {};
                    return (
                      <div key={i} className="col-12 mb-5">
                        <div className="list-group-item mt-3 mb-5">
                          <h5 className="card-title">Hiển thị {i + 1}</h5>
                          <div className="form-row">
                            <div className="form-group col-12">
                              <label>Tiêu đề</label>
                              <Field
                                name={`subPostReqList.${i}.subTitle`}
                                type="text"
                                className={
                                  "form-control" +
                                  (itemErrors.subTitle &&
                                  itemErrors.subTitle &&
                                  itemTouched.subTitle
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name={`subPostReqList.${i}.subTitle`}
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="form-group col-12">
                              <label>Miêu tả</label>
                              <Field
                                name={`subPostReqList.${i}.subDescription`}
                                type="text"
                              >
                                {({ field }) => (
                                  <Textarea
                                    {...field}
                                    aria-label="minimum height"
                                    minRows={3}
                                    maxRows={4}
                                    className={
                                      "form-control" +
                                      (itemErrors.subDescription &&
                                      itemErrors.subDescription &&
                                      itemTouched.subDescription
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                )}
                              </Field>
                              <ErrorMessage
                                name={`subPostReqList.${i}.subDescription`}
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="form-group col-12">
                              <label>Chọn ảnh</label>
                              <Field
                                name={`subPostReqList.${i}.file`}
                                type="file"
                                className={
                                  "form-control" +
                                  (itemErrors.file &&
                                  itemErrors.file &&
                                  itemTouched.file
                                    ? " is-invalid"
                                    : "")
                                }
                              >
                                {(event) => (
                                  <>
                                    <input
                                      {...event?.filed}
                                      id={`subPostReqList.${i}.file`}
                                      name={`subPostReqList.${i}.file`}
                                      type="file"
                                      className="custom-input-file"
                                      onChange={(e) => {
                                        onChangeFile(e, i, values, setValues);
                                      }}
                                    />

                                    <label
                                      htmlFor={`subPostReqList.${i}.file`}
                                      className={
                                        "form-control" +
                                        (itemErrors.file &&
                                        itemErrors.file &&
                                        itemTouched.file
                                          ? " is-invalid"
                                          : "" + " custom-label-input")
                                      }
                                    >
                                      {values.subPostReqList[i].file
                                        ? values.subPostReqList[i].file.name ||
                                          values.subPostReqList[i].file
                                        : "Chọn ảnh:"}
                                    </label>
                                  </>
                                )}
                              </Field>
                              <ErrorMessage
                                name={`subPostReqList.${i}.file`}
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="col-12 list-group-item custom-preview-img">
                              {values.subPostReqList[i]?.file && (
                                <>
                                  <div className="cancel-img">
                                    <IconButton
                                      onClick={() => {
                                        removeFile(i, values, setValues);
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </div>
                                  <img
                                    src={
                                      `${getImage(
                                        values.subPostReqList[i]?.file
                                      )}` + ""
                                    }
                                    alt="preview image"
                                    width="100%"
                                    height="100%"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <IconButton
                            className="custom-add-item-product"
                            onClick={() => {
                              insert(i + 1, {
                                subTitle: "",
                                subDescription: "",
                                file: "",
                              });
                              // handleAddSubBlog(i, values, setValues);
                            }}
                          >
                            <AddCircleOutlineIcon />
                          </IconButton>
                          {values?.subPostReqList?.length > 1 && (
                            <IconButton
                              className="custom-remove-item-product"
                              onClick={() => {
                                remove(i);
                                // handleRemoveSubBlog(i, values, setValues);
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    );
                  })
                }
              </FieldArray>
              <div className="card-footer text-center border-top-0">
                <div>
                  <Button type="submit" variant="contained">
                    Lưu
                  </Button>
                  <Button
                    variant="outlined"
                    style={{ marginLeft: "1rem" }}
                    onClick={() => {
                      navigate(PATH.posts);
                    }}
                  >
                    Hủy
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default EditPost;


const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};
const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);
