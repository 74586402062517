import { useEffect, useState } from "react";

const useDebounce = (value, delay=500) => {
  const [debounceVal, setDebounceVal] = useState(value);
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebounceVal(value);
    }, delay);
    return ()=>{
        clearTimeout(timer)
    }
  }, [value, delay]);
  return debounceVal
};
export default useDebounce;
