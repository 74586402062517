import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { styled, Tooltip, tooltipClasses } from "@mui/material";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "black",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const ProductCard = ({ item, id }) => {
  const [mainImage, setMainImage] = useState(
    item?.colorList[0]?.images[0]?.linkImg
  );

  function formatCurrency(value) {
    const strValue = value.toString();

    const formattedValue = strValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return formattedValue + " VND";
  }

  return (
    <div
      className="product-card-container"
      key={id}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <CustomTooltip title={item.name}></CustomTooltip>
      <Link to={`/product/${item.id}`} style={{ height: "100%" }}>
        <CustomTooltip title={item.name}>
          <img
            className="border"
            style={{
              width: "100%",
              minHeight: "320px",
              objectFit: "cover",
              height: "100%",
            }}
            src={mainImage}
            // alt={item.name}
          />
        </CustomTooltip>
      </Link>
      <div className="flex-styling border">
        <div className="name-styling">
          <Link to={`/product/${item.id}`}>
            <CustomTooltip title={item.name}>
              <span style={{ paddingLeft: 8 }}>{item.name}</span>
            </CustomTooltip>
          </Link>
        </div>
        <div className="price-s">{formatCurrency(item.price)}</div>
      </div>
    </div>
  );
};

export default ProductCard;
