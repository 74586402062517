import { Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../CSS/Cart.css";
import "../CSS/style.css";
import { useDispatch, useSelector } from "react-redux";
import { setRefreshStage } from "../Redux/App/action";

const Cart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const refreshStage = useSelector((state) => state.AppReducer.refreshStage);

  const [cartData, setCartData] = useState([]);
  function formatCurrency(value) {
    // Convert the number to a string
    const strValue = value.toString();

    // Use a regular expression to format the string with dots
    const formattedValue = strValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return formattedValue + " VND";
  }
  useEffect(() => {
    const storedCartData = localStorage.getItem("cart");
    if (storedCartData) {
      setCartData(JSON.parse(storedCartData));
    }
  }, []);

  const handleDeleteProduct = (index) => {
    const updatedCart = [...cartData];
    updatedCart.splice(index, 1);
    setCartData(updatedCart);
    dispatch(setRefreshStage(refreshStage));
    localStorage.setItem("cart", JSON.stringify(updatedCart)); // Update localStorage
  };

  const addHandler = (index) => {
    const updatedCart = [...cartData];
    updatedCart[index].quantity += 1;
    setCartData(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart)); // Update localStorage
  };

  const reduceHandler = (index) => {
    const updatedCart = [...cartData];
    if (updatedCart[index].quantity > 1) {
      updatedCart[index].quantity -= 1;
      setCartData(updatedCart);
      localStorage.setItem("cart", JSON.stringify(updatedCart)); // Update localStorage
    }
  };

  const handleCheckout = () => {
    if (cartData && cartData.length > 0) {
      navigate("/checkout");
    } else {
      alert("Please Add Some Items To Your Cart In Order To Proceed");
    }
  };

  let sum = 0;
  cartData &&
    cartData.forEach((element) => {
      sum += element.price * element.quantity;
    });

  return (
    <>
      <div className="container">
        <div className="heading">
          <span className="text-bold text-18">
            Giỏ hàng ({cartData ? cartData.length : "0"})
          </span>
        </div>
        <div className="shoping-cart-msg">
          Các mặt hàng trong giỏ hàng chưa được đặt giữ cho đến khi hoàn tất
          thanh toán.
        </div>
        <Divider />
        <br />
        <div className="cart-item-flex">
          {cartData && cartData.length === 0 ? (
            <div style={{ textTransform: "uppercase" }}>Giỏ hàng trống</div>
          ) : (
            cartData?.map((item, index) => (
              <div className="cart-item" key={index}>
                <div className="cart-item-container">
                  <div className="img-product">
                    <img
                      src={item.property.image}
                      alt={item.producttitle}
                      onClick={() => (window.location = `product/${item?.id}`)}
                    />
                  </div>
                  <div className="cart-item-details" style={{ padding: 6 }}>
                    <div
                      className="cart-item-header"
                      style={{ fontSize: "13px", paddingBottom: "10px" }}
                    >
                      <a
                        className="text-16"
                        href={`product/${item?.id}`}
                        style={{ color: "black" }}
                      >
                        {item.name}{" "}
                      </a>
                    </div>
                    <div className="cart-item-description">
                      <div className="text-11">
                        {`Màu: ${item.property.colorName}`}
                      </div>
                      <span className="text-11">
                        {`Size: ${item.property.size}`}
                      </span>
                      <div className="justify-center">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            reduceHandler(index);
                          }}
                        >
                          -
                        </span>
                        <span>{item.quantity}</span>
                        <span
                          style={{
                            cursor:
                              item.quantity === item.property.quantity
                                ? "not-allowed"
                                : "pointer",
                            color:
                              item.quantity === item.property.quantity
                                ? "gray"
                                : "black",
                          }}
                          onClick={() => {
                            if (item.quantity < item.property.quantity) {
                              addHandler(index);
                            }
                          }}
                        >
                          +
                        </span>
                      </div>
                    </div>
                    <div className="item-quantity" style={{ fontSize: "12px" }}>
                      <div className="justify-center">
                        Giá: {formatCurrency(item.price)}
                      </div>
                    </div>
                    <div className="flex-column ">
                      <button
                        onClick={() => {
                          handleDeleteProduct(index);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        <div className="bottom-btn">
          <div>
            <div>
              <b>Thành Tiền {formatCurrency(sum)} </b>
            </div>
            <div>sản phẩm đã có thuế</div>
            <div>nhưng chưa có phí vận chuyển</div>
          </div>
          <button className="checkout-btn" onClick={handleCheckout}>
            Thanh toán
          </button>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Cart;
