import axios from "axios";
import queryString from "query-string";
import { toast } from "react-toastify";
import {
  DEFAULT_TIME_AUTO_HIDE_TOASTIFY,
  ERROR_CODE,
} from "../constants/appConstants";
import { clearUserInfo } from "../untils/storage";

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

const notificationWarning = (message, position = "top-right", autoClose) => {
  toast.warning(message, {
    position: position,
    autoClose: autoClose || DEFAULT_TIME_AUTO_HIDE_TOASTIFY,
    hideProgressBar: true,
  });
};

axiosApi.interceptors.request.use(async (config) => {
  let token = localStorage.getItem("access_token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiosApi.interceptors.response.use(
  // async (response) => {
  //     if (response && response.data) {
  //         if (
  //             response.data.error_code
  //         ) {
  //             let refresh_token = Cookies.get("refreshToken");

  //             const params = {
  //                 refresh: refresh_token,
  //             };
  //             return await axiosApi
  //                 .post("/iuhcoder/api/token/refresh/", params)
  //                 .then((response) => {
  //                     if (response.data.error_message === "success") {
  //                         Cookies.set("access_token");
  //                         axios.defaults.headers.common[
  //                             "Authorization"
  //                         ] = `Bearer ${Cookies.get("access_token")}`;
  //                     } else {
  //                         Cookies.remove("id");
  //                         Cookies.remove("accessToken");
  //                         Cookies.remove("refreshToken");
  //                     }
  //                 });
  //         }
  //     }
  //     return response;
  // },
  // (error) => {
  //     throw error;
  // }
  async (response) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  (error) => {
    // Handle errors
    if (error?.response?.data?.errorCode === ERROR_CODE.NOT_LOGIN) {
      localStorage.clear();
    }
    throw error?.response;
  }
);

export default axiosApi;

export const getRequest = (
  url = "",
  params,
  successCallback,
  errorCallback,
  timeout
) => {
  return axiosApi
    .get(url, { params, timeout })
    .then((response) => {
      if (successCallback) {
        try {
          successCallback(response);
        } catch (error) {
          console.log(error);
        }
      }
    })
    .catch((error) => {
      if (error) {
        if (
          error?.response?.data?.errors?.httpResponseError?.err_code === 113 &&
          error?.response?.data?.errors?.httpResponseError?.http_code === 403
        ) {
          notificationWarning("Token expried");
          setTimeout(() => logout(), 3000);
        }
      }
      if (errorCallback)
        try {
          errorCallback(error);
        } finally {
          console.log(error);
        }
    });
};

export const getRequestFile = (
  url = "",
  successCallback,
  errorCallback,
  timeout
) => {
  return axiosApi
    .get(url, { timeout, responseType: "blob" })
    .then((response) => {
      if (successCallback) {
        try {
          successCallback(response);
        } catch (error) {
          console.log(error);
        }
      }
    })
    .catch((error) => {
      if (error) {
        if (
          error?.response?.data?.errors?.httpResponseError?.err_code === 113 &&
          error?.response?.data?.errors?.httpResponseError?.http_code === 403
        ) {
          notificationWarning("Token expried");
          setTimeout(() => logout(), 3000);
        }
      }
      if (errorCallback)
        try {
          errorCallback(error);
        } finally {
          console.log(error);
        }
    });
};

export const postRequest = async (
  url = "",
  params,
  successCallback,
  errorCallback
) => {
  return await axiosApi
    .post(url, params)
    .then((response) => {
      if (successCallback) {
        try {
          successCallback(response);
        } catch (error) {
          console.log("error", error);
        }
      }
    })
    .catch((error) => {
      if (error) {
        if (
          error?.response?.data?.errors?.httpResponseError?.err_code === 113 &&
          error?.response?.data?.errors?.httpResponseError?.http_code === 403
        ) {
          notificationWarning("Token expried");
          setTimeout(() => logout(), 3000);
        }
      }
      if (errorCallback)
        try {
          errorCallback(error?.response);
        } finally {
          console.log(error);
        }
    });
};

export const postRequestFile = async (
  url = "",
  file,
  successCallback,
  errorCallback
) => {
  try {
    const formData = new FormData();
    // Thêm dữ liệu vào FormData
    formData.append("file", file);

    const response = await axiosApi.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (successCallback) {
      try {
        successCallback(response);
      } catch (error) {
        console.log("error", error);
      }
    }
  } catch (error) {
    if (error.response) {
      if (
        error.response.data.errors.httpResponseError.err_code === 113 &&
        error.response.data.errors.httpResponseError.http_code === 403
      ) {
        notificationWarning("Token expired");
        setTimeout(() => logout(), 3000);
      }
    }

    if (errorCallback) {
      try {
        errorCallback(error.response);
      } finally {
        console.log(error);
      }
    }
  }
};

export const putRequest = (
  url = "",
  params = {},
  successCallback,
  errorCallback,
  headers = {},
  timeout
) => {
  return axiosApi
    .put(url, params, {
      headers,
      timeout,
    })
    .then((response) => {
      if (successCallback) {
        try {
          successCallback(response);
        } catch (error) {
          console.log(error);
        }
      }
    })
    .catch((error) => {
      if (error) {
        if (
          error?.response?.data?.errors?.httpResponseError?.err_code === 113 &&
          error?.response?.data?.errors?.httpResponseError?.http_code === 403
        ) {
          notificationWarning("Token expried");
          setTimeout(() => logout(), 3000);
        }
      }
      if (errorCallback)
        try {
          errorCallback(error);
        } finally {
          console.log(error);
        }
    });
};

export const deleteRequest = (
  url = "",
  params = {},
  successCallback,
  errorCallback,
  headers = {},
  timeout
) => {
  return axiosApi
    .delete(url, params, {
      headers,
      timeout,
    })
    .then((response) => {
      if (successCallback) {
        try {
          successCallback(response);
        } catch (error) {
          console.log(error);
        }
      }
    })
    .catch((error) => {
      if (error) {
        if (
          error?.response?.data?.errors?.httpResponseError?.err_code === 113 &&
          error?.response?.data?.errors?.httpResponseError?.http_code === 403
        ) {
          notificationWarning("Token expried");
          setTimeout(() => logout(), 3000);
        }
      }
      if (errorCallback)
        try {
          errorCallback(error);
        } finally {
          console.log(error);
        }
    });
};

const logout = () => {
  clearUserInfo();
  window.location.replace("/login");
};
