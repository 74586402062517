import React from "react";
import Footer from "./Footer-component/Footer-component";
import "../CSS/PurchasePolicy.css";

const PurchasePolicy = () => {
  return (
    <>
      <div className="policy-container">
        <h1>Chính sách mua hàng online</h1>

        <h2>I/ HƯỚNG DẪN MUA HÀNG ONLINE.</h2>
        <h3>Bước 1: Chọn hàng</h3>
        <p> Khách hàng có thể xem thông tin sản phẩm trên website</p>

        <h3>Bước 2: Đặt hàng</h3>
        <ul>
          <li>
            Đặt hàng trên website:
            <ul>
              <li>
                Truy cập vào website:
                <a
                  href="https://vaidiepanh.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://vaidiepanh.com
                </a>
              </li>
              <li>Chọn hàng và đặt mua.</li>
            </ul>
          </li>
          <li>Đặt hàng trên Zalo, điện thoại: 0976 999 378</li>
          <li>
            <ul>
              <li>Số điện thoại: 0976.999.378</li>
              <li>Zalo: 0976.999.378</li>
              <li>Chăm Sóc Khách Hàng: 0976.999.378</li>
            </ul>
          </li>
        </ul>

        <h3>Bước 3: Thanh toán</h3>
        <p>
          * Tất cả các hình thức đặt hàng với Vải Diệp Anh đều phải có xác nhận
          lại từ nhân viên công ty, khách hàng không tự chuyển tiền khi chưa có
          xác nhận.
        </p>
        <ul>
          <li>
            Thanh toán trực tiếp khi nhận hàng đối với khách hàng tại nội thành
            Hà Nội (COD).
          </li>
          <li>Thanh toán chuyển khoản qua ngân hàng.</li>
        </ul>
        <p>
          Lưu ý:
          <ul>
            <li>
              Mọi thắc mắc trong quá trình đặt hàng và thanh toán, quý khách
              hàng liên hệ với bộ phận bán hàng online theo số hotline bán hàng
              online và các kênh mua sắm online (Website Vải Diệp Anh.vn,
              Zalo,…)
            </li>
            <li>
              Hệ thống thanh toán điện tử của Vải Diệp Anh được kết nối với cổng
              thanh toán điện tử VNPay. Theo đó, các tiêu chuẩn bảo mật thanh
              toán của Vải Diệp Anh đảm bảo tuân thủ theo các tiêu chuẩn bảo mật
              của Vải Diệp Anh, đã được Ngân hàng nhà nước Việt Nam thẩm định về
              độ an toàn bảo mật và cấp phép hoạt động chính thức.
            </li>
          </ul>
        </p>

        <h2>II/ CHÍNH SÁCH VẬN CHUYỂN, GIAO NHẬN.</h2>
        <p>Đối tượng áp dụng:</p>
        <p>
          Chính sách vận chuyển được áp dụng đối với tất cả các đối tượng khách
          hàng mua sản phẩm của Vải Diệp Anh qua các kênh mua sắm online chính
          thức của Vải Diệp Anh (Website Vải Diệp Anh.vn, mobile app, hotline
          bán hàng online, zalo Vải Diệp Anh…)
        </p>
        <p>Phạm vi áp dụng:</p>
        <p>Áp dụng đối với tất cả các đơn hàng trên toàn quốc.</p>

        <h3>Phí vận chuyển:</h3>
        <ul>
          <li>
            3.1. Miễn phí chuyển phát với những hóa đơn trên 1 triệu chuyển
            khoản trước (Áp dụng sau khi trừ cashback 100k).
          </li>
          <li>
            3.2. Phí chuyển phát áp dụng đối với những hoá đơn dưới 1 triệu:
            <ul>
              <li>
                Tại Hà Nội: Áp dụng phí vận chuyển 20,000 VND/đơn đối với đơn
                hàng dưới 1 triệu chuyển khoản trước.
              </li>
              <li>
                Tại các tỉnh: Áp dụng phí vận chuyển 30,000 VND/đơn đối với đơn
                hàng dưới 1 triệu chuyển khoản trước.
              </li>
            </ul>
          </li>
          <li>
            3.3. Áp dụng nhận hàng thanh toán đối với khách hàng tại nội thành
            Hà Nội. Khách hàng thanh toán phí vận chuyển cho đơn vị vận chuyển
            khi nhận hàng.
          </li>
        </ul>

        <h3>Thời gian vận chuyển</h3>
        <p>Tại Hà Nội: 1 – 2 ngày kể từ khi đơn hàng được xác nhận.</p>
        <p>
          Tại các thành phố lớn: 2 – 4 ngày kể từ khi đơn hàng được xác nhận.
        </p>
        <p>
          Tại địa điểm huyện, xã, vùng sâu vùng xa: 3 – 6 ngày kể từ khi đơn
          hàng được xác nhận.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default PurchasePolicy;
