export const checkDataInLocalStorage = (data) => {
  if (
    data === null ||
    data === undefined ||
    data === "null" ||
    data === "undefined"
  ) {
    return false;
  }
  return true;
};

export const saveUserInfo = (accessToken, state, id, userInfo, Role) => {
  const UserInfo = JSON.stringify(userInfo);
  localStorage.setItem("state", state);
  localStorage.setItem("id", id);
  localStorage.setItem("access_token", accessToken);
  localStorage.setItem("_currentUser", UserInfo);
  localStorage.setItem("role", Role);
};

export const clearUserInfo = () => {
  localStorage.removeItem("state");
  localStorage.removeItem("id");
  localStorage.removeItem("access_token");
  localStorage.removeItem("_currentUser");
  localStorage.removeItem("role");
};

export const saveUsername = (username) => {
  localStorage.setItem("username_rmb", username);
};

export const clearUsername = () => {
  localStorage.removeItem("username_rmb");
};

export const getToken = () => {
  const token = localStorage.getItem("access_token");
  return token;
};

export const getRole = () => {
  const role = localStorage.getItem("role");
  return role;
};

export const getIdToken = () => {
  const token = localStorage.getItem("id_token");
  return token;
};

export const getRefreshToken = () => {
  const rfToken = localStorage.getItem("_rfToken");
  return rfToken;
};

export const getTimeExpr = () => {
  const time = localStorage.getItem("_timeExpr");
  return time;
};

export const getUserInfo = () => {
  const userInfo = localStorage.getItem("_currentUser");

  return JSON.parse(userInfo);
};

export const getUsernameRemember = () => {
  const username = localStorage.getItem("username_rmb");
  if (checkDataInLocalStorage(username)) {
    return username;
  }
  return false;
};

export const setToken = (token) => {
  localStorage.setItem("_token", token);
};

export const setRefeshToken = (refeshToken) => {
  localStorage.setItem("_refeshToken", refeshToken);
};

export const setRoleInStorage = () => {
  localStorage.setItem("coodrPermision", true);
};

export const getRoleInStorage = () => {
  const per = localStorage.getItem("coodrPermision");
  return per;
};

export const saveTokenStringee = (token) => {
  localStorage.setItem("_tokenStringee", token);
};

export const getTokenStringee = () => {
  const rkStringee = localStorage.getItem("_tokenStringee");

  return rkStringee;
};
